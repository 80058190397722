<template>
    <div class="pt-spacer">
        <v-row>
            <v-col cols="6" md="9" class="d-none d-md-flex flex-row align-center">
            </v-col>
            <v-col cols="6" md="3" class="text-md-right">
                <a style="white-space: nowrap" target="_blank" :href="website_link">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                                d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                                stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{ $t('school.school_website') }}
                </a>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card style="min-height: 180px;" class="shadowless" :loading="loading">
                    <template slot="progress">
                        <Preloader :loading="loading"/>
                    </template>
                    <div class="courses-list" v-if="items.length > 0">
                        <CourseListItem
                                v-for="item in items"
                                :item="item"
                                :key="item.id"
                                @delete="deleteItem"
                        />
                    </div>
                    <div v-if="!items.length && !loading" class="text-center pa-10">
                        <!--{{ $t('course.no_results') }}-->
                    </div>
                </v-card>
                <v-card v-observe-visibility="load" v-if="!loaded"></v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import CourseListItem from "@/components/Course/parts/CourseListItem";

    export default {
        components: {
            CourseListItem,
        },
        data() {
            return {
                items: [],
                total: 0,
                page: 1,
                loaded: false,
                loading: false,
                website_link: '',
            }
        },
        mounted() {
        },
        methods: {
            load(isVisible) {
                if (isVisible && !this.loaded && !this.loading) {
                    let app = this;
                    setTimeout(() => {
                        app.loading = true;

                        axios.get('/schools/' + app.$route.params.school_slug + '/courses?page=' + app.page).then(response => {
                            if (response.data.data) {
                                if (!response.data.meta) {
                                    app.website_link = response.data.data.school.website_link;
                                    return;
                                }
                                if (Number(response.data.meta.last_page) > app.page) {
                                    app.page++;
                                } else {
                                    app.loaded = true;
                                }
                                app.items.push(...response.data.data);
                                app.website_link = response.data.school.website_link
                            }
                        }).finally(() => {
                            app.loading = false;
                        });
                    }, 500);
                }
            },
            deleteItem(item) {
                this.confirmDialog(this.$t('delete'), this.$t('delete_confirm')).then(() => {
                    axios.delete('/schools/' + item.school.slug + '/courses/' + item.id).then(() => {
                        let index = this.items.indexOf(item)
                        this.items.splice(index, 1)
                    })
                })
            },
        }
    }
</script>
