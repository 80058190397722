<template>
    <NotificationDescriptionCard :color="color" :to="to" v-if="Number(item.type) === TYPE_NEW_LECTURE"
                                 :image="item.lecture.image"
                                 :hasVideo="item.lecture.hasVideo"
                                 :title="item.lecture.title" :description="$t('notifications.types.new_lecture')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_NEW_POST"
                                 :image="'/img/text-image-sm.svg'"
                                 :title="item.post.title"
                                 :description="$t('notifications.types.new_post')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_NEW_COMMENT_TO_RECORD"
                                 :image="item.user.avatar"
                                 :title="item.user.full_name"
                                 :description="$t('notifications.types.new_comment_to_record')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_RESPONSE_TO_COMMENT"
                                 :image="item.user.avatar"
                                 :title="item.user.full_name"
                                 :description="$t('notifications.types.response_to_comment')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_MENTION_IN_COMMENT"
                                 :image="item.user.avatar"
                                 :title="item.user.full_name"
                                 :description="$t('notifications.types.mention_in_comment')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_NEW_COMMENT_TO_LECTURE"
                                 :image="item.user.avatar"
                                 :title="item.user.full_name"
                                 :description="$t('notifications.types.new_comment_to_lecture')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_BLOCK_COMMENT"
                                 :image="$auth.user().avatar"
                                 :title="$t('notifications.your_comment')"
                                 :description="$t('notifications.types.block_comment')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_UNBLOCK_COMMENT"
                                 :image="$auth.user().avatar"
                                 :title="$t('notifications.your_comment')"
                                 :description="$t('notifications.types.unblock_comment')"
                                 :type="item.type"/>
    <NotificationDescriptionCard :color="color" :to="to" v-else-if="Number(item.type) === TYPE_COMMENT_REQUEST"
                                 :image="item.user.avatar"
                                 :title="item.user.full_name"
                                 :description="$t('notifications.types.comment_request')"
                                 :type="item.type"/>
</template>
<script>
    import {
        TYPE_BLOCK_COMMENT, TYPE_COMMENT_REQUEST,
        TYPE_MENTION_IN_COMMENT, TYPE_NEW_COMMENT_TO_LECTURE,
        TYPE_NEW_COMMENT_TO_RECORD,
        TYPE_NEW_LECTURE,
        TYPE_NEW_POST, TYPE_RESPONSE_TO_COMMENT, TYPE_UNBLOCK_COMMENT
    } from "@/components/Notifications/notification.constants";
    import {TYPE_LECTURE_COMMENTS, TYPE_LECTURE_RECORDS} from "@/components/Lecture/lecture.constants";
    import NotificationDescriptionCard from "@/components/Notifications/parts/NotificationDescriptionCard";

    export default {
        name      : 'NotificationCard',
        components: {NotificationDescriptionCard},
        props     : {
            item : {
                type    : Object,
                required: true
            },
            to   : {
                type    : Object,
                required: false
            },
            color: {
                type   : String,
                default: 'transparent'
            },
        },
        data() {
            return {
                TYPE_NEW_LECTURE           : TYPE_NEW_LECTURE,
                TYPE_NEW_POST              : TYPE_NEW_POST,
                TYPE_NEW_COMMENT_TO_RECORD : TYPE_NEW_COMMENT_TO_RECORD,
                TYPE_RESPONSE_TO_COMMENT   : TYPE_RESPONSE_TO_COMMENT,
                TYPE_MENTION_IN_COMMENT    : TYPE_MENTION_IN_COMMENT,
                TYPE_NEW_COMMENT_TO_LECTURE: TYPE_NEW_COMMENT_TO_LECTURE,
                TYPE_BLOCK_COMMENT         : TYPE_BLOCK_COMMENT,
                TYPE_UNBLOCK_COMMENT       : TYPE_UNBLOCK_COMMENT,
                TYPE_COMMENT_REQUEST       : TYPE_COMMENT_REQUEST,
                TYPE_LECTURE_COMMENTS      : TYPE_LECTURE_COMMENTS,
                TYPE_LECTURE_RECORDS       : TYPE_LECTURE_RECORDS,
            }
        },
    }
</script>
