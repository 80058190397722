import Terms from '@/layout/Terms';
import Page from "@/layout/Page";

export default [
    {
        path: 'terms',
        component: Page,
        children : [
            {
                path     : '',
                component: Terms,
                name     : 'terms',
                meta      : {
                    auth  : undefined
                }
            }
        ]
    }
]
