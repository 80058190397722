import Page from '@/layout/Page'
import InfopostEdit from '@/components/Infopost/InfopostEdit'
import InfopostShow from '@/components/Infopost/InfopostShow'

export default [
    {
        path: 'infoposts',
        component: Page,
        children: [
            {
                path: ':id/edit',
                component: InfopostEdit,
                name: 'infoposts.edit',
            },
            {
                path: 'add',
                component: InfopostEdit,
                name: 'infoposts.add',
            },
            {
                path: ':id',
                component: InfopostShow,
                name: 'infoposts.show',
            },
        ]
    }
]
