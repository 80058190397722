<template>
    <div class="pt-spacer">
        <div class="d-flex flex-row mb-6">
            <!--v-btn color="black" class="white--text mr-3">{{$t('user.invite')}}</v-btn-->
            <div style="width: 202px">
                <v-text-field v-model="searchText" outlined dense height="48" :placeholder="$t('user.search_people')"
                              @input="doSearch">
                    <div slot="prepend-inner" class="ma-3 mt-2 mr-0">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_629:11729)">
                                <path
                                    d="M15.3281 15L11.2043 10.8763M11.2043 10.8763C12.2519 9.82938 12.8998 8.3828 12.8998 6.7849C12.8998 3.58999 10.3094 1 7.11398 1C3.91854 1 1.32812 3.58999 1.32812 6.7849C1.32812 9.9798 3.91854 12.5698 7.11398 12.5698C8.71125 12.5698 10.1574 11.9227 11.2043 10.8763Z"
                                    stroke="black" stroke-opacity="0.4" stroke-width="1.3" stroke-miterlimit="3.8637"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_629:11729">
                                    <path
                                        d="M0.328125 4C0.328125 1.79086 2.11899 0 4.32812 0H12.3281C14.5373 0 16.3281 1.79086 16.3281 4V12C16.3281 14.2091 14.5373 16 12.3281 16H4.32812C2.11899 16 0.328125 14.2091 0.328125 12V4Z"
                                        fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </v-text-field>
            </div>
        </div>
        <div class="d-flex flex-row mb-6">
            <div class="mr-3">
                <v-select
                    :items="courses"
                    :label="$t('user.select_course_label')"
                    v-model="selected_course"
                    outlined
                ></v-select>
            </div>
            <div class="mr-3">
                <v-select
                    :disabled="!selected_course"
                    :items="roles"
                    :label="$t('user.select_role_label')"
                    v-model="selected_role"
                    outlined
                ></v-select>
            </div>
            <div class="mt-7">
                <v-btn color="black" depressed @click="refresh" class="white--text">{{$t('apply')}}</v-btn>
            </div>
        </div>
        <v-card class="shadowless p-0 min-height" :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-row class="mb-2">
                <v-col cols="3">{{ $t('user.name_th') }}</v-col>
                <v-col cols="3">{{ $t('user.access_th') }}</v-col>
                <v-col cols="3">{{ $t('user.roles_th') }}</v-col>
                <v-col cols="3"></v-col>
            </v-row>
            <UserItem v-for="item in items" :item="item" :key="item.id"/>
        </v-card>
        <div class="text-center pt-2">
            <v-pagination
                v-model="page"
                :length="pageCount"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    import {mapMutations} from "vuex";
    import UserItem from "@/components/User/parts/UserItem";
    import {ROLES} from "@/base.constants";

    export default {
        components: {UserItem},
        data() {
            return {
                breadcrumbs: [
                    {
                        text    : this.$t('user.users'),
                        disabled: true,
                        to      : {
                            name: 'users.index'
                        }
                    },
                ],
                items      : [],
                total      : 0,
                page       : 1,
                pageCount  : 0,
                loading    : false,
                delayTimer : false,
                searchText : '',
                courses : [
                    {
                        value: 0,
                        text : this.$t('course.all_courses')
                    }
                ],
                selected_course   : 0,
                roles : [
                    {
                        value: 0,
                        text : this.$t('user.all_roles')
                    }
                ],
                selected_role : 0
            }
        },
        watch     : {
            page() {
                this.load()
            },
            selected_course(value) {
                if (Number(value) === 0) {
                    this.selected_role = 0;
                }
            }
        },
        mounted() {
            this.load();
            this.loadCourses();
            this.loadRoles();
            this.setBreadcrumbs([
                {
                    text: this.$t('admin_menu_title'),
                    to  : {name: 'admin.schools.index'}
                },
                {
                    text: this.$t('user.users'),
                },
            ]);
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            load() {
                this.loading = true;
                let url = '/schools/' + this.$route.params.school_slug + '/users?page=' + this.page;

                if (this.searchText.length > 0) {
                    url += '&search=' + this.searchText;
                }
                if (this.selected_course > 0) {
                    url += '&course=' + this.selected_course;
                }
                if (this.selected_role > 0) {
                    url += '&role=' + this.selected_role;
                }

                axios.get(url).then(response => {
                    this.items = response.data.data;
                    this.total = response.data.meta.total;
                    this.pageCount = response.data.meta.last_page;
                    this.page = response.data.meta.current_page;
                }).finally(() => {
                    this.loading = false;
                });
            },
            doSearch(val) {
                if (val.length >= 3) {
                    let app = this;
                    clearTimeout(app.delayTimer);
                    app.delayTimer = setTimeout(function () {
                        this.page = 1;
                        app.load();
                    }, 1000);
                } else {
                    this.page = 1;
                    this.load();
                }
            },
            loadCourses() {
                return axios.get('schools/' + this.$route.params.school_slug + '/courses').then(response => {
                for (let k in response.data.data) {
                    this.courses.push({
                        value: response.data.data[k].id,
                        text : response.data.data[k].title
                    })
                }
            }).finally(() => {
                });
            },
            loadRoles() {
                return axios.get('users/roles').then(response => {
                    for (let k in response.data.data) {
                        this.roles.push({
                            value: k,
                            text : response.data.data[k]
                        })
                    }
                }).finally(() => {
                });
            },
            refresh() {
                this.items = [];
                this.page = 1;
                this.load();
            },
        }
    }
</script>
