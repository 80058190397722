<template>
    <div class="pb-8 pt-1 pl-xs-1 pr-xs-1 mob-preview">
        <v-card :outlined="preview.type == 'text'" :to="linkTo" class="shadowless">
            <v-responsive class="mob-preview" :aspect-ratio="1" v-if="preview.type === 'text'">
                <v-card-text class="mob-p-12 d-flex justify-center">
                    <div class="comment-preview-text black--text-i" v-if="preview.type === 'text'">{{content}}</div>
<!--                <div v-if="preview.type === 'video'" class="shadowless"><img class="img-mw" src="https://vumbnail.com/652002575.jpg" /></div>
-->
                </v-card-text>
            </v-responsive>
            <img v-else-if="preview.type === 'video'" class="mob-preview img-mw align-center fill-height" :src="preview.path" />
            <img v-else :src="preview.path" class="mob-preview img-mw"/>
        </v-card>
        <v-card-title class="pb-1 pl-0 pr-0 pt-2 pt-md-5 sm-default-font-size">
            <div class="d-flex justify-space-between w-100">
                <div class="mr-aut overflow-text">
                    <v-avatar size="30" color="indigo" class="mr-2 rounded"
                              :class="(!!item.lecture.image) ? '' : 'outlined'">
                        <img v-if="!!item.lecture.image"
                             :src="item.lecture.image"
                             alt="John"
                        >
                        <v-icon v-else dark>
                            mdi-account-circle
                        </v-icon>
                    </v-avatar>
                    {{item.lecture.title}}
                </div>
                <div class="grey--text nowrap-text">
                    {{dateFormattedDate(item.updated_at)}}
                </div>
            </div>
        </v-card-title>
    </div>
</template>
<script>
    import {CONTENT_LENGTH} from '@/base.constants';
    export default {
        name    : 'UserRecord',
        props   : {
            item: {
                type    : Object,
                required: true,
            },
        },
        data() {
            return {}
        },
        computed: {
            preview() {
                let pr = {type: 'text'};
                let file = this.item.files.find(o => !!o.videoId);

                if (!!file) {
                    pr = {type: 'video', path: 'https://vumbnail.com/'+file.videoId+'.jpg'};
                } else {
                    for (let k in this.item.files) {
                        if (this.item.files[k].mime_type === 'image/jpeg' || this.item.files[k].mime_type === 'image/png' || this.item.files[k].mime_type === 'image/gif') {
                            pr = {type: 'image', path: this.item.files[k].path};
                            break;
                        }
                    }
                }
                return pr;
            },
            content() {
                let content = this.item.content;
                if (content.length > CONTENT_LENGTH) {
                    content = content.substr(1, CONTENT_LENGTH)+'...';
                }
                return content;
            },
            linkTo() {
                return {
                    name  : 'records.show',
                    params: {
                        lecture_id : this.item.lecture.id,
                        user_id    : this.item.user.id,
                        id         : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }
                }
            }
        }
    }
</script>
