<template>
    <v-row justify="center">
        <v-col cols="12"
               class="pt-spacer">
            <v-card ref="form" outlined
                    :loading="loading">
                <template slot="progress">
                    <Preloader :loading="loading"/>
                </template>
                <v-card-title>{{ $t(!!item.id ? 'school.edit' : 'school.add') }}</v-card-title>
                <v-card-text>
                    <v-text-field
                            ref="form.name"
                            v-model="item.name"
                            :rules="[() => !!item.name || $t('form.rules.required')]"
                            :error-messages="form_errors.name"
                            :label="$t('school.name_label')"
                            required
                    ></v-text-field>
                    <v-textarea
                            counter
                            :label="$t('school.description_label')"
                            v-model="item.description"
                            :error-messages="form_errors.description"
                            ref="form.description"
                    ></v-textarea>
                    <img style="max-width: 100px;" v-show="!!item.id && !!item.logo" :src="item.logo"/>
                    <v-file-input
                            accept="image/png, image/jpeg, image/bmp"
                            :placeholder="$t('school.logo_label')"
                            prepend-icon="mdi-camera"
                            ref="logo"
                            v-model="logo"
                            :error-messages="form_errors.logo"
                    ></v-file-input>
                    <v-text-field
                            ref="form.paddle_vendor_id"
                            v-model="item.paddle_vendor_id"
                            :error-messages="form_errors.paddle_vendor_id"
                            :label="$t('school.paddle_vendor_id_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.paddle_vendor_auth_code"
                            v-model="item.paddle_vendor_auth_code"
                            :error-messages="form_errors.paddle_vendor_auth_code"
                            :label="$t('school.paddle_vendor_auth_code_label')"
                    ></v-text-field>
                    <v-textarea
                            counter
                            :label="$t('school.paddle_public_key_label')"
                            v-model="item.paddle_public_key"
                            :error-messages="form_errors.paddle_public_key"
                            ref="form.paddle_public_key"
                    ></v-textarea>
                    <v-text-field
                            ref="form.vimeo_id"
                            v-model="item.vimeo_id"
                            :error-messages="form_errors.vimeo_id"
                            :label="$t('school.vimeo_id_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.vimeo_secret"
                            v-model="item.vimeo_secret"
                            :error-messages="form_errors.vimeo_secret"
                            :label="$t('school.vimeo_secret_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.vimeo_access_token"
                            v-model="item.vimeo_access_token"
                            :error-messages="form_errors.vimeo_access_token"
                            :label="$t('school.vimeo_access_token_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.record_vimeo_id"
                            v-model="item.record_vimeo_id"
                            :error-messages="form_errors.record_vimeo_id"
                            :label="$t('school.record_vimeo_id_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.record_vimeo_secret"
                            v-model="item.record_vimeo_secret"
                            :error-messages="form_errors.record_vimeo_secret"
                            :label="$t('school.record_vimeo_secret_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.record_vimeo_access_token"
                            v-model="item.record_vimeo_access_token"
                            :error-messages="form_errors.record_vimeo_access_token"
                            :label="$t('school.record_vimeo_access_token_label')"
                    ></v-text-field>
                    <v-col class="pl-0" cols="12">
                        <div class="mb-2">
                            <span>{{$t('school.privacy_label')}}</span>
                        </div>
                        <editor v-model="item.privacy" :gallery="[]" ref="form.privacy"/>
                    </v-col>

                    <v-col class="pl-0" cols="12">
                        <div class="mb-2">
                            <span>{{$t('school.terms_label')}}</span>
                        </div>
                        <editor v-model="item.terms" :gallery="[]" ref="form.privacy"/>
                    </v-col>
                    <!--<v-textarea
                            counter
                            :label="$t('school.privacy_label')"
                            v-model="item.privacy"
                            :error-messages="form_errors.privacy"
                            ref="form.privacy"
                    ></v-textarea>-->
                    <!--<v-textarea
                            counter
                            :label="$t('school.terms_label')"
                            v-model="item.terms"
                            :error-messages="form_errors.terms"
                            ref="form.terms"
                    ></v-textarea>-->
                    <v-text-field
                            ref="form.fb_link"
                            v-model="item.fb_link"
                            :error-messages="form_errors.fb_link"
                            :label="$t('school.fb_link_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.instagram_link"
                            v-model="item.instagram_link"
                            :error-messages="form_errors.instagram_link"
                            :label="$t('school.instagram_link_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.vimeo_link"
                            v-model="item.vimeo_link"
                            :error-messages="form_errors.vimeo_link"
                            :label="$t('school.vimeo_link_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.website_link"
                            v-model="item.website_link"
                            :error-messages="form_errors.website_link"
                            :label="$t('school.website_link_label')"
                    ></v-text-field>
                    <v-text-field
                            ref="form.support_link"
                            v-model="item.support_link"
                            :error-messages="form_errors.support_link"
                            :label="$t('school.support_link_label')"
                    ></v-text-field>
                    <v-switch
                            v-model="item.status"
                            inset
                            :label="$t('school.status_label')+`: ${item.status.toString()}`"
                    ></v-switch>
                </v-card-text>
                <v-divider class="mt-12"></v-divider>
                <v-card-actions class="text-right d-flex justify-space-between">
                    <v-btn depressed
                           color="accent"
                           @click="submit"
                    >
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn depressed
                           v-if="item.name"
                           color="grey lighten-2" class="black--text"
                           :to="{name: 'admin.schools.index'}"
                    >
                        {{$t('cancel_changes')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import {mapMutations} from 'vuex';
    import Editor from '@/layout/Editor';

    export default {
        components: {
            Editor
        },
        data() {
            return {
                item: {
                    name: '',
                    description: '',
                    status: 0,

                    vimeo_id: '',
                    vimeo_secret: '',
                    vimeo_access_token: '',

                    record_vimeo_id: '',
                    record_vimeo_secret: '',
                    record_vimeo_access_token: '',

                    paddle_vendor_id: '',
                    paddle_vendor_auth_code: '',
                    paddle_public_key: '',

                    privacy: '',
                    terms: '',

                    fb_link: '',
                    instagram_link: '',
                    vimeo_link: '',
                    website_link: '',
                    support_link: '',
                },
                logo: null,
                form_errors: {},
                loading: false
            }
        },
        mounted() {
            this.load().then(() => {
                this.setBreadcrumbs([
                    {text: !!this.item.id ? (this.$t('edit') + ' ' + this.item.name) : this.$t('school.add')}
                ]);
            })
        },
        methods: {
            ...mapMutations(['setBreadcrumbs']),
            async load() {
                if (!!this.$route.params.school_slug) {
                    this.loading = true;
                    await axios.get('/schools/' + this.$route.params.school_slug).then(response => {
                        this.item = response.data.data;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            submit() {
                if (!!this.item.status) {
                    for (const [key, value] of Object.entries(this.item)) {
                        if (!value && key !== 'logo') {
                            this.showMessage(this.$t('school.status_invalid'), 'error');
                            return;
                        }
                    }

                }

                let url = '/schools';
                let formData = new FormData();

                formData.append('name', this.item.name);

                formData.append('description', (!!this.item.description) ? this.item.description : '');
                formData.append('status', (this.item.status) ? 1 : 0);
                formData.append('vimeo_id', (!!this.item.vimeo_id) ? this.item.vimeo_id : '');
                formData.append('vimeo_secret', (!!this.item.vimeo_secret) ? this.item.vimeo_secret : '');
                formData.append('vimeo_access_token', (!!this.item.vimeo_access_token) ? this.item.vimeo_access_token : '');
                formData.append('record_vimeo_id', (!!this.item.record_vimeo_id) ? this.item.record_vimeo_id : '');
                formData.append('record_vimeo_secret', (!!this.item.record_vimeo_secret) ? this.item.record_vimeo_secret : '');
                formData.append('record_vimeo_access_token', (!!this.item.record_vimeo_access_token) ? this.item.record_vimeo_access_token : '');
                formData.append('paddle_vendor_id', (!!this.item.paddle_vendor_id) ? this.item.paddle_vendor_id : '');
                formData.append('paddle_vendor_auth_code', (!!this.item.paddle_vendor_auth_code) ? this.item.paddle_vendor_auth_code : '');
                formData.append('paddle_public_key', (!!this.item.paddle_public_key) ? this.item.paddle_public_key : '');
                formData.append('privacy', (!!this.item.privacy) ? this.item.privacy : '');
                formData.append('terms', (!!this.item.terms) ? this.item.terms : '');
                formData.append('fb_link', (!!this.item.fb_link) ? this.item.fb_link : '');
                formData.append('instagram_link', (!!this.item.instagram_link) ? this.item.instagram_link : '');
                formData.append('vimeo_link', (!!this.item.vimeo_link) ? this.item.vimeo_link : '');
                formData.append('website_link', (!!this.item.website_link) ? this.item.website_link : '');
                formData.append('support_link', (!!this.item.support_link) ? this.item.support_link : '');
                if (this.logo) {
                    formData.append('logo', this.logo);
                }

                if (this.item.id) {
                    formData.append('_method', 'PUT');
                    url = '/schools/' + this.$route.params.school_slug;
                }

                this.loading = true;
                axios({
                    method: 'post',
                    url: url,
                    data: formData
                }).then(response => {
                    this.form_errors = [];

                    this.showMessage(this.$t('successfully_saved'));

                    if (!this.item.id) {
                        this.item = response.data.data;
                        this.$router.push({
                            name: 'admin.schools.edit',
                            params: {
                                school_slug: response.data.data.slug
                            }
                        }).then(() => {
                            this.setBreadcrumbs([
                                {text: this.$t('edit') + ' ' + this.item.name}
                            ]);
                        })
                    }
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
        },
    }
</script>
