<template>
    <div class="pt-spacer">
        <v-row>
            <v-col cols="12" md="6">
                <v-expansion-panels class="mb-10" v-model="panel">
                    <v-expansion-panel
                            v-model="panel">
                        <v-expansion-panel-header>
                            {{$t('user.tab_main')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <div class="d-flex flex-row mb-6" style="cursor: pointer"
                                         @click="$refs.avatar.click()">
                                        <div>
                                            <v-avatar size="48" rounded color="white" class="mr-3"
                                                      :class="(!!item.avatar) ? '' : 'outlined'">
                                                <img v-if="!!item.avatar"
                                                     :src="item.avatar"
                                                     alt=""
                                                >
                                                <v-icon color="primary" v-else>
                                                    mdi-image-outline
                                                </v-icon>
                                            </v-avatar>
                                        </div>
                                        <div>
                                            <div class="black--text">
                                                <div>{{$t('user.profile_photo')}}</div>
                                            </div>
                                            <div class="grey--text">
                                                {{$t('change')}}
                                            </div>
                                        </div>
                                    </div>
                                    <input v-show="false" @change="onFileChange" type="file"
                                           accept="image/png, image/jpeg, image/bmp"
                                           ref="avatar"/>
                                    <v-text-field outlined

                                                  v-model="item.name"
                                                  :error-messages="form_errors.name"
                                                  :label="$t('user.name_label')"
                                    ></v-text-field>
                                    <v-text-field outlined
                                                  ref="form.last_name"
                                                  v-model="item.last_name"
                                                  :error-messages="form_errors.last_name"
                                                  :label="$t('user.last_name_label')"
                                    ></v-text-field>
                                    <v-text-field outlined disabled
                                                  ref="form.slug"
                                                  v-model="item.slug"
                                                  :error-messages="form_errors.slug"
                                                  :label="$t('user.slug_label')"
                                    ></v-text-field>
                                    <div class="gender-switch">
                                        <label>{{ $t('profile.fill.gender') }}</label>
                                        <div class="d-flex flex-row align-center">
                                            <div :class="(Number(item.gender) === 1) ? 'grey--text' : ''">
                                                {{$t('profile.fill.gender_male')}}
                                            </div>
                                            <v-switch class="ml-3 pt-3"
                                                      :error-messages="form_errors.gender"
                                                      v-model="item.gender"
                                                      inset
                                                      color="black" value="1"
                                            ></v-switch>
                                            <div :class="(Number(item.gender) === 0) ? 'grey--text' : ''">
                                                {{$t('profile.fill.gender_female')}}
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_workplace')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined

                                                  v-model="item.work_role"
                                                  :error-messages="form_errors.work_role"
                                                  :label="$t('user.label_role')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined

                                                  v-model="item.work_company"
                                                  :error-messages="form_errors.work_company"
                                                  :label="$t('user.label_company')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_livingplace')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined
                                                  v-model="item.country"
                                                  :error-messages="form_errors.country"
                                                  :label="$t('user.label_country')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined
                                                  v-model="item.city"
                                                  :error-messages="form_errors.city"
                                                  :label="$t('user.label_city')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_bio')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-textarea outlined rows="3"
                                        v-model="item.biography"
                                        :error-messages="form_errors.biography"
                                        :label="$t('user.label_bio')"
                            ></v-textarea>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_socials')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field outlined
                                          v-model="item.social_networks.website"
                                          :error-messages="form_errors['socialNetworks.website']"
                                          :label="$t('user.label_website')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.instagram"
                                          :error-messages="form_errors['socialNetworks.instagram']"
                                          :label="$t('user.label_instagram')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.facebook"
                                          :error-messages="form_errors['socialNetworks.facebook']"
                                          :label="$t('user.label_facebook')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.behance"
                                          :error-messages="form_errors['socialNetworks.behance']"
                                          :label="$t('user.label_behance')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.dribbble"
                                          :error-messages="form_errors['socialNetworks.dribbble']"
                                          :label="$t('user.label_dribbble')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.pinterest"
                                          :error-messages="form_errors['socialNetworks.pinterest']"
                                          :label="$t('user.label_pinterest')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.twitter"
                                          :error-messages="form_errors['socialNetworks.twitter']"
                                          :label="$t('user.label_twitter')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.youtube"
                                          :error-messages="form_errors['socialNetworks.youtube']"
                                          :label="$t('user.label_youtube')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.linkedin"
                                          :error-messages="form_errors['socialNetworks.linkedin']"
                                          :label="$t('user.label_linkedin')"
                            ></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <div class="d-flex justify-space-between mt-12 mb-6">
            <v-btn depressed
                   x-large
                   color="accent" class="black--text"
                   @click="submit"
            >
                <span>
                    {{$t('save')}}
                    <span class="d-none d-md-inline">{{$t('changes')}}</span>
                </span>
            </v-btn>
            <v-btn depressed
                   x-large
                   color="gray" class="black--text"
                   :to="{name: 'profile.index'}"
            >
                <span>
                    {{$t('cancel2')}}
                    <span class="d-none d-md-inline">{{$t('changes')}}</span>
                </span>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import {mapMutations} from "vuex";

    export default {
        data() {
            return {
                item: {
                    name: '',
                    last_name: '',
                    slug: '',
                    gender: 0,
                    email: '',
                    email_verified_at: null,
                    country: '',
                    city: '',
                    work_role: '',
                    work_company: '',
                    biography: '',
                    social_networks: {
                        instagram: '',
                        facebook: '',
                        behance: '',
                        dribbble: '',
                        pinterest: '',
                        twitter: '',
                        youtube: '',
                        linkedin: '',
                        website: ''
                    }
                },
                avatar: null,
                form_errors: {},
                loading: false,
                show_other: false,
                panel: 0,
            }
        },
        created() {
            this.setBreadcrumbs([
                {
                    to: {name: 'profile.index', params: {school_slug: this.$route.params.school_slug}},
                    text: this.$t('profile.my_profile')
                },
                {
                    text: this.$t('edit')
                },
            ]);
        },
        mounted() {
            this.load();
        },
        methods: {
            ...mapMutations(['setBreadcrumbs']),
            submit() {
                this.loading = true;

                axios.post('/schools/' + this.$route.params.school_slug + '/users/' + this.$auth.user().id, this.prepareForm()).then(response => {
                    this.form_errors = [];
                    this.$auth.fetch();
                    this.showMessage(this.$t('successfully_saved'));
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            load() {
                this.loading = true;
                return axios.get('/schools/' + this.$route.params.school_slug + '/users/' + this.$auth.user().id).then(response => {
                    this.item = response.data.data;
                    if (!this.item.social_networks) {
                        this.item.social_networks = {
                            instagram: '',
                            facebook: '',
                            behance: '',
                            dribbble: '',
                            pinterest: '',
                            twitter: '',
                            youtube: '',
                            linkedin: '',
                            website: ''
                        };
                    }
                    this.item.is_admin_school = (!!this.item.roles['admin-school']) ? 1 : 0;
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
            prepareForm() {
                let formData = new FormData();

                formData.append('email', this.$auth.user().email);
                formData.append('name', (!!this.item.name) ? this.item.name : '');
                formData.append('last_name', (!!this.item.last_name) ? this.item.last_name : '');
                formData.append('gender', (!!this.item.gender) ? 1 : 0);
                formData.append('country', (!!this.item.country) ? this.item.country : '');
                formData.append('city', (!!this.item.city) ? this.item.city : '');
                formData.append('work_role', (!!this.item.work_role) ? this.item.work_role : '');
                formData.append('work_company', (!!this.item.work_company) ? this.item.work_company : '');
                formData.append('biography', (!!this.item.biography) ? this.item.biography : '');
                formData.append('socialNetworks[instagram]', (!!this.item.social_networks.instagram) ? this.item.social_networks.instagram : '');
                formData.append('socialNetworks[facebook]', (!!this.item.social_networks.facebook) ? this.item.social_networks.facebook : '');
                formData.append('socialNetworks[behance]', (!!this.item.social_networks.behance) ? this.item.social_networks.behance : '');
                formData.append('socialNetworks[dribbble]', (!!this.item.social_networks.dribbble) ? this.item.social_networks.dribbble : '');
                formData.append('socialNetworks[pinterest]', (!!this.item.social_networks.pinterest) ? this.item.social_networks.pinterest : '');
                formData.append('socialNetworks[twitter]', (!!this.item.social_networks.twitter) ? this.item.social_networks.twitter : '');
                formData.append('socialNetworks[youtube]', (!!this.item.social_networks.youtube) ? this.item.social_networks.youtube : '');
                formData.append('socialNetworks[linkedin]', (!!this.item.social_networks.linkedin) ? this.item.social_networks.linkedin : '');
                formData.append('socialNetworks[website]', (!!this.item.social_networks.website) ? this.item.social_networks.website : '');
                formData.append('fill_complete', 1);
                formData.append('_method', 'PUT');

                if (!!this.avatar) {
                    formData.append('avatar', this.avatar);
                }

                return formData;
            },
            onFileChange(e) {
                let file = e.target.files[0];
                this.item.avatar = URL.createObjectURL(file);
                this.avatar = file;
            },
        }
    }
</script>
