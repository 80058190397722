<template>
    <v-card outlined class="mb-4" :to="linkTo">
        <v-card-text class="pa-md-5 pa-3">
            <div class="d-flex flex-column flex-md-row justify-start justify-md-space-between align-md-center">
                <div class="col-sm-5">
                    <div class="d-flex flex-row">
                        <v-avatar size="48" color="primary" class="mr-3 rounded"
                                  :class="(!!pitem.image) ? '' : 'outlined'">
                            <img v-if="!!pitem.image"
                                 :src="pitem.image"
                            >
                            <v-icon v-else dark>
                                mdi-account-circle
                            </v-icon>
                        </v-avatar>
                        <div>
                            <div>{{pitem.title}}</div>
                            <div class="grey--text">{{dateFormattedComment(item.created_at)}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7 mt-4 mt-md-0">{{item.content}}</div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import {TYPE_LECTURE_COMMENTS, TYPE_LECTURE_RECORDS} from "@/components/Lecture/lecture.constants";

    export default {
        name : 'UserComment',
        props: {
            item: {
                type    : Object,
                required: true,
            },
        },
        computed: {
            linkTo() {
                return (this.pitem.type === TYPE_LECTURE_RECORDS) ? {
                    name  : 'records.show',
                    params: {
                        lecture_id : this.pitem.id,
                        user_id    : this.item.user.id,
                        id         : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }
                } : {
                    name  : 'lectures.show',
                    params: {
                        id         : this.pitem.id,
                        comment_id : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }
                };
            },
            pitem() {
                return (!!this.item.post) ? this.item.post : this.item.lecture;
            }
        }
    }
</script>
