<template>
    <div>
        <v-row>
            <v-col md="9" sm="12" class="pt-spacer">
                <v-row v-if="course">
                    <v-col cols="12" v-if="hasCourseAdminAccess($route.params.school_slug, $route.params.id)">
                        <v-btn rounded
                               fab
                               outlined
                               :to="{name: 'courses.edit', params: $route.params }">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                    stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                            </svg>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" md="9" class="d-none d-md-flex flex-row align-center">
                        <!--SORT LECTURES COMMENTED-->
                        <!--<v-btn class="px-0 black--text" plain>{{ $t('lecture.open_lectures') }}</v-btn>
                        <span class="mx-3">/</span>
                        <v-btn class="px-0" plain>{{ $t('lecture.hidden_lectures') }}</v-btn>
                        <span class="mx-3">/</span>
                        <v-btn class="px-0" plain>{{ $t('lecture.deleted_lectures') }}</v-btn>-->
                    </v-col>
                    <v-col cols="6" md="3" class="text-md-right">
                        <a v-if="!!course" style="white-space: nowrap" target="_blank" :href="course.url_code">
                            <svg width="15" height="10" viewBox="0 0 15 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                                    stroke="black" stroke-width="1.3"/>
                            </svg>
                            {{ $t('course.course_info') }}
                        </a>
                    </v-col>
                    <v-col cols="6" class="d-md-none text-right">
                        <a style="white-space: nowrap" href="#" @click="feedShow = true">
                            {{ $t('course.news_feed') }}
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 8H1M1 8L8.45723 1M1 8L8.45723 15" stroke="black" stroke-opacity="0.4"
                                      stroke-width="1.3"/>
                            </svg>
                        </a>
                    </v-col>
                </v-row>
                <div>
                    <v-card class="shadowless p-0 min-height" :loading="loading">
                        <template slot="progress">
                            <Preloader :loading="loading"/>
                        </template>
                        <v-row class="mx-md-n5">
                            <v-col md="4" sm="12" class="px-5 px-c-10 pb-6 pt-4" v-if="infopostsGrouped.length > 0"
                                   v-for="(posts, index) in infopostsGrouped"
                                   :key="'p'+index">
                                <v-card class="shadowless p-0">
                                    <v-responsive :aspect-ratio="1" class="overflow-visible">
                                        <v-card-text class="p-0-i">
                                            <draggable group="posts"
                                                       class="list-group"
                                                       ghost-class="ghost"
                                                       v-model="infopostsGrouped[index]"
                                                       @end="placePost"
                                                       :disabled="!isOwner()"
                                                       handle=".handle">
                                                <PostItem
                                                    v-for="(post, postIndex) in posts"
                                                    :key="post.id"
                                                    :groupLength="posts.length"
                                                    :post="post"
                                                    :isLast="postIndex === (posts.length-1)"
                                                    @postDeleted="postDeleted"
                                                    @copiedPost="loadInfoposts"
                                                />
                                            </draggable>
                                        </v-card-text>
                                    </v-responsive>
                                </v-card>
                            </v-col>
                            <LectureItem v-if="!isOwner()"
                                         v-for="lecture in lectures"
                                         :key="lecture.id"
                                         :lecture="lecture"
                            />
                        </v-row>
                        <draggable group="lectures"
                                   class="row"
                                   ghost-class="ghost"
                                   v-model="lectures"
                                   v-if="isOwner()"
                                   @end="placeLecture"
                                   handle=".handle">
                            <LectureItem
                                v-for="lecture in lectures"
                                :key="lecture.id"
                                :lecture="lecture"
                                @lectureDeleted="lectureDeleted"
                                @copiedLecture="loadLectures"
                            />
                        </draggable>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <NotificationsBlock @hideFeed="feedShow=false" :feedShow="feedShow" />
    </div>
</template>
<script>
    import {mapMutations} from 'vuex';
    import LectureItem from '@/components/Lecture/parts/LectureItem'
    import PostItem from '@/components/Infopost/parts/PostItem'
    import draggable from 'vuedraggable'
    import {ROLE_STUDENT, ROLE_STUDENT_BASE, ROLE_STUDENT_PRO} from "@/base.constants";
    import NotificationsBlock from "@/components/Course/parts/NotificationsBlock";

    export default {
        components: {
            draggable,
            LectureItem,
            PostItem,
            NotificationsBlock
        },
        data() {
            return {
                course                  : null,
                lectures                : [],
                infoposts               : [],
                infopostsGrouped        : [],
                loading                 : false,
                itemReplace             : null,
                itemReplaceListFromIndex: 0,
                feedShow                : false
            }
        },
        watch     : {
            feedShow(val) {
                console.log(val)
            }
        },
        mounted() {
            this.loadInfoposts();
            this.loadLectures();
            this.load().then(() => {
                this.setBreadcrumbs([
                    {
                        text: this.course.title,
                    },
                ]);
            });
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            load() {
                this.loading = true;
                return axios.get('/schools/' + this.$route.params.school_slug + '/courses/' + this.$route.params.id).then(response => {
                    this.course = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadInfoposts() {
                this.loading = true;
                axios.get('/courses/' + this.$route.params.id + '/posts').then(response => {
                    this.infoposts = response.data.data;
                    this.splitPosts();
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadLectures() {
                this.loading = true;
                return axios.get('/courses/' + this.$route.params.id + '/lectures').then(response => {
                    this.lectures = response.data.data;

                    if (!!this.$route.query.lecture_id) {
                        let app = this;
                        setTimeout(function () {
                            if (!app.hasStudentAccess(app.$route.params.school_slug, Number(app.$route.params.id))) {
                                app.$router.push({name: 'payment.index', params: {course_id: app.$route.params.id}});
                            } else {
                                app.$vuetify.goTo('#lecture-' + app.$route.query.lecture_id, {offset: 90});
                            }
                        }, 500)
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            lectureDeleted() {
                this.loadLectures();
            },
            postDeleted() {
                this.loadInfoposts();
            },
            placePost(orderData) {
                if (orderData.newIndex === orderData.oldIndex) return;
                this.refreshPosts();
                let data = [];
                for (let k in this.infoposts) {
                    data.push({
                        id      : this.infoposts[k].id,
                        position: k
                    });
                }
                axios.post('/courses/' + this.course.id + '/posts/position', data).then(response => {
                    this.showMessage(this.$t('successfully_saved'));
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            placeLecture(orderData) {
                if (orderData.newIndex === orderData.oldIndex) return;
                let data = [];
                for (let k in this.lectures) {
                    data.push({
                        id      : this.lectures[k].id,
                        position: k
                    });
                }
                axios.post('/courses/' + this.course.id + '/lectures/position', data).then(response => {
                    this.showMessage(this.$t('successfully_saved'));
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            splitPosts() {
                let i, j, chunk = 4;
                this.infopostsGrouped = [];
                for (i = 0, j = this.infoposts.length; i < j; i += chunk) {
                    this.infopostsGrouped.push(this.infoposts.slice(i, i + chunk));
                }
            },
            refreshPosts() {
                let data = [];
                for (let k_list in this.infopostsGrouped) {
                    for (let k_post in this.infopostsGrouped[k_list]) {
                        data.push(this.infopostsGrouped[k_list][k_post]);
                    }
                }
                this.infoposts = data;
                this.splitPosts();
            },
            isOwner() {
                return this.hasCourseAdminAccess(this.$route.params.school_slug, this.$route.params.id)
            },
        }
    }
</script>
