<template>
    <v-card outlined class="mb-4">
        <v-card-text>
            <v-row class="mb-2">
                <v-col cols="3" class="d-flex flex-row">
                    <div>
                        <v-avatar size="48" rounded color="white" class="mr-3 outlined">
                            <img v-if="!!item.avatar"
                                 :src="item.avatar"
                                 alt=""
                            >
                            <v-icon color="primary" v-else>
                                mdi-image-outline
                            </v-icon>
                        </v-avatar>
                    </div>
                    <div>
                        <div class="black--text mb-1">{{ item.name }} {{ item.last_name }}</div>
                        <div>{{ item.email }}</div>
                    </div>
                </v-col>
                <v-col cols="3" class="black--text">
                    <div v-if="isAdmin()">{{ $t('course.all_courses') }}</div>
                    <div v-else>{{item.count_courses}} {{$t('course.courses_pad')}}</div>
                </v-col>
                <v-col cols="3">
                    <v-chip class="mx-1" v-for="(role, index) in roles" :key="role+item.id" label
                            :color="roleColor(role)"
                            :class="(role === ROLE_ADMIN_PLATFORM || role === ROLE_ADMIN_SCHOOL || role === ROLE_ADMIN_COURSE) ? 'white--text' : 'black--text'">
                        {{$t('user.roles_list.'+role)}}
                    </v-chip>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                    <v-btn
                        rounded
                        fab
                        outlined
                        :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: item.id}}">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                fill="black"/>
                            <path
                                d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                                stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                        </svg>
                    </v-btn>
                    <v-btn class="ml-2"
                           rounded
                           fab
                           outlined
                           :to="{name: 'users.edit', params: {id: item.id, school_slug: $route.params.school_slug}}">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                        </svg>
                    </v-btn>
                    <v-btn class="ml-2"
                           depressed
                           rounded
                           fab
                           :color="(!!item.is_blocked) ? 'error' : 'primary'"
                           :outlined="!item.is_blocked"
                           @click="dialogDelete=true">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                                stroke="black" stroke-width="1.3"/>
                        </svg>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h2" style="word-break: normal;">{{ (!item.is_blocked) ? $t('block_confirm')
                    : $t('unblock_confirm') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">{{ $t('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{ $t('ok') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
    import {
        ROLE_ADMIN_PLATFORM,
        ROLE_ADMIN_SCHOOL,
        ROLE_ADMIN_COURSE,
        ROLE_STUDENT,
        ROLE_STUDENT_BASE,
        ROLE_STUDENT_PRO,
        ROLE_STUDENT_SUSPENDED
    } from '@/base.constants';

    export default {
        name    : 'UserItem',
        props   : {
            item: {
                type    : Object,
                required: true
            }
        },
        data() {
            return {
                ROLE_ADMIN_PLATFORM   : ROLE_ADMIN_PLATFORM,
                ROLE_ADMIN_SCHOOL     : ROLE_ADMIN_SCHOOL,
                ROLE_ADMIN_COURSE     : ROLE_ADMIN_COURSE,
                ROLE_STUDENT          : ROLE_STUDENT,
                ROLE_STUDENT_BASE     : ROLE_STUDENT_BASE,
                ROLE_STUDENT_PRO      : ROLE_STUDENT_PRO,
                ROLE_STUDENT_SUSPENDED: ROLE_STUDENT_SUSPENDED,
                dialogDelete          : false,
            }
        },
        computed: {
            roles() {
                let roles = [];
                for (let k in this.item.uniq_roles) {
                    let role = (k.length > 1) ? k : this.item.uniq_roles[k];
                    roles.push(role);
                }
                return roles;
            }
        },
        methods : {
            deleteItemConfirm() {
                this.dialogDelete = false;
                this.loading = true;
                axios.get('schools/' + this.$route.params.school_slug + '/users/' + this.item.id + '/toggle-blocked').then(response => {
                    this.item.is_blocked = !this.item.is_blocked;
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
            isAdmin() {
                return this.roles.includes(this.ROLE_ADMIN_SCHOOL) || this.roles.includes(this.ROLE_ADMIN_PLATFORM);
            },
            roleColor(role) {
                if (role === ROLE_ADMIN_PLATFORM || role === ROLE_ADMIN_SCHOOL || role === ROLE_ADMIN_COURSE) {
                    return 'primary';
                } else if (role === ROLE_STUDENT_SUSPENDED) {
                    return '#FF9E74';
                } else {
                    return 'accent';
                }
            }
        }
    }
</script>
