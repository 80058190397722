<template>
    <v-card outlined class="mb-4">
        <v-card-text>
            <v-row>
                <v-col cols="5">
                    <v-avatar
                        v-if="item.image"
                        class="profile float-left"
                        color="gray"
                        size="180"
                        tile
                    >
                        <v-img :src="item.image"></v-img>
                    </v-avatar>
                    <div class="text-h2 black--text">{{ item.title }}</div>
                    <div v-show="!!item.comment" class="grey--text">{{item.comment}}</div>
                </v-col>
                <v-col cols="2" class="text-center">
                    <v-btn
                        color="primary"
                        class="mx-1 mb-2"
                        small depressed
                        rounded
                        :outlined="item.role !== ROLE_ADMIN_COURSE"
                        @click="setRole(ROLE_ADMIN_COURSE)"
                    >
                        <span>{{$t('course.role.curator')}}</span>
                    </v-btn>
                    <v-btn
                        class="mx-1 mb-2"
                        :color="(item.role === ROLE_STUDENT || item.role === ROLE_STUDENT_BASE || item.role === ROLE_STUDENT_PRO) ? 'accent' : ''"
                        rounded depressed
                        small
                        :outlined="item.role !== ROLE_STUDENT && item.role !== ROLE_STUDENT_BASE && item.role !== ROLE_STUDENT_PRO"
                        @click="setRole(ROLE_STUDENT)"
                    >
                        <span>{{$t('course.role.student')}}</span>
                    </v-btn>
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn
                        class="mx-1 mb-2"
                        color="accent"
                        small depressed
                        rounded
                        :outlined="this.item.role !== ROLE_STUDENT"
                    >
                        <span>{{$t('course.user_status.student_all')}}</span>
                    </v-btn>
                    <v-btn
                        class="mx-1 mb-2"
                        color="accent"
                        small
                        rounded depressed
                        :outlined="this.item.role !== ROLE_STUDENT_BASE"
                    >
                        <span>{{$t('course.user_status.student_basic')}}</span>
                    </v-btn>
                    <v-btn
                        class="mx-1 mb-2"
                        color="accent"
                        small
                        rounded depressed
                        :outlined="this.item.role !== ROLE_STUDENT_PRO"
                    >
                        <span>{{$t('course.user_status.student_pro')}}</span>
                    </v-btn>
                </v-col>
                <v-col cols="1" class="text-center">
                    <v-btn
                        class="mx-1"
                        color="accent"
                        fab
                        x-small
                        outlined
                        rounded
                        :outlined="item.role !== ROLE_STUDENT_SUSPENDED"
                        :disabled="item.role === ROLE_STUDENT_SUSPENDED"
                        @click="setRole(ROLE_STUDENT_SUSPENDED)"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="black" stroke-width="1.3"/>
                        </svg>
                    </v-btn>
                    <v-btn
                        class="mx-1"
                        color="accent"
                        fab
                        rounded
                        x-small
                        outlined
                        v-if="item.role === ROLE_STUDENT || item.role === ROLE_STUDENT_BASE || item.role === ROLE_STUDENT_PRO"
                        @click="dialog=true"
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                        </svg>
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog
                transition="dialog-top-transition"
                max-width="640"
                v-model="dialog"
            >
                <v-card>
                    <v-card-text class="pt-4">
                        <v-card class="shadowless p-0 min-height">
                            <v-row class="mb-2">
                                <v-col cols="7">{{ $t('lecture.lecture') }}</v-col>
                                <v-col cols="5">{{ $t('lecture.access') }}</v-col>
                            </v-row>
                            <v-card outlined class="mb-6" v-show="false">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="7" class="d-flex flex-row align-center">
                                            <div class="ml-4 black--text">{{ $t('course.all_lectures') }}</div>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-btn
                                                color="accent btn-access"
                                                class="mx-1"
                                                small
                                                rounded depressed
                                            >
                                                    <span
                                                        :class="'black--text'">{{$t('opened_o')}}</span>
                                            </v-btn>
                                            <v-btn
                                                class="mx-1 btn-access"
                                                color="grey"
                                                small
                                                rounded depressed
                                            >
                                                <span :class="!item.is_open ? 'white--text' : 'black--text'">{{$t('closed_o')}}</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card outlined v-for="item in items" :key="item.id" class="mb-6">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="7" class="d-flex flex-row align-center">
                                            <v-avatar rounded
                                                      class="profile float-left m-1"
                                                      color="gray"
                                                      size="48"
                                                      tile
                                            >
                                                <v-img :src="item.image"></v-img>
                                            </v-avatar>
                                            <div class="ml-4 black--text">{{ item.title }}</div>
                                        </v-col>
                                        <v-col cols="5" class="d-flex flex-row align-center">
                                            <div v-if="item.is_passed">
                                                <v-chip label color="primary" class="p-1 btn-access white--text">{{$t('passed')}}
                                                </v-chip>
                                            </div>
                                            <div v-else>
                                                <v-btn
                                                    color="accent"
                                                    class="mx-1 btn-access"
                                                    small
                                                    rounded depressed
                                                    :outlined="!item.is_open"
                                                    @click="toggleLock(item)"
                                                >
                                                    <span :class="item.is_open ? 'black--text' : 'grey--text'">{{$t('opened_o')}}</span>
                                                </v-btn>
                                                <v-btn
                                                    class="mx-1 btn-access"
                                                    small
                                                    color="grey lighten-2"
                                                    rounded depressed
                                                    :outlined="item.is_open"
                                                    @click="toggleLock(item)"
                                                >
                                                    <span :class="!item.is_open ? 'black--text' : 'grey--text'">{{$t('closed_o')}}</span>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>

<script>

    import {
        PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE,
        PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO,
        PAYMENT_TYPE_COURSE_ALL
    } from '@/components/Course/course.constants';
    import {
        ROLE_STUDENT,
        ROLE_ADMIN_COURSE,
        ROLE_STUDENT_SUSPENDED,
        ROLE_STUDENT_BASE,
        ROLE_STUDENT_PRO
    } from '@/base.constants'

    export default {
        name   : 'CourseUserItem',
        props  : {
            item: {
                type    : Object,
                required: true
            },
            user: {
                type    : Object,
                required: true
            }
        },
        data() {
            return {
                PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE: PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE,
                PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO   : PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO,
                PAYMENT_TYPE_COURSE_ALL                : PAYMENT_TYPE_COURSE_ALL,
                ROLE_STUDENT                           : ROLE_STUDENT,
                ROLE_ADMIN_COURSE                      : ROLE_ADMIN_COURSE,
                ROLE_STUDENT_SUSPENDED                 : ROLE_STUDENT_SUSPENDED,
                ROLE_STUDENT_BASE                      : ROLE_STUDENT_BASE,
                ROLE_STUDENT_PRO                       : ROLE_STUDENT_PRO,
                dialog                                 : false,
                items                                  : []
            }
        },
        watch  : {
            dialog: {
                handler(val) {
                    if (!!val) {
                        this.load()
                    }
                },
                deep: true,
            },
        },
        methods: {
            setRole(role) {
                if ((role === this.ROLE_STUDENT_SUSPENDED && this.item.role !== this.ROLE_STUDENT_SUSPENDED) || (role !== this.ROLE_STUDENT_SUSPENDED && this.item.role === this.ROLE_STUDENT_SUSPENDED) || this.item.role === '') {
                    this.loading = true;
                    let url = '/courses/' + this.item.id + '/users/' + this.user.id + '/set-role/' + role;
                    axios.get(url).then(response => {
                        this.item.role = role;
                        this.item.type_payment = this.PAYMENT_TYPE_COURSE_ALL;
                        this.$emit('updated');
                    }).catch((error) => {
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            load() {
                this.loading = true;
                return axios.get('/courses/' + this.item.id + '/lectures/' + this.user.id).then(response => {
                    this.items = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            toggleLock(item) {
                this.loading = true;
                let action = (!!item.is_open) ? 'lock' : 'unlock';
                return axios.get('/lectures/' + item.id + '/' + action + '/' + this.user.id).then(response => {
                    item.is_open = !item.is_open;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
