<template>
    <span>
        <v-chip
            v-if="isOpen"
            label
            class="white--text"
            color="black"
        >
            {{ $t('post.status.open') }}
        </v-chip>
        <v-chip
            v-else-if="isHidden"
            label
            class="white--text"
            color="gray"
        >
            {{ $t('post.status.hidden') }}
        </v-chip>
    </span>
</template>

<script>
import {
    STATUS_POST_HIDDEN,
    STATUS_POST_OPEN,
} from '@/components/Infopost/post.constants'

export default {
    name: 'PostStatusLabel',
    props: {
        status: {
            type: Number,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {}
    },
    computed: {
        isOpen() {
            return this.status === STATUS_POST_OPEN;
        },
        isHidden() {
            return this.isAdmin && this.status === STATUS_POST_HIDDEN;
        },
    }
}
</script>
