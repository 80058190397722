<template>
    <v-row justify="center">
        <v-col cols="6"
               class="pt-spacer">
            <v-card
                :loading="loading" class="shadowless">
                <template slot="progress">
                    <Preloader :loading="loading"/>
                </template>
                <v-card-title class="text-center text-h2">{{this.course.title}}</v-card-title>
                <v-card-text>
                    <v-btn v-for="(button, index) in buttons" :key="index" :href="button.link"
                           block depressed
                           color="accent"
                           class="mb-6 black--text"
                    >
                        {{ button.product_title }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import {mapMutations} from 'vuex';

    export default {
        data() {
            return {
                course : {
                    title: ''
                },
                buttons: [],
                loading: false
            }
        },
        mounted() {
            this.loadCourse().then(() => {
                this.setBreadcrumbs([
                    {
                        text: this.$t('payment.buy_title') + ' ' + this.course.title,
                    },
                ]);
            });
            this.loadButtons();
        },
        methods: {
            ...mapMutations(['setBreadcrumbs']),
            loadCourse() {
                this.loading = true;
                return axios.get('courses/' + this.$route.params.course_id + '/base-data').then(response => {
                    this.course = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadButtons() {
                this.loading = true;
                axios.get('/courses/' + this.$route.params.course_id + '/payments/links').then(response => {
                    this.buttons = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>
