import Vue from 'vue'

window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Vue = require('vue').default;

import 'es6-promise/auto'
import './plugins/globalMixin'
import './plugins/day'
import store from './store/store'
import {vuetify} from './plugins/vuetify';
import i18n from './plugins/i18n'

import VueMasonry from 'vue-masonry-css';
import DatetimePicker from 'vuetify-datetime-picker'
import VueObserveVisibility from 'vue-observe-visibility'
import http from './plugins/http'
import router from './plugins/router'
import auth from './plugins/auth'
import App from './App.vue'

Vue.component('Preloader', require('./layout/Preloader.vue').default);

Vue.use(DatetimePicker);
Vue.use(VueMasonry);
Vue.use(VueObserveVisibility);
Vue.prototype.config = window.config;
export const app = new Vue({
    http,
    store,
    router,
    vuetify,
    auth,
    i18n,
    render: h => h(App)
}).$mount('#app')

window.Vue = app
