import school from '@/router/admin/school'
import Admin from '@/layout/Admin'
import AdminCourseIndex from '@/components/Course/AdminCourseIndex'
import SchoolLayout from '@/layout/SchoolLayout'

export default [
    {
        path     : 'admin',
        component: Admin,
        meta     : {
            auth: true
        },
        children: [
            ...school,
            {
                path      : ':school_slug',
                component : SchoolLayout,
                children  : [
                    {
                        path: 'courses',
                        component: AdminCourseIndex,
                        name: 'admin.courses.index',
                    }
                ]
            },
        ]
    }
]
