import ru from "vuetify/lib/locale/ru";

export default {
    $vuetify              : ru,
    yes                   : 'Да',
    no                    : 'Нет',
    ok                    : 'Ok',
    to                    : 'До',
    cancel                : 'Отмена',
    cancel2               : 'Отменить',
    open                  : 'Открыть',
    open_course           : 'Открыть курс',
    close                 : 'Закрыть',
    change                : 'Изменить',
    subscribe             : 'Записаться',
    save                  : 'Сохранить',
    all                   : 'все',
    save_changes          : 'Сохранить изменения',
    cancel_changes        : 'Отменить изменения',
    changes               : 'изменения',
    publish               : 'Опубликовать',
    add                   : 'Добавить',
    edit                  : 'Редактировать',
    show                  : 'Показать',
    restore               : 'Восстановить',
    delete                : 'Удалить',
    hide                  : 'Скрыть',
    copy                  : 'Дублировать',
    confirm               : 'Подтвердить',
    click_here            : 'Нажми сюда',
    delete_confirm        : 'Вы действительно хотите удалить это?',
    download_confirm      : 'Вы хотите сохранить этот файл?',
    block_confirm         : 'Вы действительно хотите заблокировать?',
    unblock_confirm       : 'Вы действительно хотите разблокировать?',
    preview               : 'Просмотр',
    new                   : 'Новый',
    actions               : 'Действия',
    id                    : 'ID',
    in                    : 'в',
    search                : 'Искать...',
    buy                   : 'Купить',
    become_student_free   : 'Стать студентом бесплатно',
    no_results            : 'Ничего не найдено',
    unknown_server_error  : 'Неизвестная ошибка сервера',
    upload                : 'Загрузить',
    uploader              : 'Перетащите ваши файлы сюда, или <em>нажмите загрузить</em>',
    loading               : 'Загрузка... Пожалуйста подождите',
    create                : 'Создать',
    detail                : 'Подробнее',
    successfully_saved    : 'Успешно сохранено',
    admin_menu_title      : 'Админка',
    status                : 'Статус',
    become_student_confirm: 'Вы уверены что хотите стать студентом?',
    understand            : 'Понятно',
    edited                : 'Отредактировано',
    editing               : 'Редактирование',
    opened_o              : 'Открыто',
    closed_o              : 'Закрыто',
    passed                : 'Пройдено',
    apply                 : 'Применить',
    footer_question       : 'Есть вопрос? Пишите',
    footer_question_here  : 'сюда.',
    copyright             : '&copy; 2021 Method Education',
    design_text           : 'Дизайн Other Land',
    month                 : 'месяц',
    year                  : 'год',
    read_all              : 'читать всё',
    back                  : 'Вернуться',
    likes                 : 'Лайки',
    more_likes            : 'И еще лайки',
    more_favorites        : 'Еще сохранения',
    favorites             : 'Сохранения',
    like_set              : 'Лайк автору поставлен – спасибо!',
    favorite_set_comments : 'Комментарий сохранен в вашем профиле',
    favorite_set_records  : 'Запись сохранена в вашем профиле',
    oops                  : 'Упс...',
    people_not_found      : 'Людей с таким логином нет :(',
    form                  : {
        rules: {
            required       : 'Поле является обязательным',
            email          : 'Не подходит. Попробуй ввести другую',
            password       : 'Не подходит. Попробуй ввести другой.',
            phone          : 'введите правильный телефон',
            recaptcha      : 'Введите капчу',
            min            : 'Длинна поля должна быть больше {attribute} символов',
            max            : 'Длинна поля должна быть меньше {attribute} символов',
            number         : 'Поле дожно быть числовым',
            password_repeat: {
                different: 'Пароли не совпадают',
            },
            with_lowercase : 'Поле дожно иметь хотябы одну маленькую букву',
            with_uppercase : 'Поле должно иметь хотябы одну большую букву',
            with_number    : 'Поле должно иметь хотябы одну цыфру',
            with_special   : 'Поле должно иметь хотябы однин специальный символ',
            url            : 'Поле должно быть ссылкой'
        }
    },
    auth                  : {
        token_expired_alert_title: 'Сессия истекла!',
        token_expired_alert_text : 'Войдите чтобы продолжить',
        email_label              : 'Почта',
        password_label           : 'Пароль',
        by_fb                    : 'Через Facebook',
        by_google                : 'Через Google',
        verification             : {
            title_success: 'Электронная почта успешно подтверждена',
            title_error  : 'Ошибка подтверждения электронной почты',
            resend_link  : 'Отправить письмо верификации еще раз',
            resend_button: 'Отправить',
            failed       : 'Ссылка верификации недействительная',
            not_verified : 'Электронная почта не подтверждена'
        },
        forgot_password          : {
            title                : 'Забыл пароль',
            button               : 'Востановить',
            notification_title   : 'На вашу почту<br>пришло письмо.',
            notification_subtitle: 'Нажмите на ссылку внутри,<br>чтобы восстановить пароль.'
        },
        confirm_email            : {
            notification_title   : 'На вашу почту<br>пришло письмо.',
            notification_subtitle: 'Нажмите на ссылку внутри,<br>чтобы подтвердить почту.'
        },
        reset_password           : {
            title             : 'Востановить пароль',
            button            : 'Отправить',
            new_password_title: 'Придумайте<br> новый пароль',
            new_password_label: 'Новый пароль',
            old_password_label: 'Старый пароль',
            title_success     : 'Пароль успешно изменен!',
            title_resend_email: 'Отправить письмо еще раз?',
        },
        invite                   : {
            title: 'Добро пожаловать.<br>Придумайте<br>пароль',
        },
        login                    : {
            title                      : 'Войдите',
            login_button               : 'Вход',
            or_through                 : 'или через',
            submit_button              : 'Войти',
            if_register_text           : 'если нет аккаунта',
            register_link              : 'Создайте',
            forgot_password_link       : 'Забыли?',
            if_isset_account_text      : 'если есть аккаунт.',
            if_password_remembered_text: 'если вспомнили.',
        },
        login_social             : {
            title    : 'Вход через соц. сеть',
            wait_text: 'Подождите...'
        },
        logout                   : {
            title: 'Выйти',
        },
        register                 : {
            title                      : 'Создайте аккаунт',
            password_confirmation_label: 'Подтвердите пароль',
            submit_button              : 'Регистрация',
        },
        logout_confirm           : {
            title        : 'Выход',
            text         : 'Вы будете розлогинены',
            button_ok    : 'Ok',
            button_cancel: 'Отмена',
        }
    },
    course                : {
        course                : 'Курс',
        courses               : 'Курсы',
        all_courses           : 'Все курсы',
        my_courses            : 'Мои курсы',
        course_list           : 'Список курсов',
        course_about          : 'О курсе',
        course_info           : 'всё о курсе',
        courses_pad           : 'курсов',
        no_results            : 'У этой школы пока нету курсов',
        add                   : 'Добавить курс',
        edit                  : 'Редактировать курс',
        slug_label            : 'Ссылка на лендинг курса',
        status_label          : 'Статус курса',
        access_type_label     : 'Тип доступа к курсу',
        name_label            : 'Название курса',
        add_payment_type_label: 'Добавить тип оплаты',
        content_type_label    : 'Тип контента',
        is_active_label       : 'Активен',
        is_finished           : 'Курс окончен',
        is_need_register_label: 'Регистрация на курс',
        link_google_form_label: 'Ссылка на форму записи',
        registration_open     : 'Открыт набор',
        description_label     : 'Краткое описание (до 60 символов)',
        soon                  : 'Скоро',
        image_label           : 'Картинка',
        preview_label         : 'Титульная картинка',
        seo_title_label       : 'Title',
        seo_description_label : 'Description',
        rating_label          : 'Рейтинг',
        program               : 'Программа',
        you_student           : 'Мой курс',
        teacher               : 'Учитель | Учителя',
        news_feed             : 'Лента новостей',
        you_curator           : 'К',
        detail                : 'Все о курсе',
        image_title           : 'Обложка курса',
        image_size_title      : 'До 5 мб: jpg,png',
        image_wh_title        : 'Размер 600*600',
        type_course           : 'Тип курса',
        post                  : 'нет постов | {n} пост | {n} поста | {n} постов',
        lecture               : 'нет лекций | {n} лекция | {n} лекции | {n} лекций',
        no_items              : 'Здесь ничего нет',
        open_from_edit        : 'Открыть в списке курсов',
        all_lectures          : 'Все лекции курса',
        access_given_free     : 'Доступ выдан бесплатно',
        for_course            : 'за курс',
        access_suspended      : 'доступ закончился',
        open_course_platform  : 'развернуть все остальные курсы платформы',
        pay_type              : {
            pro   : 'pro',
            all   : 'весь курс',
            simple: 'обычная',
        },
        payment               : {
            title                      : 'Оплата',
            method_label               : 'Метод оплаты',
            type_label                 : 'Тип оплаты',
            price_label                : 'Стоимость',
            paddle_product_id_label    : 'Название и Product / Plan ID',
            paddle_product_secret_label: 'Paddle product_secret',
            paid                       : 'Платный',
            free                       : 'Бесплатный',
            subscription               : 'Подписка',
            fixed_price                : 'Фиксированная цена',
        },
        type                  : {
            hidden: 'Скрыт',
            open  : 'Открыт набор',
            soon  : 'Скоро',
            closed: 'Закрыт набор',
        },
        access_type           : {
            tokenized: 'Токенизированный',
            opened   : 'Всё открыто',
        },
        payment_type          : {
            subscription_simple: 'Подписка (обычная)',
            subscription_pro   : 'Подписка (pro)',
            all                : 'Весь курс',
        },
        role                  : {
            curator: 'Куратор',
            student: 'Студент',
        },
        user_status           : {
            student_all  : 'студент (весь курс)',
            student_basic: 'студент (basic)',
            student_pro  : 'студент (pro)',
        },
        upgrade_to_pro        : 'Стать Pro за $',
        role_by_course        : 'Роль на курсе',
        notifications         : 'новости',
    },
    lecture               : {
        lecture                : 'Лекция',
        add                    : 'Новая лекция',
        edit                   : 'Редактирование лекции',
        lectures               : 'Лекции',
        name_label             : 'Название',
        status_label           : 'Статус',
        is_active_label        : 'Активна',
        is_blocked_label       : 'Не активна',
        is_hidden_title_label  : 'Скрыть название',
        image_title            : 'Обложка лекции',
        video_label            : 'Видео',
        short_description_label: 'Краткое описание',
        task_label             : 'Задание',
        publish_at_label       : 'Опубликован',
        is_grid_label          : 'Скрыт в гриде',
        is_testing_label       : 'Тестовый режим',
        is_moderate_label      : 'На модерацию',
        open_lectures          : 'Открытые лекции',
        hidden_lectures        : 'Скрытые',
        deleted_lectures       : 'Удалённые',
        show_author_label      : 'Показать автора поста',
        show_publish_date_label: 'Показать дату публикации',
        author_label           : 'Выберите автора',
        coauthor_label         : 'Выберите соавтора',
        discuss_type_label     : 'Тип обсуждения',
        opened_status          : 'Открыт',
        hidden_status          : 'Скрыт',
        comments_type          : 'Коментарии',
        records_type           : 'Рабочие тетради',
        settings_lecture_label : 'Настройки лекции',
        open_from_edit         : 'Превью лекции',
        access                 : 'Доступ к лекциям',
        hidden_label           : 'скрыт',
        unavailable_title      : "Эта лекция<br>вам пока-что<br>недоступна",
        unavailable_text       : 'Чтобы появилась возможность открыть её - сначала пройдите уже открытую лекцию',
        pending_title          : "Кураторы скоро проверят вашу работу!",
        pending_text           : 'И вы сможете открыть любую следующую лекцию.',
        video_after_buy        : "Просмотр видео будет доступен после покупки курса"
    },
    post                  : {
        post                   : 'Пост',
        posts                  : 'Посты',
        add                    : 'Новый пост',
        edit                   : 'Редактирование Инфопоста',
        name_label             : 'Название',
        status_label           : 'Статус',
        is_active_label        : 'Активен',
        is_blocked_label       : 'Не активен',
        comment_type_label     : 'Тип комментариев (список, сетка)',
        image_label            : 'Изображение для списка',
        short_description_label: 'Краткое описание',
        description_label      : 'Полное описание',
        publish_at_label       : 'Опубликован',
        is_grid_label          : 'Скрыт в гриде',
        is_testing_label       : 'Тестовый режим',
        settings_post_label    : 'Настройки инфопоста',
        opened_status          : 'Открыт',
        hidden_status          : 'Сткрыт',
        show_author_label      : 'Показать автора поста',
        show_publish_date_label: 'Показать дату публикации',
        author_label           : 'Выберите автора',
        coauthor_label         : 'Выберите соавтора',
        open_from_edit         : 'Превью поста',
        status                 : {
            hidden: 'Скрыт',
            open  : 'Открыт',
        }
    },
    school                : {
        school                         : 'Школа',
        schools                        : 'Школы',
        add                            : 'Добавление Школы',
        edit                           : 'Редактирование Школы',
        name_label                     : 'Название',
        slug_label                     : 'Слаг',
        description_label              : 'Описание',
        logo_label                     : 'Логотип',
        status_label                   : 'Статус',
        enabled                        : 'Активна',
        disabled                       : 'Неактивна',
        th_view                        : 'Посмотреть',
        th_view_btn                    : 'Курсы, посты...',
        courses_n_posts                : 'Курсы и посты',
        school_website                 : 'сайт школы',
        vimeo_id_label                 : 'Vimeo id',
        vimeo_secret_label             : 'Vimeo secret',
        vimeo_access_token_label       : 'Vimeo access token',
        record_vimeo_id_label          : 'Record vimeo id',
        record_vimeo_secret_label      : 'Record vimeo secret',
        record_vimeo_access_token_label: 'Record vimeo access token',
        paddle_vendor_id_label         : 'Paddle id',
        paddle_vendor_auth_code_label  : 'Paddle auth code',
        paddle_public_key_label        : 'Paddle public key',
        something_else                 : 'Что-то еще',
        privacy_label                  : 'Политика конфиденциальности',
        terms_label                    : 'Публичная оферта',
        fb_link_label                  : 'Facebook',
        instagram_link_label           : 'Instagram',
        vimeo_link_label               : 'Vimeo',
        website_link_label             : 'Website',
        support_link_label             : 'Есть вопрос? Пишите сюда.',
        someth                         : 'Что-то еще',
        status_invalid                 : 'Чтобы активировать - заполните все поля',
    },
    user                  : {
        add                  : 'Добавление пользователя',
        edit                 : 'Редактирование пользователя',
        create_user          : 'Создать пользователя',
        users                : 'Пользователи',
        school_users         : 'Пользователи школы',
        name_label           : 'Имя',
        last_name_label      : 'Фамилия',
        email_label          : 'Адрес эл. почты',
        role_label           : 'Роль',
        status_label         : 'Статус',
        password_label       : 'Пароль',
        is_admin_school_label: 'Админ школы',
        gender_label         : 'Пол',
        gender_male_label    : 'Мужской',
        gender_female_label  : 'Женский',
        avatar_label         : 'Аватар',
        name_th              : 'Имя, фамилия',
        access_th            : 'Доступ',
        all_roles            : 'Все роли',
        roles_th             : 'Роли на курсе',
        roles_list           : {
            'admin-platform'   : 'админ платформы',
            'admin-school'     : 'админ школы',
            'admin-course'     : 'куратор',
            'student-all'      : 'студент весь курс',
            'student-base'     : 'студент base',
            'student-pro'      : 'студент pro',
            'student-suspended': 'студент suspended',
        },
        invite               : 'Пригласить',
        invite_sent          : 'Приглашение отправлено',
        password_changed     : 'Пароль изменен',
        search_people        : 'Поиск человека',
        select_course_label  : 'Выберите курс',
        select_role_label    : 'Выберите роль',
        tab_main             : 'Основное',
        slug_label           : 'Логин',
        email_not_approved   : 'не подтверждена',
        email_approved       : 'подтверждена',
        change_password      : 'Измените пароль',
        tab_workplace        : 'Место работы',
        label_role           : 'Роль',
        label_company        : 'Компания',
        tab_livingplace      : 'Место жизни',
        label_country        : 'Страна',
        label_city           : 'Город',
        tab_bio              : 'Биография',
        label_bio            : 'Может, интересный факт о профессиональном опыте...',
        tab_socials          : 'Социальные сети',
        label_website        : 'Ваш сайт',
        label_instagram      : 'Instagram',
        label_facebook       : 'Facebook',
        label_behance        : 'Behance',
        label_dribbble       : 'Dribbble',
        label_pinterest      : 'Pinterest',
        label_twitter        : 'Twitter',
        label_youtube        : 'Youtube',
        label_linkedin       : 'Linkedin',
        tab_payment_history  : 'История покупок',
        tab_active_sessions  : 'Активные сессии',
        profile_photo        : 'Фото профиля',
        status               : 'Статус пользователя',
    },
    profile               : {
        title       : 'Профиль',
        my_profile  : 'Мой профиль',
        open_profile: 'Открыть профиль',
        edit_title  : 'Редактирование профиля',
        bio_title   : 'Биография',
        block       : 'Заблокировать',
        unblock     : 'Разблокировать',
        fill        : {
            title        : 'Расскажите о себе',
            continue     : 'Продолжить',
            skip         : 'Расскажу позже',
            name         : 'Имя',
            last_name    : 'Фамилия',
            add_avatar   : 'Добавить',
            your_photo   : 'Ваше фото',
            gender       : 'Как к вам обращаться?',
            gender_male  : 'Он',
            gender_female: 'Она'
        }
    },
    settings              : {
        title: 'Настройки'
    },
    record                : {
        records                : 'Записи',
        notebooks              : 'Тетради',
        saves                  : 'Сохранения',
        record_saved           : 'Сохраненные записи',
        work_records           : 'Рабочие тетради',
        my_records             : 'Моя тетрадь',
        add_new                : 'Новая запись',
        add_new_br             : 'Новая<br>запись',
        open_br                : 'Открыть<br>мои записи',
        add                    : 'Добавление Записи',
        edit                   : 'Редактирование Записи',
        choose_file            : 'Выбери файл',
        description_placeholder: '|Опишите процесс, идею, сложности эксперимента..',
        description_label      : 'Опиши идею',
        notify                 : 'Да, я хочу позвать кураторов оценить мою запись.',
        from                   : 'из',
        splines                : 'Сплайны',
        record_list            : 'Тетрадь',
        curator_notify         : 'Чтобы позвать куратора в запись добавьте описание и контент',
        photo_or_video         : '(фото, видео или файл).',
        add_success            : 'Запись добавлена!',
        add_success_text       : 'Открыть доступ к следующей  лекции можно будет в течении 24 часов. Но вы не ждите зря и экспериментируйте дальше.',
        add_record             : 'Добавьте запись',
        record_switch_text     : 'Позвать куратора в запись могут только участники с подпиской Pro',
        increase_text          : 'Улучшить за',
        loading                : 'Загружаем работы...',
        sort_best              : 'Сначала лучшие',
        sort_new               : 'Сначала новые',
        blocked                : 'Запись заблокирована'
    },
    news                  : {
        news: 'Новости'
    },
    platform              : {
        title: 'Платформа'
    },
    faq                   : {
        title: 'FAQ'
    },
    payment               : {
        buy_title: 'Купить'
    },
    terms                 : {
        title: 'Публичная оферта'
    },
    privacy               : {
        title: 'Конфиденциальность'
    },
    fb_link:  'Facebook',
    instagram_link: 'Instagram',
    vimeo_link: 'Vimeo',
    comments              : {
        lecture_comments_title: 'Обсуждение лекции',
        post_comments_title   : 'Обсуждение поста',
        placeholder           : 'Напишите коментарий',
        respond               : 'Ответить',
        upload_label          : 'Фото / видео / файл',
        upload_rules_label    : 'не больше 100 мб:',
        post                  : 'Запостить',
        deleted               : 'коментарий удалён автором',
        comments              : 'Комментарии',
        blocked               : 'Комментарий заблокирован',
        loading               : 'Загружаем комментарии...'
    },
    image_gallery         : {
        label     : 'Слайдер изображений',
        title     : 'Загрузка картинок',
        add       : 'Добавить слайдер картинок',
        image_link: 'Ссылка на изображение'
    },
    video_gallery         : {
        label     : 'Слайдер видео',
        title     : 'Загрузка видео',
        add       : 'Добавить видео слайдер',
        add_more  : 'Добавить еще видео',
        video_link: 'Ссылка на видео'
    },
    file: {
        validation: {
            max: {
                title: 'Выбранный файл очень тяжелый',
                description: 'Добавьте файл весом до 100 мб.',
            },
            format: {
                title: 'Расширение файла не подходит',
                description: 'Добавить можно только',
            }
        },
    },
    notifications         : {
        title                : 'Уведомления',
        news_feed            : 'Лента новостей',
        is_new               : 'новое',
        read_all             : 'Прочесть все',
        loading              : 'Загружаем новости...',
        types                : {
            new_lecture           : 'Добавлена лекция',
            new_post              : 'Добавлен пост',
            new_comment_to_record : 'Ответ в вашей тетради',
            response_to_comment   : 'Ответил на комментарий',
            mention_in_comment    : 'Упомянул в коментарии',
            new_comment_to_lecture: 'Комментарий к лекции',
            block_comment         : 'Заблокирован',
            unblock_comment       : 'Разблокирован',
            comment_request       : 'Тетрадь на проверку',
        },
        your_comment         : 'Ваш комментарий',
        confirm_read_all     : 'Точно хотите прочесть все уведомления?',
        confirm_read_all_btn : 'Да, прочесть все',
        confirm_read_all_text: 'Студенты остануться без вашей помощи и, может, у них пропадёт мотивация что-то делать.',
        readed_all           : 'Все новости в ленте прочитаны.',
        more_news            : 'и еще новости',
        send_settings        : {
            all     : 'Присылать все',
            comments: 'Комментарии к Записям',
            lectures: 'Лекции в курсе',
            responds: 'Ответы и упоминания',
        },
        empty                : 'Нет уведомлений'
    },
    search_page           : {
        placeholder     : 'Поиск по людям или словам',
        lectures_cnt    : 'лекций',
        show_more       : 'показать ещё',
        posts_cnt       : 'инфопостов',
        users_cnt       : 'профилей',
        comments_cnt    : 'комментариев',
        nothing_found   : 'Мы ничего не нашли по вашему запросу.',
        in_which_lecture: 'В какой лекции?',
        who_comment     : 'Чей комментарий?',
        content         : 'Содержание:'
    }
}
