import en from "vuetify/lib/locale/en";

export default {
    $vuetify: en,
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    cancel: 'Cancel',
    save: 'Save changes',
    add: 'Add',
    edit: 'Edit',
    show: 'Show',
    delete: 'Delete',
    download_confirm      : 'Do you want save this file?',
    preview: 'Preview',
    new: 'New',
    actions: 'Actions',
    search: 'Search...',
    no_results: 'No results found',
    unknown_server_error: 'Unknown server error',
    uploader: 'Place your files here, or <em>click to upload</em>',
    add_new: 'Add new',
    form: {
        rules: {
            required: 'Field is required',
            email: 'Please input correct email address',
            phone: 'Please enter your correct phone number',
            recaptcha: 'Please input captcha',
            min: 'Field length must be more then {attribute} characters',
            max: 'Field length must be less then {attribute} characters',
            number: 'Field should be a number',
            password_repeat: {
                different: 'Mismatch passwords',
            },
            with_lowercase: 'Field should contain at least one lowercase letter',
            with_uppercase: 'Field should contain at least one uppercase letter',
            with_number: 'Field should contain at least one number',
            with_special: 'Field should contain at least one special character',
            url: 'Field should be valid url'
        }
    },
    auth: {
        token_expired_alert_title: 'Session Expired!',
        token_expired_alert_text: 'Please log in again to continue.',
        verification: {
            title_success: 'Email successfully confirmed',
            title_error: 'Error email confirmation',
            resend_link: 'Resend verification',
            resend_button: 'Resend',
            failed: 'The verification link is invalid.',
            not_verified : 'Email not verified'
        },
        forgot_password: {
            title: 'Reset your password',
            button: 'Reset',
        },
        reset_password: {
            title: 'Reset your password',
            button: 'Reset',
        },
        login: {
            title: 'Log into your Account',
            login_button: 'LOG IN',
            submit_button: 'Submit',
            email_label: 'E-mail',
            password_label: 'Password',
            register_text: 'Don\'t have an account yet?',
            register_link: 'Register',
            forgot_password_link: 'Reset password',
        },
        logout: {
            title: 'Logout',
        },
        register: {
            title: 'Register now',
            email_label: 'E-Mail',
            password_label: 'Password',
            password_confirmation_label: 'Confirm your password',
            submit_button: 'Create account',
        },
        logout_confirm: {
            title: 'Confirm LogOut',
            text: 'You will be logged out',
            button_ok: 'Ok',
            button_cancel: 'Cancel',
        }
    },
    terms: {
        title: 'Terms of Service'
    },
    privacy: {
        title: 'Privacy Policy'
    },
    fb_link:  'Facebook',
    instagram_link: 'Instagram',
    vimeo_link: 'Vimeo',
}
