<template>
    <v-row justify="center">
        <v-col cols="12"
               class="pt-spacer">
            <div>
                <v-row>
                    <v-col cols="3">
                        <div>
                            <v-card :to="{ name: 'admin.courses.index', params: { school_slug: $route.params.school_slug }}">
                                <v-responsive :aspect-ratio="1" content-class="d-flex align-content-space-between flex-wrap">
                                    <v-card-title>{{ $t('school.courses_n_posts') }}</v-card-title>
                                    <v-card-text>
                                        <v-btn large depressed>
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-responsive>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <div>
                            <v-card :to="{ name: 'users.index', params: { school_slug: $route.params.school_slug }}">
                                <v-responsive :aspect-ratio="1" content-class="d-flex align-content-space-between flex-wrap">
                                    <v-card-title>{{$t('user.users')}}</v-card-title>
                                    <v-card-text>
                                        <v-btn large depressed>
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-responsive>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <div>
                            <v-card>
                                <v-responsive :aspect-ratio="1" content-class="d-flex align-content-space-between flex-wrap">
                                    <v-card-title>{{$t('news.news')}}</v-card-title>
                                    <v-card-text>
                                        <v-btn large depressed>
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-responsive>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <div>
                            <v-card>
                                <v-responsive :aspect-ratio="1" content-class="d-flex align-content-space-between flex-wrap">
                                    <v-card-title>{{$t('school.something_else')}}</v-card-title>
                                    <v-card-text>
                                        <v-btn large depressed>
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-responsive>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';

    export default {
        data()
        {
            return {
                loading : false
            }
        },
        computed: {
            ...mapGetters(['school'])
        },
        watch: {
            school(value) {
                if (!!value) {
                    this.setBreadcrumbs([
                        {
                            text: this.$t('admin_menu_title'),
                            to  : {name: 'admin.schools.index'}
                        },
                        { text : this.school.name },
                    ]);
                }
            }
        },
        methods  : {
            ...mapMutations(['setBreadcrumbs']),
        }
    }
</script>
