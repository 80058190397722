<template>
    <div class="d-inline-flex ml-2">
        <v-menu
            right
            open-on-hover
            bottom
            offset-y
            content-class="shadowless"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="default-font-size btn-gray-hover"
                       :class="(item.is_favorited) ? 'btn-gray-hover-active' : ''" small outlined
                       @click="toggleFavorite()"
                       @mouseover="loadFavorites"
                       v-bind="attrs"
                       v-on="on">
                    <span class="mr-1">{{item.count_favorites}}</span>
                    <img class="img-active" src="/img/favorite-black-icon.svg"/>
                    <img class="img-hover" src="/img/favorite-white-icon.svg"/>
                    <img class="img-default" src="/img/favorite.svg"/>
                </v-btn>
            </template>
            <v-list outlined style="min-width: 300px" v-show="users.length > 0">
                <v-list-item v-for="user in slicedUsers" :key="user.id" style="min-height: 24px;" class="my-2">
                    <v-card class="shadowless pa-0"
                            :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: user.id}}">
                        <div class="d-flex flex-row">
                            <v-avatar size="24" rounded color="white" class="mr-2"
                                      :class="(!!user.avatar) ? '' : 'outlined'">
                                <img v-if="!!user.avatar"
                                     :src="user.avatar"
                                     alt=""
                                >
                                <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black"
                                          stroke-opacity="0.4"
                                          stroke-width="1.3"/>
                                </svg>
                            </v-avatar>
                            <div>{{user.name}}</div>
                        </div>
                    </v-card>
                </v-list-item>
                <v-list-item v-show="users.length > 5" style="min-height: 24px;" class="my-2">
                    <v-card class="shadowless pa-0" @click="dialog=true">
                        <div class="d-flex flex-row">
                            <v-avatar size="24" rounded color="gray" class="mr-2 pa-1 bg-gray-custom">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_4127_52156--111)">
                                        <path
                                            d="M13 1C13 5 13 11 13 15L8.00047 10.25L3.00093 15C3.00093 11 3.00338 5 3 1C6.33333 1 9.66667 1 13 1Z"
                                            fill="black"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4127_52156--111">
                                            <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </v-avatar>
                            <div>{{$t('more_favorites')}}</div>
                        </div>
                    </v-card>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="dialog" max-width="360px">
            <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="dialog = false"
            >
                <v-icon
                    class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card>
                <v-card-title class="pb-6">{{$t('favorites')}}&nbsp;<span class="grey--text">[{{users.length}}]</span>
                </v-card-title>
                <v-card-text class="pa-10 pt-0">
                    <div class="mt-n3">
                        <v-card class="shadowless mt-3" v-for="user in users" :key="user.id"
                                :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: user.id}}">
                            <div class="d-flex flex-row align-center">
                                <v-avatar size="24" rounded color="white" class="mr-2"
                                          :class="(!!user.avatar) ? '' : 'outlined'">
                                    <img v-if="!!user.avatar"
                                         :src="user.avatar"
                                         alt=""
                                    >
                                    <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black"
                                              stroke-opacity="0.4"
                                              stroke-width="1.3"/>
                                    </svg>
                                </v-avatar>
                                <div>{{user.name}}</div>
                            </div>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {TYPE_LECTURE_COMMENTS, TYPE_LECTURE_RECORDS} from "@/components/Lecture/lecture.constants";

    export default {
        name    : 'FavoriteBtn',
        props   : {
            item: {
                type    : Object,
                required: true,
            },
        },
        data() {
            return {
                users                : [],
                loadedUsers          : false,
                dialog               : false,
                TYPE_LECTURE_COMMENTS: TYPE_LECTURE_COMMENTS,
                TYPE_LECTURE_RECORDS : TYPE_LECTURE_RECORDS,
            }
        },
        computed: {
            slicedUsers() {
                return this.users.slice(0, 5);
            }
        },
        methods : {
            toggleFavorite() {
                axios.post('/records/' + this.item.id + '/toggle-favorite').then(response => {
                    this.item.count_favorites = response.data.data.count_favorites;
                    this.item.is_favorited = response.data.data.is_favorited;
                    this.loadedUsers = false;
                    if (!!this.item.is_favorited) {
                        this.showMessage((this.item.type === TYPE_LECTURE_RECORDS) ? this.$t('favorite_set_records') : this.$t('favorite_set_comments'), 'accent');
                    }
                });
            },
            loadFavorites() {
                if (!this.loadedUsers) {
                    this.loadedUsers = true;
                    axios.get('/records/' + this.item.id + '/users-favorites').then(response => {
                        if (!!response.data.data) {
                            this.users = response.data.data;
                        } else {
                            this.users = [];
                        }
                    });
                }
            }
        }
    }
</script>
