<template>
    <v-row justify="center">
        <v-col cols="12" class="pt-spacer">
            <div class="mb-3 pb-1">
                <v-btn depressed
                       v-if="hasSchoolAdminAccess($route.params.school_slug)"
                       color="primary"
                       :to="{name: 'courses.add', params: {school_slug: $route.params.school_slug}}"
                >
                    <svg class="mr-2" width="16" height="15" viewBox="0 0 17 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="white" stroke-width="1.3"/>
                    </svg>
                    <span>{{$t('course.course')}}</span>
                </v-btn>
            </div>
            <v-card class="shadowless p-0 min-height" :loading="loading">
                <template slot="progress">
                    <Preloader :loading="loading"/>
                </template>
                <v-row class="mb-2">
                    <v-col cols="3">{{ $t('course.course') }}</v-col>
                    <v-col cols="3">{{ $t('post.post') }}</v-col>
                    <v-col cols="2">{{ $t('status') }}</v-col>
                    <v-col cols="4"></v-col>
                </v-row>
                <v-expansion-panels accordion>
                    <draggable v-if="courses.length > 0"
                               class="list-group-course"
                               ghost-class="ghost"
                               @end="moveCourse"
                               :list="courses">
                        <v-expansion-panel
                            v-for="course in courses"
                            :key="course.id"
                            class="mb-3"
                            @click.native.stop=""
                        >
                            <v-expansion-panel-header>
                                <AdminCourseItem :item="course" @delete="deleteItem" @copiedCourse="refresh"/>
                                <template v-slot:actions>
                                    <v-btn small
                                           outlined
                                           fab
                                           class="ml-1">
                                        <v-icon>$expand</v-icon>
                                    </v-btn>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row style="margin-right: -15px;">
                                    <v-col cols="3"></v-col>
                                    <v-col cols="9">
                                        <div v-if="course.posts.length + course.lectures.length">
                                            <draggable v-if="course.posts.length > 0"
                                                       class="list-group"
                                                       ghost-class="ghost"
                                                       @end="movePost(course)"
                                                       :list="course.posts">
                                                <AdminPostItem
                                                    v-for="post in course.posts"
                                                    :key="`post_${post.id}`"
                                                    :item="post"
                                                    :course="course"
                                                    entity="infoposts"
                                                    @copiedPost="copied"
                                                    @delete="deletePostLecture"
                                                />
                                            </draggable>
                                            <draggable v-if="course.lectures.length > 0"
                                                       class="list-group"
                                                       ghost-class="ghost"
                                                       @end="moveLecture(course)"
                                                       :list="course.lectures">
                                                <AdminPostItem
                                                    v-for="lecture in course.lectures"
                                                    :key="`lecture_${lecture.id}`"
                                                    :item="lecture"
                                                    :course="course"
                                                    entity="lectures"
                                                    @copiedPost="copied"
                                                    @delete="deletePostLecture"
                                                />
                                            </draggable>
                                        </div>
                                        <div v-else>
                                            {{ $t('course.no_items') }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </draggable>
                </v-expansion-panels>
            </v-card>
            <v-card v-observe-visibility="load" v-if="!loaded"></v-card>
        </v-col>
    </v-row>
</template>

<script>
    import draggable from 'vuedraggable'
    import {mapMutations} from 'vuex'
    import AdminCourseItem from '@/components/Course/parts/AdminCourseItem'
    import AdminPostItem from '@/components/Course/parts/AdminPostItem'
    import {DEFAULT_SCHOOL_NAME, DEFAULT_SCHOOL_SLUG} from "@/base.constants";

    export default {
        name      : 'AdminCourseIndex',
        components: {
            draggable,
            AdminCourseItem,
            AdminPostItem,
        },
        data() {
            return {
                expanded    : [],
                courses     : [],
                total       : 0,
                page        : 1,
                pageCount   : 0,
                itemsPerPage: 10,
                loaded      : false,
                loading     : false
            }
        },
        mounted() {
            this.setBreadcrumbs([
                {
                    text: this.$t('admin_menu_title'),
                    to  : {name: 'admin.schools.index'}
                },
                {
                    text: this.$t('school.courses_n_posts')
                },
            ]);
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            load(isVisible) {
                if (isVisible && !this.loaded && !this.loading) {
                    let app = this;
                    setTimeout(() => {
                        app.loading = true;

                        axios.get('/schools/' + app.$route.params.school_slug + '/courses-lectures-posts?page=' + app.page).then(response => {
                            if (Number(response.data.meta.last_page) > app.page) {
                                app.page++;
                            } else {
                                app.loaded = true;
                            }
                            app.courses.push(...response.data.data);
                        }).finally(() => {
                            app.loading = false;
                        });
                    }, 500);
                }
            },

            copied(entity_route, item) {
                for (let k in this.courses) {
                    if (Number(this.courses[k].id) === Number(item.course_id)) {
                        this.courses[k][entity_route].push(item);
                    }
                }
            },

            refresh() {
                this.courses = [];
                this.page = 1;
                this.loaded = false;
            },

            deleteItem(item) {
                this.confirmDialog(this.$t('delete'), this.$t('delete_confirm')).then(() => {
                    axios.delete('/schools/' + item.school.slug + '/courses/' + item.id).then(() => {
                        let index = this.courses.indexOf(item)
                        this.courses.splice(index, 1)
                    })
                })
            },

            deletePostLecture(course, item, entity) {
                this.confirmDialog(this.$t('delete'), this.$t('delete_confirm')).then(() => {
                    let course_index = this.courses.indexOf(course)
                    if (entity === 'lectures') {
                        axios.delete('/lectures/' + item.id).then(() => {
                            let index = this.courses[course_index].lectures.indexOf(item)
                            this.courses[course_index].lectures.splice(index, 1)
                        })
                    } else if (entity === 'infoposts') {
                        axios.delete('/posts/' + item.id).then(() => {
                            let index = this.courses[course_index].posts.indexOf(item)
                            this.courses[course_index].posts.splice(index, 1)
                        })
                    }
                })
            },

            moveCourse(e) {
                let data = [];
                for (let k in this.courses) {
                    data.push({
                        id      : this.courses[k].id,
                        position: k
                    });
                }
                axios.post('/schools/' + this.$route.params.school_slug + '/courses/position', data).then(response => {
                    this.showMessage(this.$t('successfully_saved'));
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },

            movePost(course) {
                let data = [];
                for (let k in course.posts) {
                    data.push({
                        id      : course.posts[k].id,
                        position: k
                    });
                }
                axios.post('/courses/' + course.id + '/posts/position', data).then(response => {
                    this.showMessage(this.$t('successfully_saved'));
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },

            moveLecture(course) {
                let data = [];
                for (let k in course.lectures) {
                    data.push({
                        id      : course.lectures[k].id,
                        position: k
                    });
                }
                axios.post('/courses/' + course.id + '/lectures/position', data).then(response => {
                    this.showMessage(this.$t('successfully_saved'));
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .v-expansion-panel-header.v-expansion-panel-header--active {
        border-bottom: solid 1px #eee;
        margin-bottom: 15px;
    }

    .list-group-course {
        width: 100%;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
</style>
