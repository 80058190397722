<template>
    <span>
        <v-chip
            v-if="isSoon"
            label
            class="black--text"
            color="gray"
        >
            {{ $t('course.soon') }}
        </v-chip>
        <v-chip
            v-else-if="isOpen"
            label
            class="black--text"
            color="gray"
        >
            {{ $t('course.registration_open') }}
        </v-chip>
        <v-chip
            v-else-if="isHidden"
            label
            class="black--text"
            color="gray"
        >
            {{ $t('course.type.hidden') }}
        </v-chip>
        <v-chip
            v-else-if="isClosed"
            label
            class="black--text"
            color="gray"
        >
            {{ $t('course.type.closed') }}
        </v-chip>
    </span>
</template>

<script>
import {
    STATUS_COURSE_CLOSED,
    STATUS_COURSE_HIDDEN,
    STATUS_COURSE_OPEN,
    STATUS_COURSE_SOON
} from '@/components/Course/course.constants'

export default {
    name: 'CourseStatusLabel',
    props: {
        status: {
            type: Number,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {}
    },
    computed: {
        isOpen() {
            return this.status === STATUS_COURSE_OPEN;
        },
        isSoon() {
            return this.status === STATUS_COURSE_SOON;
        },
        isHidden() {
            return this.isAdmin && this.status === STATUS_COURSE_HIDDEN;
        },
        isClosed() {
            return this.status === STATUS_COURSE_CLOSED;
        },
    }
}
</script>
