<template>
    <div class="record-card-block">
        <v-card :outlined="preview.type == 'text'" class="shadowless"
                :to="{name: 'records.show', params: {lecture_id : lecture.id, user_id:item.user.id, id: item.id, school_slug: $route.params.school_slug}}">
            <v-responsive :aspect-ratio="1" v-if="preview.type === 'text'">
                <v-card-text class="d-flex justify-center">
                    <div class="comment-preview-text black--text-i" v-if="preview.type === 'text'">{{content}}</div>
                </v-card-text>
            </v-responsive>
            <div :aspect-ratio="1" v-else-if="preview.type === 'video'">
                <img class="img-mw" :src="preview.path" />
            </div>
            <v-responsive :aspect-ratio="1" v-else>
                    <img :src="preview.path" class="img-mw"/>
            </v-responsive>
            <!--<img v-if="preview.type === 'video'" class="img-mw" :src="preview.path" />
            <img v-else :src="preview.path" class="img-mw"/>-->
        </v-card>
        <div class="pb-1 mt-2">
            <div class="d-flex flex-row w-100">
                <div>
                    <v-avatar size="24" color="indigo" class="mr-2 rounded"
                              :class="(!!item.user.avatar) ? '' : 'outlined'">
                        <img v-if="!!item.user.avatar"
                             :src="item.user.avatar"
                             alt="John"
                        >
                        <v-icon v-else dark>
                            mdi-account-circle
                        </v-icon>
                    </v-avatar>
                </div>
                <div>{{item.user.name}} {{item.user.last_name}}</div>
                <div class="ml-auto grey--text">
                    {{dateFormattedDate(item.created_at)}}
                </div>
                <v-chip v-if="!!item.is_star"
                        color="accent"
                        label small
                        class="d-block ml-2 chip-square">
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.9392 12.4372L3.99468 15L4.9392 9.57195L0.933594 5.72816L6.46133 4.9384L8.93359 0L11.4059 4.9384L16.9336 5.72816L12.928 9.57195L13.8725 15L8.9392 12.4372Z"
                            fill="black"/>
                    </svg>
                </v-chip>
            </div>
        </div>
    </div>
</template>
<script>
    import {CONTENT_LENGTH} from '@/base.constants';

    export default {
        name    : 'RecordItem',
        props   : {
            item   : {
                type    : Object,
                required: true,
            },
            lecture: {
                type    : Object,
                required: true,
            },
        },
        computed: {
            preview() {
                let pr = {type: 'text'};
                let file = this.item.files.find(o => !!o.videoId);

                if (!!file) {
                    // pr = {type: 'video', path: 'https://vumbnail.com/'+file.videoId+'.jpg'};
                    pr = {type: 'video', path: file.path};
                } else {
                    for (let k in this.item.files) {
                        if (this.item.files[k].mime_type === 'image/jpeg' || this.item.files[k].mime_type === 'image/png' || this.item.files[k].mime_type === 'image/gif') {
                            pr = {type: 'image', path: this.item.files[k].path};
                            break;
                        }
                    }
                }
                return pr;
            },
            content() {
                let content = this.item.content;
                if (content.length > CONTENT_LENGTH) {
                    content = content.substr(1, CONTENT_LENGTH)+'...';
                }
                return content;
            }
        }
    }
</script>
