import {DEFAULT_SCHOOL_SLUG} from '@/base.constants'

export default name => {
    return {
        path : '',
        name      : name,
        meta      : { auth : undefined },
        redirect: {
            name: 'courses.index',
            params: { school_slug: DEFAULT_SCHOOL_SLUG }
        }
    }
}

