<template>
    <v-card tag="div" :to="!!linkTo ? linkTo : ''" outlined class="mb-4 hover-bordered">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="3" class="d-flex flex-row">
                    <div>
                        <v-avatar v-if="!!toItem.image" size="48" rounded color="white" class="mr-3"
                                  :class="(!!toItem.image) ? '' : 'outlined'">
                            <img :src="toItem.image" alt="">
                        </v-avatar>
                        <div v-else-if="!!hasVideo" class="mr-3">
                            <img src="/img/video-image-sm.svg"/>
                        </div>
                        <div v-else-if="Number(item.type) === TYPE_NEW_POST" class="mr-3">
                            <img :src="'/img/text-image-sm.svg'" alt=""/>
                        </div>
                        <div v-else class="mr-3">
                            <img src="/img/no-cover.svg"/>
                        </div>
                    </div>
                    <div>
                        <div class="black--text">
                            <div v-if="!!toItem">{{toItem.title}}</div>
                        </div>
                        <div class="grey--text">{{dateFormattedComment(item.created_at)}}</div>
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <NotificationCard :item="item"/>
                </v-col>
                <v-col cols="12" md="5" v-html="content"></v-col>
                <v-col cols="12" md="1" class="text-right notification-status-label">
                    <div v-if="!item.is_read">
                        <v-chip label class="black--text" color="accent">
                            {{$t('notifications.is_new')}}
                        </v-chip>
                        <v-btn @click.stop.prevent="read" class="hover-bordered"
                               small
                               rounded
                               fab
                               outlined>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.00507 7.40257L6.40193 11.7994L14.0021 4.19922" stroke="black"
                                      stroke-width="1.3" stroke-linecap="square"/>
                            </svg>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import {
        TYPE_NEW_LECTURE,
        TYPE_NEW_POST,
        TYPE_NEW_COMMENT_TO_RECORD,
        TYPE_RESPONSE_TO_COMMENT,
        TYPE_MENTION_IN_COMMENT,
        TYPE_NEW_COMMENT_TO_LECTURE,
        TYPE_BLOCK_COMMENT,
        TYPE_UNBLOCK_COMMENT,
        TYPE_COMMENT_REQUEST
    } from "@/components/Notifications/notification.constants";
    import {TYPE_LECTURE_COMMENTS, TYPE_LECTURE_RECORDS} from "@/components/Lecture/lecture.constants";
    import NotificationCard from "@/components/Notifications/parts/NotificationCard";

    export default {
        name: 'NotificationListItem',
        components: {NotificationCard},
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            linkTo() {
                if (this.item.type === TYPE_BLOCK_COMMENT
                    || this.item.type === TYPE_UNBLOCK_COMMENT
                    || this.item.type === TYPE_NEW_COMMENT_TO_LECTURE
                    || this.item.type === TYPE_RESPONSE_TO_COMMENT
                    || this.item.type === TYPE_MENTION_IN_COMMENT
                    || this.item.type === TYPE_NEW_COMMENT_TO_RECORD
                    || this.item.type === TYPE_COMMENT_REQUEST) {
                    let uid = (this.item.type === TYPE_NEW_COMMENT_TO_RECORD) ? this.item.user_id : this.item.user.id;
                    return (this.item.lecture.type === TYPE_LECTURE_RECORDS) ? {
                        name: 'records.show',
                        params: {
                            lecture_id: this.item.lecture.id,
                            user_id: uid,
                            id: this.item.notifiable_id,
                            school_slug: this.$route.params.school_slug
                        }
                    } : {
                        name: 'lectures.show',
                        params: {
                            id: this.item.lecture.id,
                            comment_id: this.item.notifiable_id,
                            school_slug: this.$route.params.school_slug
                        }
                    };
                } else if (this.item.type === TYPE_NEW_LECTURE) {
                    return {
                        name: 'lectures.show',
                        params: {
                            id: this.item.lecture.id,
                            school_slug: this.$route.params.school_slug
                        }, query: {course_id: this.item.lecture.course_id}
                    }
                } else if (this.item.type === TYPE_NEW_POST) {
                    return {
                        name: 'infoposts.show',
                        params: {
                            id: this.item.post.id,
                            school_slug: this.$route.params.school_slug
                        }
                    }
                }

                return false;
            },
            toItem() {
                if (!!this.item.lecture) {
                    return this.item.lecture;
                }
                if (!!this.item.post) {
                    return this.item.post;
                }
                return false;
            },
            hasVideo() {
                if (!!this.item.lecture) {
                    return this.item.lecture.hasVideo;
                }
                return false;
            },
            content() {
                if (this.item.content.length <= 100) {
                    return this.item.content;
                }
                return this.item.content.substr(0, 100 - 1) + '&hellip;'
            }
        },
        data() {
            return {
                TYPE_NEW_LECTURE: TYPE_NEW_LECTURE,
                TYPE_NEW_POST: TYPE_NEW_POST,
                TYPE_NEW_COMMENT_TO_RECORD: TYPE_NEW_COMMENT_TO_RECORD,
                TYPE_RESPONSE_TO_COMMENT: TYPE_RESPONSE_TO_COMMENT,
                TYPE_MENTION_IN_COMMENT: TYPE_MENTION_IN_COMMENT,
                TYPE_NEW_COMMENT_TO_LECTURE: TYPE_NEW_COMMENT_TO_LECTURE,
                TYPE_BLOCK_COMMENT: TYPE_BLOCK_COMMENT,
                TYPE_UNBLOCK_COMMENT: TYPE_UNBLOCK_COMMENT,
                TYPE_COMMENT_REQUEST: TYPE_COMMENT_REQUEST,
                TYPE_LECTURE_COMMENTS: TYPE_LECTURE_COMMENTS,
                TYPE_LECTURE_RECORDS: TYPE_LECTURE_RECORDS,
            }
        },
        methods: {
            read() {
                let url = 'notifications/' + this.item.id + '/read';
                axios.get(url).then(response => {
                    this.$emit('updated', response.data.data)
                }).finally(() => {
                });
            }
        }
    }
</script>
