import Index from '@/components/Index.vue'

export default [
    {
        path      : '/login',
        component : Index,
        name      : 'login',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/login',
        component : Index,
        name      : 'login_by_school',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/registration',
        component : Index,
        name      : 'registration_by_school',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/login/:type',
        component : Index,
        name      : 'login_social',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/registration',
        component : Index,
        name      : 'registration',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/reset/:token',
        component : Index,
        name      : 'reset',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/reset/:token',
        component : Index,
        name      : 'reset_by_school',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/invite-user/:token',
        component : Index,
        name      : 'invite',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/forgot',
        component : Index,
        name      : 'forgot',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/forgot',
        component : Index,
        name      : 'forgot_by_school',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/forgot-sent',
        component : Index,
        name      : 'forgotsent',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/forgot-sent',
        component : Index,
        name      : 'forgotsent_by_school',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/confirmation-sent',
        component : Index,
        name      : 'confirmationsent',
        meta      : {
            guest : true,
            auth  : false
        }
    },
    {
        path      : '/:school_slug/email/verify/:id',
        component : Index,
        name      : 'confirmationenter',
        meta      : {
            guest : true,
            auth  : false
        }
    },
]
