<template>
    <v-dialog
        v-model="$store.state.showEmailConfirmationPopup"
        persistent
        max-width="360"
    >
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            :to="{name: 'courses.index', params: {school_slug: this.$route.params.school_slug}}"
            @click="showEmailConfirmation({dialog: false, email: ''})"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading" v-if="$route.name !== 'confirmationenter'">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2 pb-0" v-html="$t('auth.confirm_email.notification_title')"></v-card-title>
            <v-card-text class="pt-0">
                <div class="mb-6 mt-1" v-html="$t('auth.confirm_email.notification_subtitle')"></div>
                <!--v-btn block color="black" class="white--text" x-large @click="goLogin">
                    {{ $t('auth.login.submit_button') }}
                </v-btn-->
                <div class="mt-6">
                    <a @click="submit">
                        <img src="/img/comment-arrow.svg"/>
                        {{ $t('auth.reset_password.title_resend_email') }}
                    </a>
                </div>
            </v-card-text>
        </v-card>
        <v-card :loading="loading" v-else>
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name   : 'EmailConfirmationBlock',
        data() {
            return {
                loading: false
            }
        },
        mounted() {
            if (this.$route.name === 'confirmationenter') {
                this.loading = true;
                const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
                axios.post('/email/verify/' + this.$route.params.id + '?' + qs(this.$route.query), {
                    expires  : this.$route.query.expires,
                    signature: this.$route.query.signature
                }).finally(() => {
                    this.loading = false;
                    this.showEmailConfirmation({dialog: false, email: ''});
                    if (!!this.$route.params.school_slug) {
                        this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}});
                    } else {
                        this.$router.push({name: 'login'});
                    }
                });
            }
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    this.showEmailConfirmation(false);
                }
            });
        },
        methods: {
            ...mapMutations(['showEmailConfirmation']),
            submit() {
                this.loading = true;
                axios.post('/email/resend', {email: this.$store.state.confirmationEmail}).finally(() => {
                    this.loading = false;
                });
            },
            goLogin() {
                this.showEmailConfirmation({dialog: false, email: ''});
                if (!!this.$route.params.school_slug) {
                    this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}});
                } else {
                    this.$router.push({name: 'login'});
                }
            },
        }
    }
</script>
