import Page from "@/layout/Page";
import NotificationsIndex from "@/components/Notifications/NotificationsIndex";

export default [
    {
        path     : 'notifications',
        component: Page,
        children : [
            {
                path     : ':id?',
                component: NotificationsIndex,
                name     : 'notifications.index',
            },
        ]
    }
]
