import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './../lang'
import {changeDayjsLocale} from './day'
import {changeVuetifyLocale} from './vuetify'
import 'vuetify'

Vue.use(VueI18n)

const DEFAULT_LANGUAGE = 'ru'
changeDayjsLocale(DEFAULT_LANGUAGE)
changeVuetifyLocale(DEFAULT_LANGUAGE)

const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    messages,
    silentTranslationWarn: true,
    pluralizationRules: {
        /**
         * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} an overall amount of available choices
         * @returns a final choice index to select plural word by
         */
        'ru': function(choice, choicesLength) {
            // this === VueI18n instance, so the locale property also exists here

            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    }
})

export async function setI18nLanguage (lang) {
    changeDayjsLocale(lang)
    changeVuetifyLocale(lang)
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export default i18n
