<template>
    <div class="pt-spacer">
        <div class="d-flex flex-row justify-space-between align-center mb-3 mb-md-0">
            <div>
                <v-select class="d-none d-md-flex only-select" :items="courses" @change="refresh"
                          v-model="selected_course"
                ></v-select>
                <a class="d-block d-md-none" href="#" @click.prevent="dialogSelectCourse=true">
                    {{$t('course.all_courses')}}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6.33594L8 11.6625L13 6.33594" stroke="black" stroke-width="1.3"/>
                    </svg>
                </a>
            </div>
            <div>
                <a href="#" @click.prevent="dialogReadAll = true">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.66669 7.99935L8.00002 11.3327L14.6667 4.66602" stroke="black" stroke-width="1.3"
                              stroke-linecap="square"/>
                        <path d="M7.99998 7.99935L11.3333 4.66602M1.33331 7.99935L4.66665 11.3327L1.33331 7.99935Z"
                              stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                    </svg>
                    {{$t('notifications.read_all')}}
                </a>
            </div>
        </div>
        <div v-if="items.length > 0">
            <NotificationListItem @updated="updated" v-for="item in items" :item="item" :id="'notification-'+item.id"
                                  :key="item.id"/>
        </div>
        <div class="text-center pa-4" v-else-if="items.length === 0 && !loading">
            {{$t('notifications.empty')}}
        </div>
        <div class="shadowless text-center" v-observe-visibility="load" v-if="!loaded">{{$t('notifications.loading')}}
        </div>
        <v-dialog v-model="dialogReadAll" max-width="360px">
            <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="dialogReadAll = false"
            >
                <v-icon
                    class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card>
                <v-card-text class="pa-10">
                    <div class="text-h2 mb-2">{{$t('notifications.confirm_read_all')}}</div>
<!--
                    <div>{{$t('notifications.confirm_read_all_text')}}</div>
-->
                    <div class="mt-6">
                        <v-btn depressed block color="error" class="mb-3" @click="readAll">
                            {{$t('notifications.confirm_read_all_btn')}}
                        </v-btn>
                        <v-btn depressed block @click="dialogReadAll=false">{{ $t('cancel') }}</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSelectCourse" max-width="360px">
            <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="dialogSelectCourse = false"
            >
                <v-icon
                    class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card>
                <v-card-text class="pa-10">
                    <div class="mt-n3">
                        <v-card class="shadowless mt-3"
                                @click="setCourse(0)">
                            <div class="d-flex flex-row align-center">
                                <div>
                                    <v-avatar size="48" rounded color="white" class="mr-3 outlined">
                                        <v-icon color="primary">
                                            mdi-image-outline
                                        </v-icon>
                                    </v-avatar>
                                </div>
                                <div>
                                    <div>{{$t('course.all_courses')}}</div>
                                </div>
                            </div>
                        </v-card>
                        <v-card class="shadowless mt-3" v-for="course in coursesData" :key="course.id"
                                @click="setCourse(course.id)">
                            <div class="d-flex flex-row align-center">
                                <div>
                                    <v-avatar size="48" rounded color="white" class="mr-3"
                                              :class="(!!course.image) ? '' : 'outlined'">
                                        <img v-if="!!course.image"
                                             :src="course.image"
                                             alt=""
                                        >
                                        <v-icon color="primary" v-else>
                                            mdi-image-outline
                                        </v-icon>
                                    </v-avatar>
                                </div>
                                <div>
                                    <div>{{course.title}}</div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import NotificationListItem from "@/components/Notifications/parts/NotificationListItem";
    import {mapActions, mapMutations} from "vuex";

    export default {
        components: {NotificationListItem},
        data() {
            return {
                items             : [],
                courses           : [
                    {
                        value: 0,
                        text : this.$t('course.all_courses')
                    }
                ],
                coursesData       : [],
                total             : 0,
                page              : 1,
                pageCount         : 0,
                loading           : false,
                loaded            : false,
                selected_course   : 0,
                dialogReadAll     : false,
                dialogSelectCourse: false,
                is_scrolled       : false
            }
        },
        watch     : {
            '$store.state.countUnreadNotifications': {
                handler: function (search) {
                    this.refresh();
                }
            }
        },
        mounted() {
            this.loadCourses();
            this.setBreadcrumbs([
                {
                    text    : this.$t('notifications.news_feed'),
                    disabled: true,
                    to      : {
                        name: 'notifications.index',
                        params: this.$route.params.school_slug
                    }
                },
            ]);
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            ...mapActions(['fetchCountUnreadNotifications']),
            load(isVisible) {
                if (isVisible && !this.loaded && !this.loading) {
                    let app = this;
                    setTimeout(() => {
                        app.loading = true;

                        let url = '/schools/'+this.$route.params.school_slug+'/notifications?page=' + this.page;
                        if (app.selected_course > 0) {
                            url += '&filterByCourse=' + app.selected_course;
                        }

                        axios.get(url).then(response => {
                            if (response.data.data) {
                                if (Number(response.data.meta.last_page) > app.page) {
                                    app.page++;
                                } else {
                                    app.loaded = true;
                                }
                                app.items.push(...response.data.data);
                            }
                            setTimeout(function () {
                                if (!!app.$route.params.id && app.isIdPresent(app.$route.params.id) && !app.is_scrolled) {
                                    app.$vuetify.goTo('#notification-' + app.$route.params.id, {offset: 90});
                                    app.is_scrolled = true;
                                }
                            }, 500)
                        }).finally(() => {
                            app.loading = false;
                        });
                    }, 500);
                }
            },
            loadCourses() {
                return axios.get('schools/' + this.$route.params.school_slug + '/user-courses').then(response => {
                    this.coursesData = response.data.data;
                    for (let k in response.data.data) {
                        this.courses.push({
                            value: response.data.data[k].id,
                            text : response.data.data[k].title
                        })
                    }
                }).finally(() => {
                });
            },
            refresh() {
                this.items = [];
                this.page = 1;
                this.loaded = false;
            },
            readAll() {
                let url = 'notifications/read';
                if (this.selected_course > 0) {
                    url += '/' + this.selected_course
                }
                axios.get(url).then(response => {
                    this.showMessage(this.$t('notifications.readed_all'), 'error');
                    this.refresh();
                    this.dialogReadAll = false;
                    this.fetchCountUnreadNotifications(this.$route.params.school_slug);
                }).finally(() => {
                });
            },
            updated(item) {
                for (let k in this.items) {
                    if (this.items[k].id === item.id) {
                        this.items.splice(k, 1);
                    }
                }
                let readFound = false;
                for (let k in this.items) {
                    if (!!this.items[k].is_read) {
                        this.items.splice(k, 0, item);
                        readFound = true;
                    }
                }
                if (!readFound) {
                    this.items.push(item);
                }
                this.fetchCountUnreadNotifications(this.$route.params.school_slug);
            },
            setCourse(id) {
                this.selected_course = id;
                this.dialogSelectCourse = false;
                this.refresh();
            },
            isIdPresent(id) {
                for (let k in this.items) {
                    if (Number(this.items[k].id) === Number(id)) {
                        return true;
                    }
                }
                return false;
            },
        }
    }
</script>
