<template>
    <v-row justify="center">
        <v-col cols="12"
               class="pt-spacer">
            <div>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    :options.sync="options"
                    :server-items-length="total"
                    class="elevation-1"
                    @page-count="pageCount = $event"
                    :loading="loading"
                    :loading-text="$t('loading')"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>{{ $t('school.schools') }}</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <router-link :to="{ name: 'admin.schools.show', params: { school_slug: item.slug }}">
                            {{ item.name }}
                        </router-link>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-chip v-if="item.status"
                                :color="'green'"
                                dark
                        >
                            {{ $t('school.enabled') }}
                        </v-chip>
                        <v-chip v-else
                                :color="'red'"
                                dark
                        >
                            {{ $t('school.disabled') }}
                        </v-chip>
                    </template>
                    <template v-slot:item.logo="{ item }">
                        <img style="max-width: 100px;" v-show="!!item.logo" :src="item.logo"/>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn
                            v-if="hasSchoolAdminAccess(item.slug)"
                            depressed
                            rounded
                            fab
                            outlined
                            @click="editItem(item)">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z" stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                            </svg>
                        </v-btn>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                    ></v-pagination>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
    import {mapMutations} from 'vuex';
    import {DEFAULT_SCHOOL_NAME, DEFAULT_SCHOOL_SLUG} from '@/base.constants'

    export default {
        data() {
            return {
                items         : [],
                total         : 0,
                page          : 1,
                pageCount     : 0,
                itemsPerPage  : 10,
                options       : {},
                headers       : [
                    {
                        text    : this.$t('id'),
                        align   : 'start',
                        sortable: true,
                        value   : 'id',
                    },
                    {
                        text    : this.$t('school.logo_label'),
                        align   : 'start',
                        sortable: false,
                        value   : 'logo',
                    },
                    {
                        text : this.$t('school.name_label'),
                        align: 'start',
                        value: 'name',
                    },
                    {
                        text : this.$t('school.status_label'),
                        align: 'center',
                        value: 'status'
                    },
                    {
                        text    : this.$t('actions'),
                        align   : 'end',
                        value   : 'actions',
                        sortable: false
                    },
                ],
                dialog           : false,
                dialogDelete     : false,
                currentItemId    : null,
                loading          : false,
            }
        },
        watch  : {
            options: {
                handler() {
                    this.load()
                },
                deep: true,
            },
        },
        create() {
            let school_slug = this.getAdminOneSchool();
            if (!!school_slug) {
                return this.$router.push({
                    name  : 'admin.schools.show',
                    params: {school_slug: school_slug}
                });
            }
        },
        mounted() {
            this.setBreadcrumbs([
                {
                    text: DEFAULT_SCHOOL_NAME,
                    to: {name: 'courses.index', params: {school_slug: DEFAULT_SCHOOL_SLUG}}
                },
                {
                    text: this.$t('admin_menu_title'),
                    to  : {name: 'admin.schools.index'}
                },
            ]);
        },
        methods : {
            ...mapMutations(['setBreadcrumbs']),

            load() {
                this.loading = true;
                axios.get('/schools?page=' + this.page).then(response => {
                    this.items = response.data.data;
                    this.total = response.data.meta.total;
                    this.page = response.data.meta.current_page;
                    this.itemsPerPage = response.data.meta.per_page;
                }).finally(() => {
                    this.loading = false;
                });
            },

            editItem(item) {
                this.$router.push({
                    name  : 'admin.schools.edit',
                    params: {school_slug: item.slug}
                })
            },

            deleteItem(item) {
                this.confirmDialog(this.$t('delete'), this.$t('delete_confirm')).then(() => {
                    axios.delete('/schools/' + item.slug).then(() => {
                        let index = this.items.indexOf(item)
                        this.items.splice(index, 1)
                    })
                })
            },
        }
    }
</script>
