import i18n from '@/plugins/i18n'

export const STATUS_POST_HIDDEN = 0;
export const STATUS_POST_OPEN = 1;

export const STATUS_POST_SELECT = [
    {
        text : i18n.t('post.status.open'),
        value: STATUS_POST_OPEN
    },
    {
        text : i18n.t('post.status.hidden'),
        value: STATUS_POST_HIDDEN
    },
];
