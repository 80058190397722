<template>
    <div class="pt-spacer">
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field outlined disabled
                              ref="form.email"
                              v-model="item.email"
                              :error-messages="form_errors.email"
                              :label="$t('user.email_label')"
                              required
                >
                    <template slot="append">
                        <v-chip label small class="" color="gray">
                            {{(!!item.email_verified_at) ? $t('user.email_approved') :
                            $t('user.email_not_approved')}}
                        </v-chip>
                    </template>
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field outlined
                              :error-messages="form_errors.password"
                              :label="$t('user.password_label')"
                >
                    <template slot="append">
                        <v-btn plain small @click="changePasswordPopup=true">{{$t('change')}}</v-btn>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>
        <v-expansion-panels class="mb-10">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{$t('course.my_courses')}}
                    <span class="grey--text">[{{settings.courses.length}}]</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-for="(course, index) in settings.courses" :key="index">
                        <v-col cols="12" md="6">{{course.course_title}}</v-col>
                        <v-col cols="12" md="3">
                            <v-chip
                                label small
                                :color="($auth.check({[ROLE_ADMIN_COURSE]: course.course_id})) ? 'primary' : ''"
                                :outlined="!($auth.check({[ROLE_ADMIN_COURSE]: course.course_id}))"
                                :class="($auth.check({[ROLE_ADMIN_COURSE]: course.course_id})) ? 'white--text' : 'grey--text'">
                                {{$t('course.role.curator')}}
                            </v-chip>
                            <v-chip
                                label small
                                :color="($auth.check({[ROLE_STUDENT]: course.course_id})) ? 'primary' : ''"
                                :outlined="!($auth.check({[ROLE_STUDENT]: course.course_id}))"
                                :class="($auth.check({[ROLE_STUDENT]: course.course_id})) ? 'white--text' : 'grey--text'">
                                {{$t('course.pay_type.all')}}
                            </v-chip>
                            <v-chip
                                label small
                                :color="($auth.check({[ROLE_STUDENT_PRO]: course.course_id})) ? 'primary' : ''"
                                :outlined="!($auth.check({[ROLE_STUDENT_PRO]: course.course_id}))"
                                :class="($auth.check({[ROLE_STUDENT_PRO]: course.course_id})) ? 'white--text' : 'grey--text'">
                                {{$t('course.pay_type.pro')}}
                            </v-chip>
                            <v-chip
                                label small
                                :color="($auth.check({[ROLE_STUDENT_BASE]: course.course_id})) ? 'primary' : ''"
                                :outlined="!($auth.check({[ROLE_STUDENT_BASE]: course.course_id}))"
                                :class="($auth.check({[ROLE_STUDENT_BASE]: course.course_id})) ? 'white--text' : 'grey--text'">
                                {{$t('course.pay_type.simple')}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" md="3" class="grey--text">
                            <div v-if="$auth.check({[ROLE_STUDENT_SUSPENDED]: course.course_id})">
                                {{$t('course.access_suspended')}}
                            </div>
                            <div v-else-if="!course.access_paid">
                                {{$t('course.access_given_free')}}
                            </div>
                            <div v-else>
                                <div v-if="$auth.check({[ROLE_STUDENT]: course.course_id})">
                                    {{(!!course.amount) ? course.amount : '0'}}$ {{$t('course.for_course')}}
                                </div>
                                <div v-else>
                                    {{(!!course.amount) ? course.amount : '0'}}$ {{(!!course.due_date) ? '/' : ''}}
                                    <span>{{course.interval}}</span>
                                    <span v-if="course.interval_type === 'month'">{{$t('month')}}.</span>
                                    <span v-if="course.interval_type === 'year'">{{$t('year')}}</span>
                                    {{(!!course.due_date) ? $t('to') + course.due_date : ''}}
                                </div>
                            </div>
                        </v-col>
                        <v-col v-show="(index+1) < settings.courses.length" cols="12" class="py-0">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="mb-10">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{$t('user.tab_payment_history')}}
                    <span class="grey--text">[{{settings.payment_history.length}}]</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-for="(payment_item, index) in settings.payment_history" :key="index">
                        <v-col cols="12" md="6">{{(!!payment_item.course_title) ? payment_item.course_title: ''}}
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-chip
                                v-if="!!payment_item.type_payment && payment_item.type_payment === PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO"
                                label small
                                color="primary" class="white--text">{{$t('course.pay_type.pro')}}
                            </v-chip>
                            <v-chip
                                v-else-if="!!payment_item.type_payment && payment_item.type_payment === PAYMENT_TYPE_COURSE_ALL"
                                label small
                                color="primary" class="white--text">{{$t('course.pay_type.all')}}
                            </v-chip>
                            <v-chip
                                v-else-if="Number(payment_item.type_payment) === Number(PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE)"
                                label small
                                color="primary" class="white--text">{{$t('course.pay_type.simple')}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" md="3" class="grey--text">
                            {{payment_item.amount}}$ / {{payment_item.paid_at}}
                        </v-col>
                        <v-col cols="12" md="1">
                            <a :href="payment_item.receipt_url" target="_blank">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                        fill="black"/>
                                    <path
                                        d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                                        stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                                </svg>
                            </a>
                        </v-col>
                        <v-col v-show="(index+1) < settings.payment_history.length" cols="12" class="py-0">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="mb-10">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{$t('notifications.title')}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <!--v-col cols="12" md="3" class="py-0">
                            <v-switch
                                v-model="notice_all"
                                inset
                                :label="$t('notifications.send_settings.all')"
                                color="black"
                                class="pt-0"
                            ></v-switch>
                        </v-col-->
                        <v-col cols="12" md="3" class="py-0">
                            <v-switch @change="saveSettings('notice_new_comment')"
                                      v-model="settings.notice_new_comment"
                                      inset
                                      :label="$t('notifications.send_settings.comments')"
                                      color="black"
                                      class="pt-0"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" md="3" class="py-0">
                            <v-switch @change="saveSettings('notice_new_lecture')"
                                      v-model="settings.notice_new_lecture"
                                      inset
                                      :label="$t('notifications.send_settings.lectures')"
                                      color="black"
                                      class="pt-0"
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" md="3" class="py-0">
                            <v-switch @change="saveSettings('notice_new_response')"
                                      v-model="settings.notice_new_response"
                                      inset
                                      :label="$t('notifications.send_settings.responds')"
                                      color="black"
                                      class="pt-0"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="mb-10">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{$t('user.tab_active_sessions')}}
                    <span class="grey--text">[{{settings.session.length}}]</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-for="(session_item, index) in settings.session" :key="index">
                        <v-col cols="12" md="6">{{(!!session_item.ip_address) ? session_item.ip_address: ''}}
                        </v-col>
                        <v-col cols="12" md="6">{{(!!session_item.user_agent) ? session_item.user_agent: ''}}
                        </v-col>
                        <v-col cols="12" md="6">{{(!!session_item.last_used_at) ? session_item.last_used_at: session_item.created_at | dateTime }}
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog
            v-model="changePasswordPopup"
            persistent
            max-width="360"
        >
            <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="changePasswordPopup=false"
            >
                <v-icon
                    class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card :loading="loading">
                <template slot="progress">
                    <Preloader :loading="loading"/>
                </template>
                <v-card-title
                    v-html="$t('user.change_password')"
                    class="text-h2 pb-0"
                    style="word-break: normal"></v-card-title>
                <v-card-text class="pt-5">
                    <v-text-field outlined
                                  v-model="old_password"
                                  :error-messages="form_errors_password.password_current"
                                  :type="show_old_pwd ? 'text' : 'password'"
                                  :append-icon="show_old_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="show_old_pwd = !show_old_pwd"
                                  :label="$t('auth.reset_password.old_password_label')"
                                  required
                    >
                    </v-text-field>
                    <v-text-field outlined
                                  v-model="new_password"
                                  :error-messages="form_errors_password.password"
                                  :type="show_new_pwd ? 'text' : 'password'"
                                  :append-icon="show_new_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="show_new_pwd = !show_new_pwd"
                                  :label="$t('auth.reset_password.new_password_label')"
                                  required
                    >
                    </v-text-field>
                    <v-btn block depressed color="black" class="white--text mt-2" x-large @click="changePassword">
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn block color="gray" depressed class="mt-3" x-large @click="changePasswordPopup=false">
                        {{ $t('cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {mapMutations} from "vuex";
    import {
        PAYMENT_TYPE_COURSE_ALL,
        PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO,
        PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE
    } from "@/components/Course/course.constants";
    import {
        ROLE_ADMIN_COURSE,
        ROLE_STUDENT_BASE,
        ROLE_STUDENT_PRO,
        ROLE_STUDENT_SUSPENDED,
        ROLE_STUDENT
    } from "@/base.constants";

    export default {
        data() {
            return {
                item                                   : {
                    name             : '',
                    last_name        : '',
                    slug             : '',
                    gender           : 0,
                    email            : '',
                    is_admin_school  : 0,
                    email_verified_at: null,
                    country          : '',
                    city             : '',
                    work_role        : '',
                    work_company     : '',
                    biography        : '',
                    social_networks  : {
                        instagram: '',
                        facebook : '',
                        behance  : '',
                        dribbble : '',
                        pinterest: '',
                        twitter  : '',
                        youtube  : '',
                        linkedin : '',
                        website  : ''
                    },
                    payment_history  : [],
                    session : [],
                },
                avatar                                 : null,
                form_errors                            : {},
                form_errors_password                   : {},
                loading                                : false,
                show_other                             : false,
                panel                                  : 0,
                changePasswordPopup                    : false,
                show_old_pwd                           : false,
                show_new_pwd                           : false,
                old_password                           : '',
                new_password                           : '',
                PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE: PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE,
                PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO   : PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO,
                PAYMENT_TYPE_COURSE_ALL                : PAYMENT_TYPE_COURSE_ALL,
                ROLE_ADMIN_COURSE                      : ROLE_ADMIN_COURSE,
                ROLE_STUDENT_SUSPENDED                 : ROLE_STUDENT_SUSPENDED,
                ROLE_STUDENT_BASE                      : ROLE_STUDENT_BASE,
                ROLE_STUDENT_PRO                       : ROLE_STUDENT_PRO,
                ROLE_STUDENT                           : ROLE_STUDENT,
                password                               : '',
                settings                               : {
                    courses        : [],
                    payment_history: [],
                    session : []
                },
                notice_all                             : false
            }
        },
        watch  : {
            'notice_all': {
                handler: function (val) {
                    this.settings.notice_new_comment = (!!val) ? 1 : 0;
                    this.settings.notice_new_lecture = (!!val) ? 1 : 0;
                    this.settings.notice_new_response = (!!val) ? 1 : 0;
                    this.saveSettings('notice_new_comment');
                    this.saveSettings('notice_new_lecture');
                    this.saveSettings('notice_new_response');
                }
            }
        },
        created() {
            this.setBreadcrumbs([
                {
                    to  : {name: 'profile.index', params: {school_slug: this.$route.params.school_slug}},
                    text: this.$t('profile.my_profile')
                },
                {
                    text: this.$t('settings.title')
                },
            ]);
        },
        mounted() {
            this.load();
            this.loadSettings();
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    this.changePasswordPopup = false;
                }
            });
        },
        methods: {
            ...mapMutations(['setBreadcrumbs']),
            load() {
                this.loading = true;
                return axios.get('/schools/' + this.$route.params.school_slug + '/users/' + this.$auth.user().id).then(response => {
                    this.item = response.data.data;
                    if (!this.item.social_networks) {
                        this.item.social_networks = {
                            instagram: '',
                            facebook : '',
                            behance  : '',
                            dribbble : '',
                            pinterest: '',
                            twitter  : '',
                            youtube  : '',
                            linkedin : '',
                            website  : ''
                        };
                    }
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadSettings() {
                this.loading = true;
                axios.get('schools/'+this.$route.params.school_slug+'/profile/settings').then(response => {
                    this.settings = response.data.data;
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
            changePassword() {
                axios.post('user/change-password/' + this.item.id, {
                    password        : this.new_password,
                    password_current: this.old_password,
                }).then(response => {
                    this.changePasswordPopup = false
                    this.showMessage(this.$t('user.password_changed'));
                }).catch((error) => {
                    this.form_errors_password = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            saveSettings(name) {
                let app = this;
                let data = {};
                data[name] = (!!app.settings[name]) ? 1 : 0;
                setTimeout(function () {
                    axios.post('profile/notice/configure', data).then(response => {
                        app.showMessage(app.$t('successfully_saved'));
                    }).catch((error) => {
                    }).finally(() => {
                    });
                }, 500)
            }
        }
    }
</script>
