<template>
    <section id="faq_form_item">
        <div class="text-h2 mt-4 mb-2">{{ $t('faq.title') }}:</div>

        <draggable v-if="faqs.length > 0"
                   class="list-group"
                   :list="localValue">
            <div
                class="list-group-item"
                v-for="(element, index) in localValue"
                :key="index"
            >
                <v-select
                    prepend-icon="mdi-format-list-bulleted"
                    :items="faqs"
                    item-text="text"
                    item-value="value"
                    :error-messages="errorMessages"
                    v-model.number="localValue[index].faq_id"
                >
                    <v-icon @click="deleteFaq(index)" slot="append" color="red">mdi-close</v-icon>
                </v-select>
            </div>
        </draggable>

        <v-btn class="mt-3" outlined color="indigo" @click="addFaq">{{ $t('add') }}</v-btn>
    </section>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'FaqFormItem',
    components: {draggable},
    props: {
        value: {
            type: Array,
            default: () => []
        },
        errorMessages: {
            type: [String, Array],
            default: () => [],
        }
    },
    data(){
        return {
            faqs: [],
        }
    },
    mounted() {
        this.loadFaqs();
    },
    computed: {
        localValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    methods: {
        loadFaqs() {
            axios.get('/faqs/list').then(response => {
                for (let k in response.data.data) {
                    this.faqs.push({
                        value : parseInt(k),
                        text  : response.data.data[k]
                    });
                }
            })
        },

        addFaq() {
            this.localValue.push({
                faq_id   : 0,
                question : ''
            });
        },

        deleteFaq(index) {
            this.localValue.splice(index, 1);
        },
    }
}
</script>
