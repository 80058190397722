<template>
    <v-col cols="12" md="3" class="pt-0 pt-md-3 pb-5 pb-md-3">
        <v-card
            :to="{name  : 'infoposts.show',params: {school_slug: $route.params.school_slug, id: item.id}}"
            outlined>
            <v-card-text class="d-flex flex-row pa-3 pa-md-5">
                <div>
                    <v-avatar size="48" rounded color="white" class="mr-3"
                              :class="(!!item.image) ? '' : 'outlined'">
                        <img v-if="!!item.image"
                             :src="item.image"
                             alt=""
                        >
                        <v-icon color="primary" v-else>
                            mdi-image-outline
                        </v-icon>
                    </v-avatar>
                </div>
                <div>
                    <div class="black--text">
                        <div>{{item.course.title}}</div>
                    </div>
                    <div class="grey--text">
                        {{item.title}}
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-col>
</template>
<script>
    export default {
        name : 'SearchPostItem',
        props: {
            item: {
                type    : Object,
                required: true
            }
        },
        data() {
            return {}
        }
    };
</script>
