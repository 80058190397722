import {ROLE_ADMIN_COURSE, ROLE_ADMIN_SCHOOL} from '@/base.constants'

export class AuthResolver {
    static adminRedirect(to, from, next) {
        if (window.Vue && window.Vue.$auth.check()) {
            const user_roles = window.Vue.$auth.user().roles
            const school_slug = window.Vue.$auth.user().one_school_admin

            if (typeof school_slug === 'string') {
                if (!!user_roles[ROLE_ADMIN_SCHOOL] && to.name !== 'admin.schools.show') {
                    next({name: 'admin.schools.show', params: {school_slug}})
                } else if (!!user_roles[ROLE_ADMIN_COURSE]) {

                    next({name: 'admin.courses.index', params: {school_slug}})
                }
            }
        }
        next()
    }
    static toLogin(to, from, next) {
        if (!window.Vue) {
            next({name: 'Login'})
        } else {
            next()
        }
    }
}
