<template>
    <v-dialog
        v-model="$store.state.showFillInformationPopup"
        persistent
        max-width="360"
    >
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            @click="SHOW_FILL_INFORMATION(false)"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading" v-if="$auth.ready()">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2 pb-0" style="word-break: normal">
                {{ $t('profile.fill.title') }}
            </v-card-title>
            <v-card-text class="pt-5">
                <div class="d-flex flex-row mb-6" style="cursor: pointer">
                    <div>
                        <v-avatar size="48" rounded color="white" class="mr-3 outlined avatar-user">
                            <div class="d-flex" v-if="!!item.avatar">
                                <div class="avatar-hover-del align-center justify-center">
                                    <v-btn depressed x-small rounded fab color="primary" @click="deleteAvatar">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.3137 2L7.65685 7.65685M7.65685 7.65685L2 13.3137M7.65685 7.65685L2 2M7.65685 7.65685L13.3137 13.3137"
                                                stroke="white" stroke-width="1.3"/>
                                        </svg>
                                    </v-btn>
                                </div>
                                <img :src="item.avatar" alt=""/>
                            </div>
                            <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black" stroke-opacity="0.4" stroke-width="1.3"/>
                            </svg>
                        </v-avatar>
                    </div>
                    <div
                        @click="$refs.avatar.click()">
                        <div class="black--text">
                            <div>{{$t('profile.fill.your_photo')}}</div>
                        </div>
                        <div class="grey--text">
                            {{(!!item.avatar) ? $t('change') : $t('add')}}
                        </div>
                    </div>
                </div>
                <input v-show="false" @change="onFileChange" type="file"
                       accept="image/png, image/jpeg, image/bmp"
                       ref="avatar"/>
                <v-text-field outlined
                              v-model="item.name"
                              :error-messages="form_errors.name"
                              :type="'text'"
                              :label="$t('profile.fill.name')"
                              v-on:input="data_entered = true"
                              required
                ></v-text-field>
                <v-text-field outlined
                              v-model="item.last_name"
                              :error-messages="form_errors.last_name"
                              :type="'text'"
                              :label="$t('profile.fill.last_name')"
                              v-on:input="data_entered = true"
                              required
                >
                </v-text-field>
                <div class="gender-switch">
                    <label>{{ $t('profile.fill.gender') }}</label>
                    <v-switch
                        :error-messages="form_errors.gender"
                        v-model="item.gender"
                        :label="genderText"
                        @change="data_entered = true"
                        inset
                        color="black"
                    ></v-switch>
                </div>
                <v-btn v-if="!is_filled && !data_entered"
                       @click="SHOW_FILL_INFORMATION(false)"
                       block
                       x-large
                       depressed
                >{{ $t('profile.fill.skip') }}
                </v-btn>
                <v-btn v-else
                       block
                       color="black"
                       class="white--text"
                       x-large
                       type="submit"
                       @click="submit"
                       depressed
                >{{ $t('profile.fill.continue') }}
                </v-btn>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
    import {mapMutations} from "vuex";

    export default {
        name    : 'FillInformationBlock',
        data() {
            return {
                item        : {
                    name           : '',
                    last_name      : '',
                    gender         : 0,
                    avatar         : null,
                    is_delete_image: 0,
                },
                avatar      : null,
                avatar_url  : '',
                form_errors : [],
                data_entered: false,
                loading     : false
            }
        },
        computed: {
            genderText() {
                return this.item.gender == 0
                    ? this.$t('profile.fill.gender_male')
                    : this.$t('profile.fill.gender_female');
            },
            is_filled() {
                return (!!this.$auth.user().name && !!this.$auth.user().last_name && !!this.$auth.user().avatar);
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    this.SHOW_FILL_INFORMATION(false);
                }
            });
            this.item.name = this.$auth.user().name;
            this.item.last_name = this.$auth.user().last_name;
            this.item.gender = this.$auth.user().gender;
            this.item.avatar = this.$auth.user().avatar;
        },
        methods : {
            ...mapMutations(['SHOW_FILL_INFORMATION']),
            submit() {
                this.loading = true;
                let formData = new FormData();

                if (!!this.item.name) {
                    formData.append('name', this.item.name);
                }
                if (!!this.item.last_name) {
                    formData.append('last_name', this.item.last_name);
                }
                formData.append('gender', (this.item.gender)
                    ? 1
                    : 0);
                if (!!this.avatar) {
                    formData.append("avatar", this.avatar);
                }
                formData.append("is_delete_image", this.item.is_delete_image);
                let fill_complete = (!!this.item.name && !!this.item.last_name && (!!this.avatar || this.item.avatar)) ? 1 : 0;
                formData.append('fill_complete', fill_complete);

                axios.post('/profile', formData).then(response => {
                    this.form_errors = [];

                    this.showMessage(this.$t('successfully_saved'));
                    this.SHOW_FILL_INFORMATION(false)
                    this.$auth.fetch();
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                    this.form_errors = error.response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            onFileChange(e) {
                let file = e.target.files[0];
                this.item.avatar = URL.createObjectURL(file);
                this.avatar = file;
                this.data_entered = true
            },
            deleteAvatar() {
                this.avatar = '';
                this.item.avatar = '';
                this.item.is_delete_image = 1;
            }
        },
    }
</script>
