<template>
    <div class="pt-spacer">
        <v-row>
            <v-col cols="12" md="6">
                <div v-if="!!item.id" class="mb-16">
                    <v-btn class="mr-2"
                           rounded
                           outlined
                           :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: item.id}}"
                    >
                        <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                fill="black"/>
                            <path
                                d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                                stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                        </svg>
                        {{$t('profile.open_profile')}}
                    </v-btn>
                    <v-btn class="mr-2"
                           rounded
                           outlined
                           @click="toggleBlocked"
                    >
                        <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                                stroke="black" stroke-width="1.3"/>
                        </svg>
                        {{(!!item.is_blocked) ? $t('profile.unblock') : $t('profile.block')}}
                    </v-btn>
                </div>
                <v-expansion-panels class="mb-10" v-model="panel">
                    <v-expansion-panel
                        v-model="panel">
                        <v-expansion-panel-header>
                            {{$t('user.tab_main')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <div class="d-flex flex-row mb-6" style="cursor: pointer"
                                         @click="$refs.avatar.click()">
                                        <div>
                                            <v-avatar size="48" rounded color="white" class="mr-3"
                                                      :class="(!!item.avatar) ? '' : 'outlined'">
                                                <img v-if="!!item.avatar"
                                                     :src="item.avatar"
                                                     alt=""
                                                >
                                                <v-icon color="primary" v-else>
                                                    mdi-image-outline
                                                </v-icon>
                                            </v-avatar>
                                        </div>
                                        <div>
                                            <div class="black--text">
                                                <div>{{$t('user.profile_photo')}}</div>
                                            </div>
                                            <div class="grey--text">
                                                {{$t('change')}}
                                            </div>
                                        </div>
                                    </div>
                                    <input v-show="false" @change="onFileChange" type="file"
                                           accept="image/png, image/jpeg, image/bmp"
                                           ref="avatar"/>
                                    <v-text-field outlined

                                                  v-model="item.name"
                                                  :error-messages="form_errors.name"
                                                  :label="$t('user.name_label')"
                                    ></v-text-field>
                                    <v-text-field outlined
                                                  ref="form.last_name"
                                                  v-model="item.last_name"
                                                  :error-messages="form_errors.last_name"
                                                  :label="$t('user.last_name_label')"
                                    ></v-text-field>
                                    <v-text-field outlined disabled
                                                  ref="form.slug"
                                                  v-model="item.slug"
                                                  :error-messages="form_errors.slug"
                                                  :label="$t('user.slug_label')"
                                    ></v-text-field>
                                    <div v-if="!item.id">
                                        <v-text-field outlined
                                                      ref="form.email"
                                                      v-model="item.email"
                                                      :error-messages="form_errors.email"
                                                      :label="$t('user.email_label')"
                                                      required
                                        ></v-text-field>
                                        <v-text-field outlined
                                                      v-model="password"
                                                      :error-messages="form_errors.password"
                                                      :label="$t('user.password_label')"
                                        ></v-text-field>
                                    </div>
                                    <div class="gender-switch">
                                        <label>{{ $t('profile.fill.gender') }}</label>
                                        <div class="d-flex flex-row align-center">
                                            <div :class="(Number(item.gender) === 1) ? 'grey--text' : ''">
                                                {{$t('profile.fill.gender_female')}}
                                            </div>
                                            <v-switch class="ml-3 pt-3"
                                                      id="gender-switch"
                                                      :error-messages="form_errors.gender"
                                                      v-model="item.gender"
                                                      inset
                                                      color="black"
                                                      :value="item.gender"
                                            ></v-switch>
                                            <div :class="(Number(item.gender) === 0) ? 'grey--text' : ''">
                                                {{$t('profile.fill.gender_male')}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="gender-switch">
                                        <label>{{ $t('user.is_admin_school_label') }}</label>
                                        <div class="d-flex flex-row align-center">
                                            <div :class="(Number(adminSwitch) === 1) ? 'grey--text' : ''">
                                                {{$t('no')}}
                                            </div>
                                            <v-switch class="ml-3 pt-3"
                                                      id="admin-switch"
                                                      :error-messages="form_errors.adminSwitch"
                                                      v-model="adminSwitch"
                                                      inset
                                                      color="black"
                                                      :value="adminSwitch"
                                            ></v-switch>
                                            <div :class="(Number(adminSwitch) === 0) ? 'grey--text' : ''">
                                                {{$t('yes')}}
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div v-if="!!item.id">
                    <v-text-field outlined disabled
                                  ref="form.email"
                                  v-model="item.email"
                                  :error-messages="form_errors.email"
                                  :label="$t('user.email_label')"
                                  required
                    >
                        <template slot="append">
                            <v-chip label small class="" color="gray">
                                {{(!!item.email_verified_at) ? $t('user.email_approved') :
                                $t('user.email_not_approved')}}
                            </v-chip>
                        </template>
                    </v-text-field>
                    <v-text-field outlined
                                  :error-messages="form_errors.password"
                                  :label="$t('user.password_label')"
                    >
                        <template slot="append">
                            <v-btn plain small @click="changePasswordPopup=true">{{$t('change')}}</v-btn>
                        </template>
                    </v-text-field>
                </div>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_workplace')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined

                                                  v-model="item.work_role"
                                                  :error-messages="form_errors.work_role"
                                                  :label="$t('user.label_role')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined

                                                  v-model="item.work_company"
                                                  :error-messages="form_errors.work_company"
                                                  :label="$t('user.label_company')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_livingplace')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined
                                                  v-model="item.country"
                                                  :error-messages="form_errors.country"
                                                  :label="$t('user.label_country')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field outlined
                                                  v-model="item.city"
                                                  :error-messages="form_errors.city"
                                                  :label="$t('user.label_city')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_bio')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-textarea outlined rows="3"
                                        v-model="item.biography"
                                        :error-messages="form_errors.biography"
                                        :label="$t('user.label_bio')"
                            ></v-textarea>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="mb-10">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{$t('user.tab_socials')}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field outlined
                                          v-model="item.social_networks.website"
                                          :error-messages="form_errors['socialNetworks.website']"
                                          :label="$t('user.label_website')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.instagram"
                                          :error-messages="form_errors['socialNetworks.instagram']"
                                          :label="$t('user.label_instagram')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.facebook"
                                          :error-messages="form_errors['socialNetworks.facebook']"
                                          :label="$t('user.label_facebook')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.behance"
                                          :error-messages="form_errors['socialNetworks.behance']"
                                          :label="$t('user.label_behance')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.dribbble"
                                          :error-messages="form_errors['socialNetworks.dribbble']"
                                          :label="$t('user.label_dribbble')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.pinterest"
                                          :error-messages="form_errors['socialNetworks.pinterest']"
                                          :label="$t('user.label_pinterest')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.twitter"
                                          :error-messages="form_errors['socialNetworks.twitter']"
                                          :label="$t('user.label_twitter')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.youtube"
                                          :error-messages="form_errors['socialNetworks.youtube']"
                                          :label="$t('user.label_youtube')"
                            ></v-text-field>
                            <v-text-field outlined
                                          v-model="item.social_networks.linkedin"
                                          :error-messages="form_errors['socialNetworks.linkedin']"
                                          :label="$t('user.label_linkedin')"
                            ></v-text-field>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-expansion-panels class="mb-10">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{$t('user.tab_payment_history')}}
                    <span class="grey--text">[{{item.payment_history.length}}]</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-for="(payment_item, index) in item.payment_history" :key="index">
                        <v-col cols="6">{{(!!payment_item.course_title) ? payment_item.course_title: ''}}</v-col>
                        <v-col cols="2">
                            <v-chip
                                v-if="!!payment_item.type_payment && payment_item.type_payment === PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO"
                                label small
                                color="primary" class="white--text">{{$t('course.pay_type.pro')}}
                            </v-chip>
                            <v-chip
                                v-else-if="!!payment_item.type_payment && payment_item.type_payment === PAYMENT_TYPE_COURSE_ALL"
                                label small
                                color="primary" class="white--text">{{$t('course.pay_type.all')}}
                            </v-chip>
                            <v-chip
                                v-else-if="Number(payment_item.type_payment) === Number(PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE)"
                                label small
                                color="primary" class="white--text">{{$t('course.pay_type.simple')}}
                            </v-chip>
                        </v-col>
                        <v-col cols="3" class="grey--text">
                            {{payment_item.amount}}$ / {{payment_item.paid_at}}
                        </v-col>
                        <v-col cols="1">
                            <a :href="payment_item.receipt_url" target="_blank">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                        fill="black"/>
                                    <path
                                        d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                                        stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                                </svg>
                            </a>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels class="mb-10">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{$t('user.tab_active_sessions')}}
                    <span class="grey--text">[{{item.session.length}}]</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row v-for="(session_item, index) in item.session" :key="index">
                        <v-col cols="12" md="6">{{(!!session_item.ip_address) ? session_item.ip_address: ''}}
                        </v-col>
                        <v-col cols="12" md="6">{{(!!session_item.user_agent) ? session_item.user_agent: ''}}
                        </v-col>
                        <v-col cols="12" md="6">{{(!!session_item.last_used_at) ? session_item.last_used_at: session_item.created_at | dateTime }}
                        </v-col>
                        <v-divider class="my-4"></v-divider>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="!!item.id">
            <v-row class="px-3 black--text text-h6">
                <v-col cols="5">
                    {{$t('course.courses')}}
                </v-col>
                <v-col cols="2" class="text-center">
                    {{$t('course.role_by_course')}}
                </v-col>
                <v-col cols="4" class="text-center">
                    {{$t('user.status')}}
                </v-col>
                <v-col cols="1" class="text-center">
                    {{$t('user.access_th')}}
                </v-col>
            </v-row>
            <CourseUserItem @updated="loadCourses" v-for="(course, index) in courses_user"
                            :key="course.id" :item="course"
                            :user="item"/>
            <CourseUserItem @updated="loadCourses" v-show="show_other"
                            v-for="(course, index) in courses_other"
                            :key="course.id"
                            :item="course" :user="item"/>
            <v-btn
                plain
                @click="show_other = !show_other"
            >
                <v-icon
                    small
                    class="mr-2"
                >
                    mdi-subdirectory-arrow-right
                </v-icon>
                {{$t('course.open_course_platform')}}
            </v-btn>
        </div>
        <div class="d-flex justify-space-between mt-12 mb-6">
            <v-btn v-if="!item.email_verified_at && !!item.id"
                   x-large depressed
                   color="accent" class="black--text"
                   @click="invite"
            >
                {{$t('user.invite')}}
            </v-btn>
            <v-btn
                   x-large depressed
                   color="accent" class="black--text"
                   @click="submit"
            >
                <span v-if="!!item.id">
                {{$t('save')}}
                <span class="d-none d-md-inline">{{$t('changes')}}</span>
                </span>
                <span v-else>{{$t('user.create_user')}}</span>
            </v-btn>
            <v-btn depressed
                   x-large
                   color="gray" class="black--text"
                   :to="{name: 'users.index', params: {school_slug: $route.params.school_slug}}"
            >
                <span>
                    {{$t('cancel2')}}
                <span class="d-none d-md-inline">{{$t('changes')}}</span>
                </span>
            </v-btn>
        </div>
        <v-dialog
            v-model="changePasswordPopup"
            persistent
            max-width="360"
        >
            <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="changePasswordPopup=false"
            >
                <v-icon
                    class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card :loading="loading">
                <template slot="progress">
                    <Preloader :loading="loading"/>
                </template>
                <v-card-title
                    v-html="$t('user.change_password')"
                    class="text-h2 pb-0"
                    style="word-break: normal"></v-card-title>
                <v-card-text class="pt-5">
                    <v-text-field outlined
                                  v-model="old_password"
                                  :error-messages="form_errors_password.password_current"
                                  :type="show_old_pwd ? 'text' : 'password'"
                                  :append-icon="show_old_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="show_old_pwd = !show_old_pwd"
                                  :label="$t('auth.reset_password.old_password_label')"
                                  required
                    >
                    </v-text-field>
                    <v-text-field outlined
                                  v-model="new_password"
                                  :error-messages="form_errors_password.password"
                                  :type="show_new_pwd ? 'text' : 'password'"
                                  :append-icon="show_new_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="show_new_pwd = !show_new_pwd"
                                  :label="$t('auth.reset_password.new_password_label')"
                                  required
                    >
                    </v-text-field>
                    <v-btn block depressed color="black" class="white--text mt-2" x-large @click="changePassword">
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn block color="gray" depressed class="mt-3" x-large @click="changePasswordPopup=false">
                        {{ $t('cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {mapMutations} from 'vuex';
    import CourseUserItem from "@/components/User/parts/CourseUserItem";
    import {
        PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE,
        PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO,
        PAYMENT_TYPE_COURSE_ALL
    } from '@/components/Course/course.constants';

    export default {
        components: {
            CourseUserItem,
        },
        data() {
            return {
                item                                   : {
                    name             : '',
                    last_name        : '',
                    slug             : '',
                    gender           : 0,
                    email            : '',
                    is_admin_school  : 0,
                    email_verified_at: null,
                    country          : '',
                    city             : '',
                    work_role        : '',
                    work_company     : '',
                    biography        : '',
                    social_networks  : {
                        instagram: '',
                        facebook : '',
                        behance  : '',
                        dribbble : '',
                        pinterest: '',
                        twitter  : '',
                        youtube  : '',
                        linkedin : '',
                        website  : ''
                    },
                    payment_history  : [],
                    session : [],

                },
                avatar                                 : null,
                courses_user                           : [],
                courses_other                          : [],
                form_errors                            : {},
                form_errors_password                   : {},
                loading                                : false,
                show_other                             : false,
                panel                                  : 0,
                changePasswordPopup                    : false,
                show_old_pwd                           : false,
                show_new_pwd                           : false,
                old_password                           : '',
                new_password                           : '',
                PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE: PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE,
                PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO   : PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO,
                PAYMENT_TYPE_COURSE_ALL                : PAYMENT_TYPE_COURSE_ALL,
                password                               : '',
                adminSwitch                            : false
            }
        },
        computed  : {
            breadcrumbs() {
                return [
                    {
                        text    : this.$t('user.users'),
                        disabled: false,
                        exact   : true,
                        to      : {
                            name: 'users.index'
                        }
                    },
                    {
                        text    : this.$t(!!this.item.id
                            ? 'user.edit'
                            : 'user.add'),
                        disabled: true,
                        href    : '#',
                    },
                ]
            },
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            load() {
                this.loading = true;
                return axios.get('/schools/' + this.$route.params.school_slug + '/users/' + this.$route.params.id).then(response => {
                    this.item = response.data.data;
                    if (!this.item.social_networks) {
                        this.item.social_networks = {
                            instagram: '',
                            facebook : '',
                            behance  : '',
                            dribbble : '',
                            pinterest: '',
                            twitter  : '',
                            youtube  : '',
                            linkedin : '',
                            website  : ''
                        };
                    }

                    let isAdmin = 0;
                    if (!!this.item.roles['admin-school']) {
                        isAdmin = this.item.roles['admin-school'].includes(this.$route.params.school_slug);
                    }
                    this.adminSwitch = !!isAdmin ? 1 : 0;
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadCourses() {
                this.loading = true;
                axios.get('/schools/' + this.$route.params.school_slug + '/users/' + this.item.id + '/courses-with-user-role').then(response => {
                    this.courses_user = [];
                    this.courses_other = [];
                    for (var k in response.data.data.courses) {
                        if (!!response.data.data.courses[k].role) {
                            this.courses_user.push(response.data.data.courses[k]);
                        } else {
                            this.courses_other.push(response.data.data.courses[k]);
                        }
                    }
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
            submit() {
                let url = '/schools/' + this.$route.params.school_slug + '/users';
                let formData = new FormData();

                formData.append('name', this.item.name);
                formData.append('last_name', this.item.last_name);
                formData.append('gender', (!!this.item.gender) ? 1 : 0);
                if (!!this.item.id) {
                    formData.append('password', this.password);
                }
                formData.append('email', this.item.email);
                formData.append('is_admin_school', (this.adminSwitch) ? 1 : 0);
                formData.append('country', (!!this.item.country) ? this.item.country : '');
                formData.append('city', (!!this.item.city) ? this.item.city : '');
                formData.append('work_role', (!!this.item.work_role) ? this.item.work_role : '');
                formData.append('work_company', (!!this.item.work_company) ? this.item.work_company : '');
                formData.append('biography', (!!this.item.biography) ? this.item.biography : '');
                formData.append('socialNetworks[instagram]', (!!this.item.social_networks.instagram) ? this.item.social_networks.instagram : '');
                formData.append('socialNetworks[facebook]', (!!this.item.social_networks.facebook) ? this.item.social_networks.facebook : '');
                formData.append('socialNetworks[behance]', (!!this.item.social_networks.behance) ? this.item.social_networks.behance : '');
                formData.append('socialNetworks[dribbble]', (!!this.item.social_networks.dribbble) ? this.item.social_networks.dribbble : '');
                formData.append('socialNetworks[pinterest]', (!!this.item.social_networks.pinterest) ? this.item.social_networks.pinterest : '');
                formData.append('socialNetworks[twitter]', (!!this.item.social_networks.twitter) ? this.item.social_networks.twitter : '');
                formData.append('socialNetworks[youtube]', (!!this.item.social_networks.youtube) ? this.item.social_networks.youtube : '');
                formData.append('socialNetworks[linkedin]', (!!this.item.social_networks.linkedin) ? this.item.social_networks.linkedin : '');
                formData.append('socialNetworks[website]', (!!this.item.social_networks.website) ? this.item.social_networks.website : '');

                if (typeof this.avatar?.name === 'string') {
                    formData.append('avatar', this.avatar);
                }

                if (this.item.id) {
                    formData.append('_method', 'PUT');
                    url = '/schools/' + this.$route.params.school_slug + '/users/' + this.$route.params.id;
                }

                this.loading = true;
                axios({
                    method: 'post',
                    url   : url,
                    data  : formData
                }).then(response => {
                    this.form_errors = [];

                    this.showMessage(this.$t('successfully_saved'));

                    if (!this.item.id) {
                        this.item = response.data.data;
                        this.item.is_admin_school = !!this.item.roles['admin-school'];
                        this.$router.push({
                            name  : 'users.edit',
                            params: {
                                id: response.data.data.id
                            }
                        });
                        this.loadCourses();

                        this.setBreadcrumbs([
                            {
                                text: this.$t('admin_menu_title'),
                                to  : {name: 'admin.schools.index'}
                            },
                            {
                                text: this.$t('user.users'),
                                to  : {name: 'users.index', params: {school_slug: this.$route.params.school_slug}}
                            },
                            {
                                text: this.item.name + ' ' + this.item.last_name
                            },
                        ]);
                    }
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            changePassword() {
                axios.post('user/change-password/' + this.item.id, {
                    password        : this.new_password,
                    password_current: this.old_password,
                }).then(response => {
                    this.changePasswordPopup = false
                    this.showMessage(this.$t('user.password_changed'));
                }).catch((error) => {
                    this.form_errors_password = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            invite() {
                this.loading = true;
                axios.post('invite-user/' + this.item.id).then(response => {
                    this.showMessage(this.$t('user.invite_sent'));
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            onFileChange(e) {
                let file = e.target.files[0];
                this.item.avatar = URL.createObjectURL(file);
                this.avatar = file;
            },
            toggleBlocked() {
                this.loading = true;
                axios.get('schools/' + this.$route.params.school_slug + '/users/' + this.item.id + '/toggle-blocked').then(response => {
                    this.item.is_blocked = !this.item.is_blocked;
                }).catch((error) => {
                }).finally(() => {
                    this.loading = false;
                });
            },
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    this.changePasswordPopup = false;
                }
            });
            if (!!this.$route.params.id) {
                this.load().then(() => {
                    this.loadCourses();

                    this.setBreadcrumbs([
                        {
                            text: this.$t('admin_menu_title'),
                            to  : {name: 'admin.schools.index'}
                        },
                        {
                            text: this.$t('user.users'),
                            to  : {name: 'users.index', params: {school_slug: this.$route.params.school_slug}}
                        },
                        {
                            text: this.item.name + ' ' + this.item.last_name
                        },
                    ]);
                });
            } else {
                this.setBreadcrumbs([
                    {
                        text: this.$t('admin_menu_title'),
                        to  : {name: 'admin.schools.index'}
                    },
                    {
                        text: this.$t('user.users'),
                        to  : {name: 'users.index', params: {school_slug: this.$route.params.school_slug}}
                    },
                    {
                        text: this.$t('user.add')
                    },
                ]);
            }
        },
    }
</script>
