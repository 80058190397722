<template>
    <v-card :to="to" class="d-flex flex-wrap flex-row pa-0 shadowless" :class="(color === 'transparent' ? 'pa-0' : 'pa-md-5 pa-3')" :color="color">
        <div class="d-flex flex-wrap flex-row" v-if="Number(type) === TYPE_NEW_LECTURE">
            <div v-if="image !== null">
                <v-avatar v-if="!!image && this.$route.name !== 'notifications.index'" size="48" rounded color="white" class="mr-3"
                          :class="(!!image) ? '' : 'outlined'" style="border: none">
                    <img :src="image" alt="">
                    <!--<v-icon color="primary" v-else>
                        mdi-image-outline
                    </v-icon>-->
                </v-avatar>
                <div v-else-if="!!hasVideo && this.$route.name !== 'notifications.index'" class="mr-3">
                    <img src="/img/video-image-sm.svg"/>
                </div>
                <div v-else-if="this.$route.name !== 'notifications.index'" class="mr-3">
                    <img src="/img/no-cover.svg"/>
                </div>
            </div>
            <div>
                <div v-if="title !== null" class="black--text">
                    <div v-if="this.$route.name !== 'notifications.index'">{{title}}</div>
                </div>
                <div class="grey--text">
                    {{description}}
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap flex-row" v-else-if="Number(type) === TYPE_NEW_POST">
            <div v-if="image !== null">
                <div v-if="this.$route.name !== 'notifications.index'" class="mr-3">
                    <img :src="image" alt="" />
                </div>
            </div>
            <div>
                <div v-if="title !== null" class="black--text">
                    <div v-if="this.$route.name !== 'notifications.index'">{{title}}</div>
                </div>
                <div class="grey--text">
                    {{description}}
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap flex-row" v-else>
            <div v-if="image !== null">
                <v-avatar v-if="!!image" size="48" rounded color="white" class="mr-3"
                          :class="(!!image) ? '' : 'outlined'" style="border: none">
                    <img :src="image" alt="">
                </v-avatar>
                <div v-else class="mr-3">
                    <img src="/img/no-cover.svg"/>
                </div>
            </div>
            <div>
                <div v-if="title !== null" class="black--text">
                    <div>{{title}}</div>
                </div>
                <div class="grey--text">
                    {{description}}
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
    import {
        TYPE_NEW_LECTURE,
        TYPE_NEW_POST,
    } from "@/components/Notifications/notification.constants";
    export default {
        name : 'NotificationDescriptionCard',
        props: {
            title      : {
                type    : String,
                required: false
            },
            description: {
                type    : String,
                required: true
            },
            image      : {
                type    : String,
                required: false,
                default : false
            },
            to  : {
                type    : Object,
                required: false
            },
            color: {
                type   : String,
                default: 'transparent'
            },
            type: {
                type: Number,
                required: true
            },
            hasVideo: {
                type: Boolean,
                required: false,
                default : false
            },
        },
        data() {
            return {
                TYPE_NEW_LECTURE : TYPE_NEW_LECTURE,
                TYPE_NEW_POST : TYPE_NEW_POST,
            }
        },
    }
</script>
