<template>
    <v-row>
        <v-col cols="3" class="text-truncate">
            <v-avatar
                class="profile"
                color="gray"
                width="50"
                height="28"
                tile
            >
                <v-img :src="item.image"></v-img>
            </v-avatar>
            {{ item.title }}
        </v-col>
        <v-col cols="9">
            <v-row style="line-height: 28px;">
                <v-col cols="4">
                    <div>
                        {{ $tc('course.post', item.posts.length) }},
                        {{ $tc('course.lecture', item.lectures.length) }}
                    </div>
                </v-col>
                <v-col cols="3">
                    <CourseStatusLabel :status="item.status" is-admin/>
                </v-col>
                <v-col cols="5">
                    <div class="course-action-buttons">
                        <v-menu
                            left
                            bottom
                            offset-y
                            content-class="shadowless"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    outlined
                                    fab
                                    @click.native.stop=""
                                >
                                    <svg width="16" height="15" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="black" stroke-width="1.3"/>
                                    </svg>
                                </v-btn>
                            </template>
                            <v-list outlined>
                                <v-list-item :to="{name: 'lectures.add', params : {school_slug: $route.params.school_slug}, query: {course_id: item.id}}">
                                    <v-list-item-title>{{ $t('lecture.lecture') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item :to="{name: 'infoposts.add', params : {school_slug: $route.params.school_slug}, query: {course_id: item.id}}">
                                    <v-list-item-title>{{ $t('post.post') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn
                            small
                            outlined
                            fab
                            @click.native.stop=""
                            :to="{name: 'courses.show', params : {school_slug : $route.params.school_slug, id: item.id}}"
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="black"/>
                                <path d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z" stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                            </svg>
                        </v-btn>
                        <v-btn small
                               rounded
                               fab
                               outlined
                               @click.native.stop=""
                               :to="{name: 'courses.edit', params : {school_slug : $route.params.school_slug, id: item.id}}">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                    stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                            </svg>
                        </v-btn>
                        <v-btn v-show="!!item.can_delete"
                            small
                            outlined
                            fab
                            @click="$emit('delete', item)">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114" stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </v-btn>
                        <v-btn
                            small
                            outlined
                            fab
                            @click.native.stop="handleCopy(item)">
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                            small
                            outlined
                            fab
                            @click.native.stop="">
                            <v-icon>mdi-drag</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import CourseStatusLabel from '@/components/Course/parts/CourseStatusLabel'

export default {
    name: 'AdminCourseItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    components: {CourseStatusLabel},
    data() {
        return {}
    },
    methods: {
        handleCopy(item) {
            axios.post('courses/'+item.id+'/copy').then(() => {
                this.$emit('copiedCourse')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.course-action-buttons {
    float: right;
}
</style>
