<template>
    <v-app class="black--text">
        <template v-if="$auth.ready()">
            <router-view></router-view>
        </template>
        <template v-else>
            <v-progress-linear
                :active="true"
                :indeterminate="true"
                fixed
                bottom
                color="deep-purple accent-4"
            ></v-progress-linear>
        </template>
        <confirm ref="confirm"></confirm>
        <Snackbar/>
    </v-app>
</template>

<script>
    import Confirm from './layout/Confirm';
    import Snackbar from "./layout/Snackbar.vue";

    export default {
        name       : 'App',
        components : {
            Confirm,
            Snackbar
        },
        mounted() {
            this.$root.$confirm = this.$refs.confirm.open
        },
    }
</script>
