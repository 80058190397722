<template>
    <v-col cols="12" md="4" sm="12" class="px-md-5 pb-md-6 pt-md-4">
        <div class="position-relative">
            <v-card :outlined="preview.type !== 'image'" class="shadowless"
                    :to="lecture.is_open || lecture.can_open ? { name: 'lectures.show', params: { school_slug: $route.params.school_slug, id: lecture.id }} : null"
                    @mouseover="hoverCard = 1" @mouseout="hoverCard = 0"
                    @click="showPopup" :id="'lecture-'+lecture.id">
                <v-responsive :aspect-ratio="1">
                    <div v-if="isOwner" v-show="hoverCard" class="position-absolute right-10 top-10">
                        <v-btn class="d-none d-md-inline-flex shadowless black--text handle cursor-move handle-btn mr-1"
                               fab rounded>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.49966 3.71417C5.97302 3.71417 6.35675 3.33044 6.35675 2.85709C6.35675 2.38373 5.97302 2 5.49966 2C5.02631 2 4.64258 2.38373 4.64258 2.85709C4.64258 3.33044 5.02631 3.71417 5.49966 3.71417Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M5.49966 8.857C5.97302 8.857 6.35675 8.47326 6.35675 7.99991C6.35675 7.52655 5.97302 7.14282 5.49966 7.14282C5.02631 7.14282 4.64258 7.52655 4.64258 7.99991C4.64258 8.47326 5.02631 8.857 5.49966 8.857Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M5.49966 14.0001C5.97302 14.0001 6.35675 13.6163 6.35675 13.143C6.35675 12.6696 5.97302 12.2859 5.49966 12.2859C5.02631 12.2859 4.64258 12.6696 4.64258 13.143C4.64258 13.6163 5.02631 14.0001 5.49966 14.0001Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M10.4997 3.71417C10.973 3.71417 11.3568 3.33044 11.3568 2.85709C11.3568 2.38373 10.973 2 10.4997 2C10.0263 2 9.64258 2.38373 9.64258 2.85709C9.64258 3.33044 10.0263 3.71417 10.4997 3.71417Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M10.4997 8.857C10.973 8.857 11.3568 8.47326 11.3568 7.99991C11.3568 7.52655 10.973 7.14282 10.4997 7.14282C10.0263 7.14282 9.64258 7.52655 9.64258 7.99991C9.64258 8.47326 10.0263 8.857 10.4997 8.857Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M10.4997 14.0001C10.973 14.0001 11.3568 13.6163 11.3568 13.143C11.3568 12.6696 10.973 12.2859 10.4997 12.2859C10.0263 12.2859 9.64258 12.6696 9.64258 13.143C9.64258 13.6163 10.0263 14.0001 10.4997 14.0001Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </v-btn>
                        <v-btn @mouseover="hover = 1" @mouseout="hover = 0"
                               class="d-none d-md-inline-flex shadowless black--text" rounded fab>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.99893 3.71417C8.47229 3.71417 8.85602 3.33044 8.85602 2.85709C8.85602 2.38373 8.47229 2 7.99893 2C7.52558 2 7.14185 2.38373 7.14185 2.85709C7.14185 3.33044 7.52558 3.71417 7.99893 3.71417Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M7.99893 8.85675C8.47229 8.85675 8.85602 8.47302 8.85602 7.99966C8.85602 7.52631 8.47229 7.14258 7.99893 7.14258C7.52558 7.14258 7.14185 7.52631 7.14185 7.99966C7.14185 8.47302 7.52558 8.85675 7.99893 8.85675Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M7.99893 13.9993C8.47229 13.9993 8.85602 13.6156 8.85602 13.1422C8.85602 12.6689 8.47229 12.2852 7.99893 12.2852C7.52558 12.2852 7.14185 12.6689 7.14185 13.1422C7.14185 13.6156 7.52558 13.9993 7.99893 13.9993Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </v-btn>
                    </div>
                    <v-card-text v-if="preview.type === 'video'" class="d-flex justify-center align-center fill-height">
                        <div class="video-image d-flex align-center justify-center"><img class="img-mw"
                                                                                         src="/img/video-image.svg"/>
                        </div>
                    </v-card-text>
                    <v-img v-else-if="preview.type === 'image'"
                           height="100%"
                           :src="lecture.image"
                    ></v-img>
                    <v-card-text v-else class="d-flex justify-center align-center fill-height">
                        <div class="text-image-empty"></div>
                    </v-card-text>
                </v-responsive>
            </v-card>
            <div v-show="hover" v-if="isOwner" @mouseover="hover = 1" @mouseout="hover = 0"
                 class="position-absolute right-0 top-0 w-100 text-right grey lighten-3 z-index-100">
                <v-responsive :aspect-ratio="1" content-class="d-flex align-center">
                    <div>
                        <v-btn class="d-none d-md-block position-absolute right-10 top-10 shadowless black--text"
                               rounded fab>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.99893 3.71417C8.47229 3.71417 8.85602 3.33044 8.85602 2.85709C8.85602 2.38373 8.47229 2 7.99893 2C7.52558 2 7.14185 2.38373 7.14185 2.85709C7.14185 3.33044 7.52558 3.71417 7.99893 3.71417Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M7.99893 8.85675C8.47229 8.85675 8.85602 8.47302 8.85602 7.99966C8.85602 7.52631 8.47229 7.14258 7.99893 7.14258C7.52558 7.14258 7.14185 7.52631 7.14185 7.99966C7.14185 8.47302 7.52558 8.85675 7.99893 8.85675Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M7.99893 13.9993C8.47229 13.9993 8.85602 13.6156 8.85602 13.1422C8.85602 12.6689 8.47229 12.2852 7.99893 12.2852C7.52558 12.2852 7.14185 12.6689 7.14185 13.1422C7.14185 13.6156 7.52558 13.9993 7.99893 13.9993Z"
                                    fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                        </v-btn>
                        <v-btn
                            class="text-h2 my-4 justify-start"
                            block
                            text
                            :to="{name: 'lectures.edit', params : {school_slug : $route.params.school_slug, id: lecture.id}}">
                            {{$t('edit')}}
                        </v-btn>
                        <v-btn
                            @click.native.stop="handleCopy"
                            class="text-h2 my-4 justify-start"
                            block
                            text>
                            {{$t('copy')}}
                        </v-btn>
                        <v-btn v-if="lecture.status === STATUS_LECTURE_OPEN"
                               class="text-h2 my-4 justify-start"
                               block
                               @click="hide"
                               text>
                            {{$t('hide')}}
                        </v-btn>
                        <v-btn v-else
                               class="text-h2 my-4 justify-start"
                               block
                               @click="hide"
                               text>
                            {{$t('open')}}
                        </v-btn>
                        <v-btn
                            class="text-h2 my-4 justify-start"
                            block
                            text
                            v-if="lecture.can_delete"
                            @click="deleteItem">
                            {{$t('delete')}}
                        </v-btn>
                    </div>
                </v-responsive>
            </div>
            <div class="mt-2">
                <div class="d-flex flex-row align-center py-1">
                    <v-chip v-if="!lecture.can_open"
                            @click.native.stop="unlock" small label class="mr-2 chip-square">
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 8H2.42857H1V13.6V15H9.57143L11 14.9993V9.4V8Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                            <path d="M3 8V3.94737C3 3.16568 3.31607 2.416 3.87868 1.86326C4.44129 1.31053 5.20435 1 6 1C6.79565 1 7.55871 1.31053 8.12132 1.86326C8.68393 2.416 9 3.16568 9 3.94737V8" stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                        </svg>
                    </v-chip>
                    <span>{{ lecture.title }}</span>
                    <v-btn v-if="isOwner" @click.stop="hover = !hover"
                           tag="div"
                           class="d-md-none ml-auto shadowless black--text responsive-small-fab-btn" rounded fab>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.99893 3.71417C8.47229 3.71417 8.85602 3.33044 8.85602 2.85709C8.85602 2.38373 8.47229 2 7.99893 2C7.52558 2 7.14185 2.38373 7.14185 2.85709C7.14185 3.33044 7.52558 3.71417 7.99893 3.71417Z"
                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path
                                d="M7.99893 8.85675C8.47229 8.85675 8.85602 8.47302 8.85602 7.99966C8.85602 7.52631 8.47229 7.14258 7.99893 7.14258C7.52558 7.14258 7.14185 7.52631 7.14185 7.99966C7.14185 8.47302 7.52558 8.85675 7.99893 8.85675Z"
                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path
                                d="M7.99893 13.9993C8.47229 13.9993 8.85602 13.6156 8.85602 13.1422C8.85602 12.6689 8.47229 12.2852 7.99893 12.2852C7.52558 12.2852 7.14185 12.6689 7.14185 13.1422C7.14185 13.6156 7.52558 13.9993 7.99893 13.9993Z"
                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                    </v-btn>
                    <v-chip small label v-if="lecture.status === STATUS_LECTURE_HIDDEN" class="min-width-fit-content align-self-start black--text ml-auto">{{
                        $t('lecture.hidden_label') }}
                    </v-chip>
                    <v-chip v-if="!!lecture.notifiable" label class="min-width-fit-content align-self-start black--text ml-auto" color="accent">
                        {{$t('notifications.is_new')}}
                    </v-chip>
                </div>
            </div>
        </div>
        <v-dialog
            v-model="popupLectureUnavailable"
            persistent
            max-width="360"
        >
            <v-card>
                <v-card-text class="pa-8">
                    <div class="mb-4 black--text">
                        <div class="text-h2 mb-4" style="word-break: normal;"
                             v-html="$t('lecture.unavailable_title')"></div>
                        <div>{{$t('lecture.unavailable_text')}}</div>
                    </div>
                    <v-btn @click="popupLectureUnavailable = false"
                           block
                           color="accent"
                           class="black--text"
                           x-large
                           depressed
                    >{{ $t('back') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="popupLectureChecking"
            persistent
            max-width="360"
        >
            <v-card>
                <v-card-text class="pa-8">
                    <div class="mb-4 black--text">
                        <div class="text-h2 mb-4" style="word-break: normal;"
                             v-html="$t('lecture.pending_title')"></div>
                        <div>{{$t('lecture.pending_text')}}</div>
                    </div>
                    <v-btn @click="popupLectureChecking = false"
                           block
                           color="accent"
                           class="black--text"
                           x-large
                           depressed
                    >{{ $t('back') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
    import {STATUS_LECTURE_HIDDEN, STATUS_LECTURE_OPEN} from "@/components/Lecture/lecture.constants";

    export default {
        name    : 'LectureItem',
        props   : {
            lecture: {
                type    : Object,
                required: true,
            }
        },
        data() {
            return {
                hover                  : false,
                hoverCard              : false,
                STATUS_LECTURE_HIDDEN  : STATUS_LECTURE_HIDDEN,
                STATUS_LECTURE_OPEN    : STATUS_LECTURE_OPEN,
                popupLectureUnavailable: false,
                popupLectureChecking   : false
            }
        },
        computed: {
            isOwner() {
                return this.hasCourseAdminAccess(this.$route.params.school_slug, this.lecture.course_id);
            },
            preview() {
                let pr = (!!this.lecture.image) ? {type: 'image'} : {type: 'text'};
                if (pr.type != 'image') {
                    for (let k in this.lecture.gallery) {
                        if (this.lecture.gallery[k].type === 'video') {
                            pr = {type: 'video'};
                            break;
                        }
                    }
                }

                return pr;
            },
        },
        mounted() {
            if (!!this.$route.query.lecture_id && Number(this.$route.query.lecture_id) === Number(this.lecture.id)) {
                let app = this;
                setTimeout(function () {
                    app.showPopup();
                }, 500)
            }
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    this.popupLectureChecking = false;
                    this.popupLectureUnavailable = false;
                }
            });
        },
        methods : {
            deleteItem() {
                this.confirmDialog(this.$t('delete'), this.$t('delete_confirm')).then(() => {
                    axios.delete('/lectures/' + this.lecture.id).then(() => {
                        this.$emit('lectureDeleted', this.lecture)
                    })
                })
            },
            hide() {
                this.lecture.status = (this.lecture.status === STATUS_LECTURE_HIDDEN) ? STATUS_LECTURE_OPEN : STATUS_LECTURE_HIDDEN;
                axios.post('lectures/' + this.lecture.id + '/options', {status: this.lecture.status}).then(() => {
                });
            },
            handleCopy() {
                axios.post('lectures/' + this.lecture.id + '/copy').then(() => {
                    this.$emit('copiedLecture')
                });
            },
            showPopup() {
                if (!this.lecture.is_open && !this.lecture.can_open && !!this.lecture.open_pending) {
                    this.popupLectureChecking = true;
                } else if (!this.lecture.is_open && !this.lecture.can_open) {
                    this.popupLectureUnavailable = true;
                }
            }
        }
    }
</script>
