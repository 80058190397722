<template>
    <v-dialog
        v-model="$store.state.showForgotEnterPopup"
        persistent
        max-width="360"
    >
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            :to="{name: 'courses.index', params: {school_slug: this.$route.params.school_slug}}"
            @click="SHOW_FORGOT_ENTER({
                                email : '',
                                token : '',
                                dialog: false
                            })"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading" v-if="!sent">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title
                v-html="($route.name === 'invite') ? $t('auth.invite.title') : $t('auth.reset_password.new_password_title')"
                class="text-h2 pb-0"
                style="word-break: normal"></v-card-title>
            <v-card-text class="pt-5">
                <v-text-field outlined
                              v-model="form.password"
                              :rules="[() => !!form.password || $t('form.rules.required')]"
                              :error-messages="form_errors.password"
                              :type="show_pwd ? 'text' : 'password'"
                              :append-icon="show_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="show_pwd = !show_pwd"
                              :label="($route.name === 'invite') ? $t('auth.password_label') : $t('auth.reset_password.new_password_label')"
                              required
                >
                </v-text-field>
                <v-btn block color="black" depressed class="white--text mt-2" x-large @click="submit">
                    {{ $t('save') }}
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-title class="text-h2" style="word-break: normal">
                {{ $t('auth.reset_password.title_success') }}
            </v-card-title>
            <v-card-text class="pt-5">
                <v-btn block color="black" depressed class="white--text justify-space-between" x-large
                       @click="SHOW_FORGOT_ENTER({
                                email : '',
                                token : '',
                                dialog: false
                            })"
                       :to="{name: 'login'}">
                    {{ $t('auth.login.submit_button') }}
                    <v-icon
                        right
                        dark
                    >
                        mdi-arrow-right
                    </v-icon>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name   : 'ForgotEnterBlock',
        data() {
            return {
                form       : {
                    email                : '',
                    password             : '',
                    password_confirmation: '',
                    token                : ''
                },
                form_errors: [],
                loading    : false,
                sent       : false,
                show_pwd   : false,
                school_slug   : this.$route.params.school_slug
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (this.$route.name === 'reset_by_school' && e.key === 'Escape') {
                    this.SHOW_FORGOT_ENTER(false);
                    this.$router.push({name: 'courses.index', params: {school_slug: this.$route.params.school_slug}}).then((resp) => {
                    }).catch(() => {});
                }
            });
        },
        methods: {
            ...mapMutations(['SHOW_FORGOT_ENTER', 'SHOW_FILL_INFORMATION']),
            submit() {
                this.loading = true;
                this.form.password_confirmation = this.form.password;
                this.form.token = this.$route.params.token;
                this.form.email = this.$route.query.email;
                axios.post('/schools/'+this.$route.params.school_slug+'/reset-password/reset', this.form).then(response => {
                    this.form_errors = [];
                    this.sent = true;
                    this.login();
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            login() {
                this.loading = true;
                this.$auth.login({
                    data      : {
                        email   : this.form.email,
                        password: this.form.password
                    },
                    url: !!this.school_slug
                        ? 'schools/'+this.school_slug+'/login'
                        : 'login',
                    rememberMe: 1,
                }).then(response => {
                    this.form_errors = [];
                    if (!this.$auth.user().fill_complete) {
                        this.SHOW_FILL_INFORMATION(true);
                    }
                    this.$router.push({name: 'courses.index', params: {school_slug: this.school_slug}}).then((resp) => {
                    }).catch(() => {});
                }).catch(error => {
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
