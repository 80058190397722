<template>
    <v-breadcrumbs class="method-breadcrumbs" style="padding-top: 6px" :class="(windowTop > 30) ? 'breadcrumbs-scrolled' : ''"
                   :items="breadcrumbs"
                   large
    ></v-breadcrumbs>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name    : 'Breadcrumbs',
        data() {
            return {
                windowTop: 0
            };
        },
        computed: {
            ...mapGetters(['breadcrumbs'])
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll)
        },
        methods : {
            onScroll(e) {
                this.windowTop = window.top.scrollY
            }
        }
    };
</script>
