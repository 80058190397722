<template>
    <div class="pt-spacer" data-v-sticky-container>
        <div class="" v-sticky="$vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs'">
            <div class="d-flex flex-row mb-6 records-toolbar" data-v-sticky-inner>
                <v-btn outlined rounded
                       class="shadowless black--text">{{$t('record.records')}}:
                </v-btn>
                <v-btn color="black" rounded
                       class="ml-2 shadowless white--text" fab>
                    {{visibleIndex}}
                </v-btn>
                <div class="ml-4 mr-2 pt-3">{{$t('record.from')}}</div>
                <v-btn color="white" outlined rounded
                       class="ml-2 shadowless grey--text" fab>
                    {{items.length}}
                </v-btn>
                <v-btn color="accent" rounded @click="$vuetify.goTo('#user-record-new', {offset: 90})"
                       v-if="isOwner"
                       class="d-none d-md-flex ml-2 shadowless black--text">
                    {{$t('record.add_record')}}
                </v-btn>
                <v-btn color="accent" rounded @click="$vuetify.goTo('#user-record-new', {offset: 90})"
                       v-if="isOwner"
                       class="d-md-none ml-2 shadowless black--text" fab>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="black" stroke-width="1.3"/>
                    </svg>
                </v-btn>
            </div>
        </div>
        <RecordBlockComments v-observe-visibility="visibilityChanged"
                             @edit="editItem"
                             @edited="edited"
                             v-for="(item, index) in items" :key="index" :index="index" :item="item"
                             :lecture="lecture"
                             :users="users"/>
        <!--div class="text-center my-4" v-observe-visibility="load" v-if="!loaded">
            {{$t('record.loading')}}
        </div-->
        <RecordEditForm v-if="isOwner" @edited="edited" :item="item" :index="-1" :lecture="lecture"/>
    </div>
</template>
<script>
    import RecordBlockComments from "@/components/Record/parts/RecordBlockComments";
    import draggable from "vuedraggable";
    import {mapMutations} from "vuex";
    import RecordEditForm from "@/components/Record/parts/RecordEditForm";
    import VueStickyDirective from '@renatodeleao/vue-sticky-directive'

    export default {
        components: {
            RecordBlockComments,
            RecordEditForm,
            draggable,
        },
        directives: {
            "sticky": VueStickyDirective
        },
        data() {
            return {
                items       : [],
                item        : {
                    id                : null,
                    content           : '',
                    is_comment_request: 0,
                    files             : []
                },
                form_errors : [],
                lecture     : {},
                users       : [],
                loading     : false,
                visibleIndex: 1,
                page        : 1,
                loaded      : false
            }
        },
        computed  : {
            isOwner() {
                return Number(this.$route.params.user_id) === Number(this.$auth.user().id);
            },
        },
        mounted() {
            this.loadLecture().then(() => {
                if (!!this.lecture.id) {
                    this.load();
                    this.applyBreadcrumbs();
                    this.loadUsers();
                }
            });
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            load() {
                this.loading = true;

                let url = 'lectures/' + this.$route.params.lecture_id + '/user-records/' + this.$route.params.user_id;
                axios.get(url).then(response => {
                    this.items.push(...response.data.data);
                }).finally(() => {
                    this.loading = false;
                    if (!!this.$route.params.id) {
                        let app = this;
                        if (this.isIdPresent(app.$route.params.id)) {
                            setTimeout(function () {
                                app.$vuetify.goTo('#user-record-' + app.$route.params.id, {offset: 90})
                            }, 500);
                        }
                    }
                });
            },
            loadLecture() {
                this.loading = true;
                return axios.get('/lectures/' + this.$route.params.lecture_id).then(response => {
                    this.lecture = response.data.data;
                }).catch((error) => {
                    this.showMessage(error.response.data.message, 'error');

                    if (!!this.$route.query.course_id) {
                        this.$router.push({
                            name  : 'courses.show',
                            params: {id: this.$route.query.course_id},
                            query : {lecture_id: this.$route.params.lecture_id}
                        });
                    } else {
                        this.$router.push({name: 'index.school'});
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadUsers() {
                axios.get('courses/' + this.lecture.course.id + '/users').then(response => {
                    for (let k in response.data.data) {
                        this.users.push(
                            {
                                id    : response.data.data[k].id,
                                value : response.data.data[k].slug,
                                label : response.data.data[k].name,
                                avatar: response.data.data[k].avatar
                            }
                        );
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            editItem(item) {
                this.item.is_comment_request = false;
                this.item = item;
                this.$vuetify.goTo('#user-record-new', {offset: 90})
            },
            visibilityChanged(isVisible, entry) {
                if (isVisible) {
                    this.visibleIndex = Number(entry.target.getAttribute("data-index")) + 1;
                }
            },
            edited(item) {
                item.is_comment_request = false;
                let index = null;
                for (var k in this.items) {
                    if (this.items[k].id === item.id) {
                        index = k;
                        break;
                    }
                }
                if (index) {
                    this.items.splice(index, 1, item);
                } else {
                    this.items.push(item);
                }
                if (item.id !== this.$route.params.id) {
                    this.$router.push({
                        name  : 'records.show',
                        params: {lecture_id: item.lecture.id, id: item.id}
                    });
                    this.applyBreadcrumbs();
                }
                let app = this;

                this.item = {
                    id                : null,
                    content           : '',
                    is_comment_request: 0,
                    files             : []
                };
            },
            applyBreadcrumbs() {
                this.setBreadcrumbs([
                    {
                        text: this.lecture.course.title,
                        to  : {
                            name  : 'courses.show',
                            params: {school_slug: this.$route.params.school_slug, id: this.lecture.course.id}
                        },
                    },
                    {
                        text: this.lecture.title,
                        to  : {
                            name  : 'lectures.show',
                            params: {school_slug: this.$route.params.school_slug, id: this.lecture.id}
                        },
                    },
                    {
                        text: (this.isOwner) ? this.$t('record.my_records') : this.$t('record.record_list'),
                    },
                ]);
            },
            isIdPresent(id) {
                for (let k_1 in this.items) {
                    if (Number(this.items[k_1].id) === Number(id)) {
                        return true;
                    } else {
                        for (let k_2 in this.items[k_1].children) {
                            if (Number(this.items[k_1].children[k_2].id) === Number(id)) {
                                return true;
                            } else {
                                for (let k_3 in this.items[k_1].children[k_2].children) {
                                    if (Number(this.items[k_1].children[k_2].children[k_3].id) === Number(id)) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
                return false;
            }
        }
    }
</script>
