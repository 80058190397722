import Vue from 'vue'
import VueRouter from 'vue-router';
import {routes} from '@/router/routes';
import store from '@/store/store'

Vue.use(VueRouter);

const router = new VueRouter({
    mode : 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (!['admin.schools.show', 'admin.schools.index', 'admin.courses.index'].includes(to.name)) {
        store.commit('setBreadcrumbs', []);
    }

    next();
});

Vue.router = router;

export default router;
