<template>
    <div :class="pageClass">
        <UserBlock class="d-flex flex-row ml-auto"/>
        <Header/>
        <v-main class="main">
            <v-container fluid class="pa-md-15 px-c-10 pt-0">
                <router-view :key="$route.params.school_slug"></router-view>
            </v-container>
        </v-main>
        <Footer/>
    </div>
</template>

<script>
    import Header from '@/layout/Header'
    import Footer from '@/layout/Footer'
    import UserBlock from "@/layout/UserBlock";

    export default {
        components: {Footer, Header,
            UserBlock,},
        computed  : {
            pageClass() {
                let val = '';
                switch (this.$route.name) {
                    case 'courses.index' :
                        val = 'page-home';
                        break;
                }

                return val;
            }
        },
        mounted() {
            //console.log(this.$route.name)
        }
    }
</script>
