<template>
    <div>
        <v-container class="pt-spacer">
            <div v-html="item.terms"></div>
        </v-container>
    </div>
</template>

<script>

    import {mapMutations} from "vuex";
    import {DEFAULT_SCHOOL_NAME, DEFAULT_SCHOOL_SLUG} from '@/base.constants'

    export default {
        name: "Terms",
        components: {},
        data(){
            return {
                item: {
                    terms: '',
                },
                loading     : false
            }
        },
        mounted() {
            this.load().then(() => {
                this.setBreadcrumbs([
                    {
                        text: this.$t('terms.title')
                    },
                ]);
            })
        },
        methods: {
            ...mapMutations(['setBreadcrumbs']),
            async load() {
                if (!!this.$route.params.school_slug) {
                    this.loading = true;
                    await axios.get('/schools/' + this.$route.params.school_slug).then(response => {
                        this.item = response.data.data;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
        }
    }
</script>
