<template>
    <div
        class="pt-spacer">
        <div class="search-text-field-container px-md-15 px-c-10">
            <v-text-field :placeholder="$t('search_page.placeholder')" v-model="searchText" @input="doSearch"
            ></v-text-field>
        </div>
        <div
            v-show="!loading && searchText.length >=3 && totalComments === 0 && totalUsers === 0 && totalPosts === 0 && totalLectures === 0">
            {{$t('search_page.nothing_found')}}
        </div>
        <div v-show="totalLectures > 0" class="mb-80">
            <div class="mb-5">{{totalLectures}} {{$t('search_page.lectures_cnt')}}:</div>
            <v-row>
                <SearchlectureItem v-for="item in lectures" :item="item" :key="'lecture_'+item.id"/>
            </v-row>
            <div v-show="loadedLectures === false" class="mt-5">
                <a href="#" @click.prevent="loadLectures">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{$t('search_page.show_more')}}
                </a>
            </div>
        </div>
        <div v-show="totalPosts > 0" class="mb-80">
            <div class="mb-5">{{totalPosts}} {{$t('search_page.posts_cnt')}}:</div>
            <v-row>
                <SearchPostItem v-for="item in posts" :item="item" :key="'post_'+item.id"/>
            </v-row>
            <div v-show="loadedPosts === false" class="mt-5">
                <a href="#" @click.prevent="loadPosts">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{$t('search_page.show_more')}}
                </a>
            </div>
        </div>
        <div v-show="totalUsers > 0" class="mb-80">
            <div class="mb-5">{{totalUsers}} {{$t('search_page.users_cnt')}}:</div>
            <v-row>
                <SearchUserItem v-for="item in users" :item="item" :key="'user_'+item.id"/>
            </v-row>
            <div v-show="loadedUsers === false" class="mt-5">
                <a href="#" @click.prevent="loadUsers">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{$t('search_page.show_more')}}
                </a>
            </div>
        </div>
        <div v-show="totalComments > 0" class="mb-80">
            <div class="mb-5">{{totalComments}} {{$t('search_page.comments_cnt')}}:</div>
            <v-card outlined class="mb-5">
                <v-card-text class="pa-3 pa-md-5">
                    <v-row>
                        <v-col cols="12" md="3">
                            <div class="d-flex flex-row align-center">
                                <div>
                                    <v-avatar size="48" rounded color="white" class="mr-3 outlined">
                                        <svg width="48" height="48" viewBox="0 0 280 281" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M140 1V281M140 1H0M140 1H280" stroke="black"/>
                                        </svg>
                                    </v-avatar>
                                </div>
                                <div>
                                    <div class="black--text">
                                        <div>{{$t('search_page.in_which_lecture')}}</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="3" class="pt-0 pt-md-3">
                            <div class="d-flex flex-row align-center">
                                <div>
                                    <v-avatar size="48" rounded color="white" class="mr-3 outlined">
                                        <svg width="48" height="48" viewBox="0 0 30 30" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black"
                                                  stroke-width="1.3"/>
                                        </svg>
                                    </v-avatar>
                                </div>
                                <div>
                                    <div class="black--text">
                                        <div>{{$t('search_page.who_comment')}}</div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex align-center pt-0 pt-md-3">
                            <div style="height: 48px;"></div>
                            <div>{{$t('search_page.content')}}</div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <SearchCommentitem v-for="item in comments" :item="item" :key="'comment-'+item.id"/>
            <div v-show="loadedComments === false" class="mt-5">
                <a href="#" @click.prevent="loadComments">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{$t('search_page.show_more')}}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapMutations} from "vuex";
    import SearchCommentitem from "@/components/Search/parts/SearchCommentitem";
    import SearchlectureItem from "@/components/Search/parts/SearchLectureItem";
    import SearchPostItem from "@/components/Search/parts/SearchPosttem";
    import SearchUserItem from "@/components/Search/parts/SearchUsertem";

    export default {
        components: {
            SearchUserItem,
            SearchPostItem,
            SearchlectureItem,
            SearchCommentitem
        },
        data() {
            return {
                searchText     : '',
                users          : [],
                comments       : [],
                lectures       : [],
                posts          : [],
                loadingUsers   : false,
                loadingComments: false,
                loadingLectures: false,
                loadingPosts   : false,
                loadedUsers    : false,
                loadedComments : false,
                loadedLectures : false,
                loadedPosts    : false,
                pageUsers      : 1,
                pageComments   : 1,
                pageLectures   : 1,
                pagePosts      : 1,
                totalUsers     : 0,
                totalComments  : 0,
                totalLectures  : 0,
                totalPosts     : 0,
                delayTimer     : false,
                loading        : false
            }
        },
        mounted() {
            this.setBreadcrumbs([{
                text    : '',
                disabled: true,
            }]);
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            loadUsers() {
                this.loadingUsers = true;
                axios.get('/schools/' + this.$route.params.school_slug + '/search/user?search=' + this.searchText + '&page=' + this.pageUsers).then(response => {
                    this.users.push(...response.data.data.data);
                    this.totalUsers = response.data.data.total;
                    if (Number(response.data.data.last_page) > this.pageUsers) {
                        this.pageUsers++;
                    } else {
                        this.loadedUsers = true;
                    }
                }).finally(() => {
                    this.loadingUsers = false;
                    let app = this;
                });
            },
            loadComments() {
                this.loadingComments = true;
                axios.get('/schools/' + this.$route.params.school_slug + '/search/comment?search=' + this.searchText + '&page=' + this.pageComments).then(response => {
                    this.comments.push(...response.data.data.data);
                    this.totalComments = response.data.data.total;
                    if (Number(response.data.data.last_page) > this.pageComments) {
                        this.pageComments++;
                    } else {
                        this.loadedComments = true;
                    }
                }).finally(() => {
                    this.loadingComments = false
                    let app = this;
                });
            },
            loadLectures() {
                this.loadingLectures = true;
                axios.get('/schools/' + this.$route.params.school_slug + '/search/lecture?search=' + this.searchText + '&page=' + this.pageLectures).then(response => {
                    this.lectures.push(...response.data.data.data);
                    this.totalLectures = response.data.data.total;
                    if (Number(response.data.data.last_page) > this.pageLectures) {
                        this.pageLectures++;
                    } else {
                        this.loadedLectures = true;
                    }
                }).finally(() => {
                    this.loadingLectures = false;
                    let app = this;
                });
            },
            loadPosts() {
                this.loadingPosts = true;
                axios.get('/schools/' + this.$route.params.school_slug + '/search/post?search=' + this.searchText + '&page=' + this.pagePosts).then(response => {
                    this.posts.push(...response.data.data.data);
                    this.totalPosts = response.data.data.total;
                    if (Number(response.data.data.last_page) > this.pagePosts) {
                        this.pagePosts++;
                    } else {
                        this.loadedPosts = true;
                    }
                }).finally(() => {
                    this.loadingPosts = false;
                    let app = this;
                });
            },
            doSearch(val) {
                if (val.length >= 3) {
                    let app = this;
                    app.loading = true;
                    clearTimeout(app.delayTimer);
                    app.delayTimer = setTimeout(function () {
                        app.clearSearch();
                        app.loadUsers();
                        app.loadComments();
                        app.loadLectures();
                        app.loadPosts();

                        setTimeout(function () {
                            app.loading = false;
                        }, 1000)
                    }, 1000);
                } else {
                    this.clearSearch();
                    this.loading = false;
                }
            },
            clearSearch() {
                this.users = [];
                this.pageUsers = 1;
                this.loadedUsers = false;
                this.totalUsers = 0;

                this.comments = [];
                this.pageComments = 1;
                this.loadedComments = false;
                this.totalComments = 0;

                this.lectures = []
                this.pageLectures = 1;
                this.loadedLectures = false;
                this.totalLectures = 0;

                this.posts = [];
                this.pagePosts = 1;
                this.loadedPosts = false;
                this.totalPosts = 0;
            }
        },
    }
</script>
