<template>
    <div class="position-relative" @mouseover="hoverCard = 1" @mouseout="hoverCard = 0">
        <v-responsive :aspect-ratio="groupLength">
            <div class="fill-height position-relative" :class="(groupLength > 1 && !isLast) ? 'pb-2' : ''">
                <div v-if="isOwner" v-show="hoverCard" class="position-absolute right-10 top-10">
                    <v-btn class="d-none d-md-inline-flex shadowless black--text handle cursor-move handle-btn mr-1" rounded fab>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.49966 3.71417C5.97302 3.71417 6.35675 3.33044 6.35675 2.85709C6.35675 2.38373 5.97302 2 5.49966 2C5.02631 2 4.64258 2.38373 4.64258 2.85709C4.64258 3.33044 5.02631 3.71417 5.49966 3.71417Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.49966 8.857C5.97302 8.857 6.35675 8.47326 6.35675 7.99991C6.35675 7.52655 5.97302 7.14282 5.49966 7.14282C5.02631 7.14282 4.64258 7.52655 4.64258 7.99991C4.64258 8.47326 5.02631 8.857 5.49966 8.857Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.49966 14.0001C5.97302 14.0001 6.35675 13.6163 6.35675 13.143C6.35675 12.6696 5.97302 12.2859 5.49966 12.2859C5.02631 12.2859 4.64258 12.6696 4.64258 13.143C4.64258 13.6163 5.02631 14.0001 5.49966 14.0001Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.4997 3.71417C10.973 3.71417 11.3568 3.33044 11.3568 2.85709C11.3568 2.38373 10.973 2 10.4997 2C10.0263 2 9.64258 2.38373 9.64258 2.85709C9.64258 3.33044 10.0263 3.71417 10.4997 3.71417Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.4997 8.857C10.973 8.857 11.3568 8.47326 11.3568 7.99991C11.3568 7.52655 10.973 7.14282 10.4997 7.14282C10.0263 7.14282 9.64258 7.52655 9.64258 7.99991C9.64258 8.47326 10.0263 8.857 10.4997 8.857Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.4997 14.0001C10.973 14.0001 11.3568 13.6163 11.3568 13.143C11.3568 12.6696 10.973 12.2859 10.4997 12.2859C10.0263 12.2859 9.64258 12.6696 9.64258 13.143C9.64258 13.6163 10.0263 14.0001 10.4997 14.0001Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </v-btn>
                    <v-btn @mouseover="hover = 1" @mouseout="hover = 0"
                           class="d-none d-md-inline-flex shadowless black--text" rounded fab>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99893 3.71417C8.47229 3.71417 8.85602 3.33044 8.85602 2.85709C8.85602 2.38373 8.47229 2 7.99893 2C7.52558 2 7.14185 2.38373 7.14185 2.85709C7.14185 3.33044 7.52558 3.71417 7.99893 3.71417Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99893 8.85675C8.47229 8.85675 8.85602 8.47302 8.85602 7.99966C8.85602 7.52631 8.47229 7.14258 7.99893 7.14258C7.52558 7.14258 7.14185 7.52631 7.14185 7.99966C7.14185 8.47302 7.52558 8.85675 7.99893 8.85675Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99893 13.9993C8.47229 13.9993 8.85602 13.6156 8.85602 13.1422C8.85602 12.6689 8.47229 12.2852 7.99893 12.2852C7.52558 12.2852 7.14185 12.6689 7.14185 13.1422C7.14185 13.6156 7.52558 13.9993 7.99893 13.9993Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </v-btn>
                </div>
                <v-btn v-if="groupLength > 1"
                       block
                       outlined
                       height="100%"
                       class="px-5 hover-bordered"
                       tag="div"
                       @click="goToPost"
                >
                    <span class="d-block w-100 text-left">{{post.title}}</span>
                    <v-btn v-if="isOwner" @click.stop="hover = !hover"
                           tag="div"
                           class="d-md-none shadowless black--text responsive-small-fab-btn" rounded fab>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99893 3.71417C8.47229 3.71417 8.85602 3.33044 8.85602 2.85709C8.85602 2.38373 8.47229 2 7.99893 2C7.52558 2 7.14185 2.38373 7.14185 2.85709C7.14185 3.33044 7.52558 3.71417 7.99893 3.71417Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99893 8.85675C8.47229 8.85675 8.85602 8.47302 8.85602 7.99966C8.85602 7.52631 8.47229 7.14258 7.99893 7.14258C7.52558 7.14258 7.14185 7.52631 7.14185 7.99966C7.14185 8.47302 7.52558 8.85675 7.99893 8.85675Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99893 13.9993C8.47229 13.9993 8.85602 13.6156 8.85602 13.1422C8.85602 12.6689 8.47229 12.2852 7.99893 12.2852C7.52558 12.2852 7.14185 12.6689 7.14185 13.1422C7.14185 13.6156 7.52558 13.9993 7.99893 13.9993Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </v-btn>
                </v-btn>
                <div v-else>
                    <v-card outlined
                        :to="{ name: 'infoposts.show', params: { school_slug: $route.params.school_slug, id: post.id }}">
                        <v-responsive :aspect-ratio="1">
                            <v-card-text class="d-flex justify-center align-center fill-height">
                                <div class="text-image d-flex align-center justify-center"><img class="img-mw" src="/img/text-image.svg" /></div>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                    <div class="mt-2">
                        <span class="d-block py-1">{{ post.title }}</span>
                    </div>
                </div>
            </div>
        </v-responsive>
        <div v-show="hover" v-if="isOwner" @mouseover="hover = 1" @mouseout="hover = 0"
             class="position-absolute right-0 top-0 w-100 text-right grey lighten-3 z-index-100">
            <v-responsive :aspect-ratio="1" content-class="d-flex align-center">
                <v-responsive class="position-absolute right-0 top-0 w-100 text-right" @click="hover = false" :aspect-ratio="4">
                    <v-btn block
                           height="100%"
                           class="shadowless hoverless bg-transparent"
                    >
                        <span class="d-block w-100 text-left">{{post.title}}</span>
                    </v-btn>
                </v-responsive>
                <div class="z-index-100">
                    <v-btn class=" d-none d-md-block position-absolute right-10 top-10 shadowless black--text" rounded fab>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99893 3.71417C8.47229 3.71417 8.85602 3.33044 8.85602 2.85709C8.85602 2.38373 8.47229 2 7.99893 2C7.52558 2 7.14185 2.38373 7.14185 2.85709C7.14185 3.33044 7.52558 3.71417 7.99893 3.71417Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99893 8.85675C8.47229 8.85675 8.85602 8.47302 8.85602 7.99966C8.85602 7.52631 8.47229 7.14258 7.99893 7.14258C7.52558 7.14258 7.14185 7.52631 7.14185 7.99966C7.14185 8.47302 7.52558 8.85675 7.99893 8.85675Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99893 13.9993C8.47229 13.9993 8.85602 13.6156 8.85602 13.1422C8.85602 12.6689 8.47229 12.2852 7.99893 12.2852C7.52558 12.2852 7.14185 12.6689 7.14185 13.1422C7.14185 13.6156 7.52558 13.9993 7.99893 13.9993Z" fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </v-btn>
                    <v-btn
                        class="text-h2 my-4 justify-start"
                        block
                        text
                        :to="{name: 'infoposts.edit', params : {course_id : $route.params.id, id: post.id}}">
                        {{$t('edit')}}
                    </v-btn>
                    <v-btn
                        @click.native.stop="handleCopy"
                        class="text-h2 my-4 justify-start"
                        block
                        text>
                        {{$t('copy')}}
                    </v-btn>
                    <v-btn v-if="post.status === STATUS_POST_OPEN"
                           class="text-h2 my-4 justify-start"
                           block
                           @click="hide"
                           text>
                        {{$t('hide')}}
                    </v-btn>
                    <v-btn v-else
                           class="text-h2 my-4 justify-start"
                           block
                           @click="hide"
                           text>
                        {{$t('open')}}
                    </v-btn>
                    <v-btn
                        class="text-h2 my-4 justify-start"
                        block
                        text
                        @click="deletePost">
                        {{$t('delete')}}
                    </v-btn>
                </div>
            </v-responsive>
        </div>
    </div>
</template>

<script>
    import {STATUS_POST_HIDDEN, STATUS_POST_OPEN} from "@/components/Infopost/post.constants";

    export default {
        name    : 'PostItem',
        props   : {
            post       : {
                type    : Object,
                required: true,
            },
            groupLength: {
                type    : Number,
                required: true,
            },
            isLast: {
                type    : Boolean,
                required: true,
            }
        },
        data() {
            return {
                hover             : false,
                hoverCard         : false,
                STATUS_POST_HIDDEN: STATUS_POST_HIDDEN,
                STATUS_POST_OPEN  : STATUS_POST_OPEN
            }
        },
        computed: {
            isOwner() {
                return this.hasCourseAdminAccess(this.$route.params.school_slug, this.post.course_id);
            }
        },
        methods : {
            deletePost() {
                this.confirmDialog(this.$t('delete'), this.$t('delete_confirm')).then(() => {
                    axios.delete('/posts/' + this.post.id).then(() => {
                        this.$emit('postDeleted', this.post)
                    })
                })
            },
            handleCopy() {
                axios.post('posts/' + this.post.id + '/copy').then(() => {
                    this.$emit('copiedPost')
                });
            },
            hide() {
                this.post.status = (this.post.status === STATUS_POST_HIDDEN) ? STATUS_POST_OPEN : STATUS_POST_HIDDEN;
                axios.post('posts/' + this.post.id + '/options', {status: this.post.status}).then(() => {
                });
            },
            goToPost() {
                console.log('goToPost');
                this.$router.push({ name: 'infoposts.show', params: { school_slug: this.$route.params.school_slug, id: this.post.id }})
            }
        }
    }
</script>
