<template>
    <div class="user-menu-block" v-if="$auth.check()">
        <FillInformationBlock/>
        <PlusButton/>
        <v-btn class="mr-2 mr-md-3 "
               rounded
               fab
               outlined
               :to="{name: 'search.index', params: {school_slug: $route.params.school_slug}}"
        >
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_629:11729)">
                    <path
                        d="M15.3281 15L11.2043 10.8763M11.2043 10.8763C12.2519 9.82938 12.8998 8.3828 12.8998 6.7849C12.8998 3.58999 10.3094 1 7.11398 1C3.91854 1 1.32812 3.58999 1.32812 6.7849C1.32812 9.9798 3.91854 12.5698 7.11398 12.5698C8.71125 12.5698 10.1574 11.9227 11.2043 10.8763Z"
                        stroke="black" stroke-width="1.3" stroke-miterlimit="3.8637"/>
                </g>
                <defs>
                    <clipPath id="clip0_629:11729">
                        <path
                            d="M0.328125 4C0.328125 1.79086 2.11899 0 4.32812 0H12.3281C14.5373 0 16.3281 1.79086 16.3281 4V12C16.3281 14.2091 14.5373 16 12.3281 16H4.32812C2.11899 16 0.328125 14.2091 0.328125 12V4Z"
                            fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </v-btn>
        <v-btn :to="{name: 'notifications.index', params: {school_slug: $route.params.school_slug}}"
               class="mr-2 mr-md-3 shadowless black--text" fab :color="($store.state.countUnreadNotifications !== 0) ? 'accent' : ''" depressed
               rounded :outlined="$store.state.countUnreadNotifications === 0">
            {{$store.state.countUnreadNotifications}}
        </v-btn>
        <v-menu
            left
            bottom
            offset-y
            content-class="shadowless mt-1 menu-user"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-avatar size="48" class="d-flex align-center menu-avatar"
                          :class="(!!$auth.user().avatar) ? '' : 'outlined'"
                          rounded
                          v-bind="attrs"
                          v-on="on">
                    <img v-if="!!$auth.user().avatar"
                         :src="$auth.user().avatar"
                         :alt="$auth.user().name"
                    >
                    <div v-else>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black" stroke-opacity="0.4"
                                  stroke-width="1.3"/>
                        </svg>
                    </div>
                </v-avatar>
            </template>

            <v-list outlined>
                <v-list-item :to="{ name: 'profile.index', params: {school_slug: $route.params.school_slug} }">
                    <v-list-item-title>{{ $t('profile.title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$auth.check('admin-platform')"
                             :to="{ name: 'admin.schools.index' }">
                    <v-list-item-title>{{ $t('admin_menu_title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else-if="getAdminOneSchool()"
                             :to="{ name: 'admin.schools.show', params: {school_slug: getAdminOneSchool()} }">
                    <v-list-item-title>{{ $t('admin_menu_title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else-if="getOnlyCurator()"
                             :to="{name: 'admin.schools.index'}">
                    <v-list-item-title>{{ $t('admin_menu_title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name: 'profile.settings'}">
                    <v-list-item-title>{{ $t('settings.title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item tag="a" @click="$auth.logout({redirect:{name: 'login_by_school', params: {school_slug: school_slug}}})">
                    <v-list-item-title>{{ $t('auth.logout.title') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
    <div class="user-menu-block" v-else>
        <v-btn class="mr-2" depressed :to="{name: 'registration_by_school', params: {school_slug: this.$route.params.school_slug}}" color="#BDFF00">{{$t('auth.register.submit_button')}}
        </v-btn>
        <v-btn depressed color="black" class="white--text" :to="{name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}}">{{ $t('auth.login.login_button') }}
        </v-btn>
        <RegistrationBlock/>
        <LoginBlock/>
        <SocialLoginBlock/>
        <ForgotSendBlock/>
        <ForgotSentBlock/>
        <ForgotEnterBlock/>
        <EmailConfirmationBlock/>
    </div>
</template>
<script>
    import RegistrationBlock from "./Auth/RegistrationBlock.vue";
    import LoginBlock from "./Auth/LoginBlock.vue";
    import ForgotSendBlock from "./Auth/ForgotSendBlock.vue";
    import ForgotSentBlock from "./Auth/ForgotSentBlock.vue";
    import ForgotEnterBlock from "./Auth/ForgotEnterBlock.vue";
    import FillInformationBlock from "./Auth/FillInformationBlock.vue";
    import SocialLoginBlock from "./Auth/SocialLoginBlock.vue";
    import EmailConfirmationBlock from "@/layout/Auth/EmailConfirmationBlock";
    import PlusButton from "./PlusButton.vue";
    import {DEFAULT_SCHOOL_SLUG} from '@/base.constants'
    import {mapActions} from "vuex";

    export default {
        name      : 'UserBlock',
        components: {
            RegistrationBlock,
            LoginBlock,
            ForgotSendBlock,
            ForgotSentBlock,
            ForgotEnterBlock,
            FillInformationBlock,
            SocialLoginBlock,
            EmailConfirmationBlock,
            PlusButton,
        },
        data() {
            return {
                DEFAULT_SCHOOL_SLUG     : DEFAULT_SCHOOL_SLUG,
                school_slug: '',
            }
        },
        watch  : {
            $route() {
                this.school_slug = this.$route.params.school_slug;
            }
        },
        mounted() {
            let app = this;
            let school_slug = !!this.$route.params.school_slug
                ? this.$route.params.school_slug
                : DEFAULT_SCHOOL_SLUG;
            this.school_slug = school_slug;
            app.fetchCountUnreadNotifications(school_slug)
            setInterval(function () {
                app.fetchCountUnreadNotifications(school_slug)
            }, 60000);
        },
        methods   : {
            ...mapActions(['fetchCountUnreadNotifications'])
        }
    }
</script>
