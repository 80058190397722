<template>
    <div ref="eContent" class="attached-file">
        <div class="d-flex"
            v-if="file.mime_type === 'image/jpeg' || file.mime_type === 'image/png' || file.mime_type === 'image/gif'">
            <img :src="file.path"/>
        </div>
        <div class="d-flex mtop-video" v-else-if="!!file.videoId">
            <iframe :height="calculatedHeight" :src="'https://player.vimeo.com/video/'+file.videoId"
                    class="video-responsive"
                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen></iframe>
        </div>
        <div class="d-flex" v-else>
            <a :href="file.path" @click="confirmWindow">{{fileName}}</a>
        </div>
    </div>
</template>
<script>
    export default {
        name   : 'AttachedFile',
        props  : {
            file: {
                type    : Object,
                required: true,
            },
        },
        data() {
            return {
                calculatedHeight: 180
            }
        },
        computed: {
            fileName() {
                let arrFileName = this.file.path.split('/');
                return arrFileName[arrFileName.length-1];
            },
        },
        mounted() {
            window.addEventListener('resize', this.setHeight);
            this.setHeight();
        },
        methods: {
            setHeight() {
                if(!!this.$refs.eContent) {
                    this.calculatedHeight = Math.ceil(this.$refs.eContent.clientWidth / 1.73);
                }
            },
            confirmWindow(e) {
                if (!confirm(this.$t('download_confirm'))) e.preventDefault();
            }
        }
    }
</script>
