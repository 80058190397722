import PaymentIndex from '@/components/Payment/PaymentIndex';
import Page from '@/layout/Page'

export default [
    {
        path: 'payment/:course_id',
        component: Page,
        children: [
            {
                path: '',
                component: PaymentIndex,
                name: 'payment.index',
            }
        ]
    }
]
