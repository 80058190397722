<template>
    <v-card class="mb-4" outlined>
        <v-card-title>{{$t('video_gallery.label')}}</v-card-title>
        <v-btn
            icon
            absolute
            top
            right
            @click="$emit('delete')"
        >
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114" stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
        </v-btn>
        <v-card-text>
            <div class="d-flex justify-start">
                <input accept="video/mp4,video/x-m4v,video/*" v-show="false" @change="onFileChange" type="file" multiple ref="file"/>
                <draggable class="d-flex justify-start"
                           :list="gallery.files"
                           ghost-class="ghost">
                    <div
                        class="mr-3"
                        v-for="(file, index) in gallery.files"
                        :key="index"
                    >
                        <v-avatar
                            class="position-relative outlined"
                            rounded
                            size="120"
                        >
                            <img class="img-mw"
                                 src="/img/video-image.svg"/>
                            <v-btn depressed @click="remove(index)" small class="ml-2 position-absolute right-0 top-0"
                                   rounded
                                   fab color="red">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114" stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </v-btn>
                        </v-avatar>
                    </div>
                </draggable>
                <div class="d-flex flex-column ml-2">
                    <v-btn class="mb-1" fab
                           rounded
                           outlined @click="$refs.file.click()">
                        <v-icon>mdi-cloud-upload</v-icon>
                    </v-btn>

                    <v-dialog
                        transition="dialog-top-transition"
                        max-width="400"
                        v-model="dialog"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn fab outlined
                                   rounded
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-link-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text class="pt-4">
                                <v-text-field
                                    v-model="video_link"
                                    :label="$t('video_gallery.video_link')"
                                    required
                                    outlined
                                ></v-text-field>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="addVideoFromLink"
                                >{{$t('add')}}
                                </v-btn>
                                <v-btn
                                    text
                                    @click="dialog = false"
                                >{{$t('cancel')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
            <div class="mt-3">[gallery id="{{gallery.uhash}}"/]</div>
        </v-card-text>
    </v-card>
</template>
<script>
    import draggable from 'vuedraggable'

    export default {
        name      : 'VideoGallery',
        components: {
            draggable
        },
        props     : {
            gallery: {
                type   : Object,
                default: null,
            },
            index  : {
                type   : Number,
                default: null,
            },
        },
        data() {
            return {
                dialog    : false,
                loading   : false,
                video_link: ''
            }
        },
        created() {
            if(!this.gallery.uhash) {
                this.gallery.uhash = new Date().getTime()+this.index;
            }
        },
        methods   : {
            remove(index) {
                this.gallery.files.splice(index, 1)
            },
            onFileChange(e) {
                Array
                    .from(Array(e.target.files.length).keys())
                    .map(x => {
                        this.gallery.files.push({
                            upload: e.target.files[x],
                            type  : 'upload'
                        });
                    });
            },
            addVideoFromLink() {
                if (!!this.video_link) {
                    this.gallery.files.push({
                        link: this.video_link,
                        type: 'link'
                    });
                }
                this.video_link = '';
                this.dialog = false
            }
        }
    }
</script>
