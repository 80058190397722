<template>
    <div class="position-relative" :id="'user-record-'+item.id"
         :class="(item.parent_id > 0) ? 'comment-responded' : ''">
        <svg class="icon-comment-responded" v-if="item.parent_id > 0 && index === 0" width="15" height="10"
             viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                stroke="black" stroke-width="1.3"/>
        </svg>
        <v-card outlined class="mb-20 mb-mobile comment-item-card" :loading="loading" v-observe-visibility="setRead">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-text class="black--text pa-20 pa-mobile">
                <v-row>
                    <v-col cols="12" md="6">
                        <div class="d-flex flex-md-row flex-column pa-0">
                            <div class="d-flex flex-row">
                                <div>
                                    <router-link class="mr-3"
                                                 :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: item.user.id}}">
                                        <v-avatar size="48" rounded color="white"
                                                  :class="(!!item.user.avatar) ? '' : 'outlined'">
                                            <img v-if="!!item.user.avatar"
                                                 :src="item.user.avatar"
                                                 alt=""
                                            >
                                            <v-icon color="black" v-else>
                                                mdi-image-outline
                                            </v-icon>
                                        </v-avatar>
                                    </router-link>
                                </div>
                                <div class="d-flex flex-column">
                                    <div>{{item.user.name}} {{item.user.last_name}}</div>
                                    <div v-if="!!item.deleted_at"
                                         class="d-flex flex-row align-center grey--text nowrap-text">
                                        <svg width="16" height="16" viewBox="0 0 14 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114"
                                                stroke="black" stroke-width="1.3" stroke-linecap="square"
                                                stroke-linejoin="round"/>
                                        </svg>
                                        <span class="ml-1">{{$t('comments.deleted')}}</span>
                                    </div>
                                    <div v-else-if="!!item.is_blocked"
                                         class="d-flex flex-row align-center grey--text nowrap-text">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                                                stroke="black" stroke-width="1.3"/>
                                        </svg>
                                        <span class="ml-1">{{$t('comments.blocked')}}</span>
                                    </div>
                                    <div v-else>
                                        <svg v-if="item.updated_at !== item.created_at" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                                stroke="black" stroke-opacity="0.4" stroke-width="1.3"
                                                stroke-linecap="square"/>
                                        </svg>
                                        <span class="grey--text">{{dateFormattedComment(item.created_at)}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-md-2 mb-3 mb-md-0 order-first order-md-last comment-admin-actions">
                                <v-btn @click="toggleBlocked"
                                       v-if="hasCourseAdminAccess($route.params.school_slug, item.course.id) && !item.deleted_at"
                                       class="default-border-radius btn-gray-hover" x-small fab outlined
                                       :class="(item.is_blocked) ? 'btn-gray-hover-active' : ''">
                                    <img class="img-active" src="/img/cancel-icon.svg"/>
                                    <img class="img-hover" src="/img/cancel-icon-white.svg"/>
                                    <img class="img-default" src="/img/cancel-icon-grey.svg"/>
                                </v-btn>
                            </div>
                            <v-chip v-if="isNew" label class="black--text ml-auto" color="accent">
                                {{$t('notifications.is_new')}}
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col cols="6" v-show="edit">
                        <v-btn small fab outlined class="rounded" @click="cancel(item)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-if="!item.deleted_at && !item.is_blocked">
                    <v-row class="line-height-mobile">
                        <v-col cols="12" md="6" sm="12">
                            <EmbedContent :content="content"/>
                        </v-col>
                    </v-row>
                    <AttachedFile v-for="(file, index) in videos" :file="file" :key="index"/>
                    <masonry class="attached-masonry"
                             :gutter="40"
                             :cols="{ default: 2, 1000: 2, 700: 2, 500: 1 }"
                    >
                        <AttachedFile v-for="(file, index) in files" :file="file" :key="index"/>
                    </masonry>
                    <div class="d-flex mtop flex-row align-center" v-show="!item.deleted_at">
                        <LikeBtn :item="item"/>
                        <FavoriteBtn :item="item"/>
                        <v-btn class="default-font-size ml-2 btn-gray-hover" x-small fab outlined @click="edit = !edit"
                               v-if="item.can_update && !item.deleted_at">
                            <img class="img-hover" src="/img/pencil-icon-white.svg"/>
                            <img class="img-default" src="/img/pencil-icon-grey.svg"/>
                        </v-btn>
                        <v-btn class="default-font-size ml-2 btn-gray-hover" x-small fab outlined
                               v-if="item.can_delete && !item.deleted_at && item.count_likes === 0 && item.count_favorites === 0"
                               @click="deleteRecord">
                            <img class="img-hover" src="/img/delete-icon-white.svg"/>
                            <img class="img-default" src="/img/delete-icon-grey.svg"/>
                        </v-btn>
                        <v-btn class="default-font-size ml-2 btn-gray-hover" small outlined
                               @click="setRespond(respond,item_respond)"
                               v-if="!item.deleted_at && canAnswer && (item.user.id !== $auth.user().id)">
                            {{$t('comments.respond')}}
                            <img class="img-hover ml-1" src="/img/comment-arrow-white.svg"/>
                            <img class="img-default ml-1" src="/img/comment-arrow-grey.svg"/>
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <CommentEdit @saved="saved" v-if="item.user.id === $auth.user().id" :item="item" :type="type" :id="id"
                     :block_id="block_id"
                     :users="users"
                     :edit="edit"/>
        <CommentItem @saved="saved" @respond="setRespond" v-for="(sub_item, index) in item.children" :item="sub_item"
                     :parent_item="parent_item"
                     :canAnswer="!item.deleted_at"
                     :block_id="block_id"
                     :users="users"
                     :type="type" :id="id"
                     :key="sub_item.id"
                     :level="level+1"
                     :index="index"
        />
        <CommentEdit v-if="level === 1" @saved="saved" :item="item_respond_pass" :type="type" :id="id"
                     :edit="respond" :users="users"
                     :block_id="block_id"
                     :respond_id="parent_item.id"
        />
    </div>
</template>

<script>
    import CommentEdit from "@/layout/Comments/CommentEdit";
    import LikeBtn from "@/layout/Comments/parts/LikeBtn";
    import FavoriteBtn from "@/layout/Comments/parts/FavoriteBtn";
    import EmbedContent from "@/layout/EmbedContent";
    import {mapActions} from "vuex";
    import AttachedFile from "@/layout/Comments/parts/AttachedFile";

    export default {
        name      : 'CommentItem',
        components: {
            AttachedFile,
            CommentEdit,
            LikeBtn,
            FavoriteBtn,
            EmbedContent
        },
        data() {
            return {
                form_errors      : [],
                edit             : false,
                respond          : false,
                loading          : false,
                item_respond     : {
                    parent_id  : this.parent_item.id,
                    content    : '@' + this.item.user.slug + ' ',
                    preview_url: '',
                    is_active  : 1,
                    files      : []
                },
                item_respond_pass: {
                    parent_id  : this.parent_item.id,
                    content    : '@' + this.item.user.slug + ' ',
                    preview_url: '',
                    is_active  : 1,
                    files      : []
                },
                files            : [],
                videos           : [],
                old_content      : '',
            }
        },
        props     : {
            item       : {
                type    : Object,
                required: true,
            },
            parent_item: {
                type    : Object,
                required: true,
            },
            type       : {
                type    : String,
                required: true,
            },
            id         : {
                type    : Number,
                required: true,
            },
            block_id   : {
                type    : String,
                required: true,
            },
            users      : {
                type    : Array,
                required: true,
            },
            canAnswer  : {
                type   : Boolean,
                default: true,
            },
            level      : {
                type    : Number,
                required: true,
            },
            index      : {
                type    : Number,
                required: true,
            },
        },
        computed  : {
            content() {
                let users_arr = [];
                for (let k in this.users) {
                    users_arr['@' + this.users[k].value] = this.users[k];
                }
                const re = /(^|\W)@(\w+)/g;
                let content = this.item.content, match, current_match;
                while (match = re.exec(content)) {
                    current_match = match[0].trim();
                    if (!!users_arr[current_match]) {
                        content = content.replace(current_match, '<a href="/profile/' + users_arr[current_match].value + '">' + current_match + '</a>');
                    }
                }

                return content;
            },
            isNew() {
                for (let k in this.item.notifiable) {
                    if (Number(this.item.notifiable[k].is_read) === 0) {
                        return true;
                    }
                }
                return false
            },
            notificationId() {
                for (let k in this.item.notifiable) {
                    if (Number(this.item.notifiable[k].is_read) === 0) {
                        return this.item.notifiable[k].id;
                    }
                }
                return false
            }
        },
        mounted() {
            this.oldContent();
            this.refreshFiles();
        },
        methods   : {
            ...mapActions(['fetchCountUnreadNotifications']),
            toggleBlocked() {
                this.loading = true;
                axios.get('/records/' + this.item.id + '/toggle-blocked').then(response => {
                    this.item.is_blocked = response.data.data.is_blocked;
                }).finally(() => {
                    this.loading = false;
                });
            },
            saved(item, param) {
                this.item_respond = {
                    parent_id  : this.parent_item.id,
                    content    : '@' + this.item.user.slug + ' ',
                    preview_url: '',
                    is_active  : 1,
                    files      : []
                };
                this.edit = false;
                this.respond = false;
                this.$emit('saved', item, param);
                this.refreshFiles();
            },
            deleteRecord() {
                axios.delete('/records/' + this.item.id).then(() => {
                    this.$emit('saved', this.item, 'deleted');
                })
            },
            setRespond(respond, item) {
                this.$emit('respond', respond, item);
                this.item_respond_pass = item;
                this.respond = !respond;

                let app = this;
                setTimeout(function () {
                    app.$vuetify.goTo('#comment-respond-' + app.parent_item.id, {offset: 90});
                }, 500)
            },
            setRead(isVisible) {
                if (isVisible && this.isNew) {
                    let app = this;
                    setTimeout(function () {
                        let url = 'notifications/' + app.notificationId + '/read';
                        axios.get(url).then(response => {
                            app.item.notifiable = [];
                            app.fetchCountUnreadNotifications(this.$route.params.school_slug);
                        }).finally(() => {
                        });
                    }, 2000)
                }
            },
            oldContent() {
                this.old_content = this.item.content;
            },
            cancel(item) {
                this.edit = false;
                this.item.content = !!this.old_content ? this.old_content : item.content;
            },
            refreshFiles() {
                this.files = [];
                this.videos = [];
                for (let k in this.item.files) {
                    if (!!this.item.files[k].videoId) {
                        this.videos.push(this.item.files[k]);
                    } else {
                        this.files.push(this.item.files[k]);
                    }
                }
            }
        }
    }
</script>
