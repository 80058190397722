<template>
    <v-card
        class="course-list-item" :class="(isOwner || onlyStudent(item.id)) ? 'course-owner' : ''"
        outlined
    >
        <v-card-text class="fill-height">
            <div class="course-list-item-labels d-flex flex-row align-center">
                <v-chip label v-if="isOwner" class="white--text chip-square" color="primary">
                    {{$t('course.you_curator')}}
                </v-chip>
                <v-chip label v-else-if="onlyStudent(item.id)" class="black--text" color="accent">
                    {{$t('course.you_student')}}
                </v-chip>
                <span class="mx-2" v-show="proStudent(item.id) && (isOwner || onlyStudent(item.id))">/</span>
                <v-chip label v-if="proStudent(item.id)" class="white--text" color="primary">
                    {{$t('course.pay_type.pro')}}
                </v-chip>
                <span class="mx-2" v-show="(item.status > 0 || (item.status === 0 && isOwner)) && (proStudent(item.id) || (isOwner || onlyStudent(item.id)))">/</span>
                <CourseStatusLabel :status="item.status" :is-admin="isOwner"/>
                <span class="mx-2" v-show="(item.count_notifiable > 0)">/</span>
                <v-chip
                        v-show="(item.count_notifiable > 0)"
                        label
                        class="black--text"
                        color="white"
                >
                    {{ item.count_notifiable }} {{ $t('course.notifications') }}
                </v-chip>
            </div>
            <v-row justify="center" class="fill-height my-0 course-list-item-row">
                <v-col
                    md="6"
                    sm="12"
                    cols="12"
                    class="d-flex flex-column py-0 fill-height"
                >
                    <div class="course-list-item-title text-h1">{{ item.title }}</div>
                    <div class="mb-4 course-list-item-descr">
                        {{ item.description }}
                        <a target="_blank" :href="item.url_code">
                            <svg width="15" height="10" viewBox="0 0 15 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                                    stroke="black" stroke-width="1.3"/>
                            </svg>
                            {{ $t('course.course_info') }}
                        </a>
                    </div>
                    <div class="d-flex flex-row mt-auto">
                        <v-btn v-if="hasSubscribeAccess"
                               color="gray"
                               :href="item.link_google_form"
                               depressed
                        >
                            {{ $t('subscribe') }}
                        </v-btn>
                        <v-btn v-else-if="hasOpenAccess"
                               color="secondary" class="black--text"
                               :to="{name: 'courses.show', params : {school_slug: item.school.slug, id: item.id}}"
                               depressed
                        >
                            {{ $t('open_course') }}
                        </v-btn>
                        <v-btn
                            v-else-if="hasBuyAccess"
                            :to="{name: 'payment.index', params: {school_slug: item.school.slug, course_id: item.id}}"
                            class="white--text"
                            color="primary"
                            depressed
                        >
                            {{ $t('buy') }}
                        </v-btn>
                        <v-btn
                            v-else-if="hasBecomeStudentAccess"
                            @click="becomeStudentFree(item)"
                            class="white--text"
                            color="primary"
                            depressed
                        >
                            {{ $t('become_student_free') }}
                        </v-btn>
                        <v-btn
                            class="ml-3"
                            v-if="isOwner"
                            color="secondary"
                            depressed
                            fab
                            :to="{name: 'courses.edit', params: { school_slug: item.school.slug, id: item.id } }">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                    stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                            </svg>
                        </v-btn>
                        <v-btn
                            class="ml-3"
                            v-if="hasDeleteAAccess"
                            color="secondary" depressed fab
                            @click="$emit('delete', item)">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114" stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </v-btn>
                    </div>
                </v-col>
                <v-col
                    md="6"
                    sm="12"
                    cols="12"
                    class="py-0 fill-height text-right"
                >
                    <img :src="item.image"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {STATUS_COURSE_SOON, STATUS_COURSE_OPEN} from '@/components/Course/course.constants'
    import CourseStatusLabel from '@/components/Course/parts/CourseStatusLabel'

    export default {
        name      : 'CourseListItem',
        props     : {
            item: {
                type    : Object,
                required: true
            }
        },
        components: {CourseStatusLabel},
        data() {
            return {
                STATUS_COURSE_SOON: STATUS_COURSE_SOON,
            }
        },
        computed  : {
            isOwner() {
                return this.hasCourseAdminAccess(this.item.school.slug, this.item.id)
            },
            hasDeleteAAccess() {
                return this.hasSchoolAdminAccess(this.item.school.slug) && this.item.can_delete;
            },
            hasOpenAccess() {
                return this.hasStudentAccess(this.item.school.slug, this.item.id);
            },
            hasSubscribeAccess() {
                return this.item.status === STATUS_COURSE_SOON && !this.isOwner;
            },
            hasBecomeStudentAccess() {
                return this.item.status === STATUS_COURSE_OPEN && this.item.payment_methods.length === 0 && !this.hasOpenAccess;
            },
            hasBuyAccess() {
                return this.item.status === STATUS_COURSE_OPEN && this.item.payment_methods.length > 0 && !this.hasOpenAccess;
            }
        },
        methods   : {
            becomeStudentFree(course) {
                if (this.$auth.check()) {
                    axios.get('courses/' + course.id + '/become-student').then(() => {
                        this.$auth.fetch();
                        this.$router.push({
                            name  : 'courses.show',
                            params: {school_slug: this.item.school.slug, id: this.item.id}
                        });
                    })
                } else {
                    this.$router.push({name: 'login'});
                }
            }
        }
    };
</script>
