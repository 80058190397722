<template>
    <div class="pt-spacer">
        <v-row>
            <v-col cols="12">
                <v-btn
                    outlined
                    :to="{name: 'courses.index', params: {school_slug: $route.params.school_slug} }"
                >
                    <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="black"/>
                        <path d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z" stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                    </svg>
                    {{ $t('course.open_from_edit') }}
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-card class="mb-4" outlined>
                    <v-card-title>{{$t('course.image_title')}}</v-card-title>
                    <v-card-text>
                        <div>
                            <div class="mb-4">
                                <v-responsive :aspect-ratio="22/13" class="gray lighten-2 rounded-lg">
                                    <img v-if="!!item.image" style="max-width: 100%;" :src="item.image"/>
                                </v-responsive>
                            </div>
                            <div class="d-flex justify-left align-center">
                                <v-btn class="mr-4"
                                       rounded
                                       fab
                                       outlined
                                       @click="$refs.image.click()">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                            stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                                    </svg>
                                </v-btn>
                                <v-btn class="mr-4"
                                       rounded
                                       fab
                                       :color="(!!item.is_blocked) ? 'error' : 'primary'"
                                       outlined
                                       @click="deleteImage">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                                            stroke="black" stroke-width="1.3"/>
                                    </svg>
                                </v-btn>
                                <div>
                                    {{$t('course.image_size_title')}}<br/>
                                    {{$t('course.image_wh_title')}}
                                </div>
                            </div>
                            <input v-show="false" @change="onImageChange" type="file"
                                   accept="image/png, image/jpeg" ref="image"/>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="mb-4" outlined>
                    <v-card-title>{{$t('course.course_about')}}</v-card-title>
                    <v-card-text>
                        <v-text-field
                            ref="form.title"
                            v-model="item.title"
                            :rules="[() => !!item.title || $t('form.rules.required')]"
                            :error-messages="form_errors.title"
                            :label="$t('course.name_label')"
                            required
                            outlined
                        ></v-text-field>
                        <v-text-field
                            ref="form.description"
                            v-model="item.description"
                            :error-messages="form_errors.description"
                            :label="$t('course.description_label')"
                            required
                            outlined
                        ></v-text-field>
                        <v-text-field
                            ref="form.url_code"
                            v-model="item.url_code"
                            :error-messages="form_errors.url_code"
                            :label="$t('course.slug_label')"
                            required
                            outlined
                        ></v-text-field>
                        <v-text-field
                            ref="form.link_google_form"
                            v-model="item.link_google_form"
                            :error-messages="form_errors.link_google_form"
                            :label="$t('course.link_google_form_label')"
                            required
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-card>
                <v-card class="mb-4" outlined>
                    <v-card-title>{{$t('course.type_course')}}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    :items="course_statuses"
                                    :label="$t('course.status_label')"
                                    v-model="item.status"
                                    :error-messages="form_errors.status"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    :items="course_access_types"
                                    :label="$t('course.access_type_label')"
                                    v-model="item.type"
                                    :error-messages="form_errors.type"
                                    outlined
                                    :disabled="!!item.id"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <PaymentMethod v-for="(payment_method, index) in item.payment_methods" :key="index"
                               :index="index" :item="payment_method" @delete="deletePaymentMethod(index)"/>
                <v-btn
                    x-large
                    outlined
                    @click="addPaymentMethod"
                >
                    <svg class="mr-2" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{$t('course.add_payment_type_label')}}
                </v-btn>
            </v-col>
        </v-row>
        <div class="d-flex justify-space-between mt-12 mb-6">
            <v-btn depressed
                   x-large
                   color="accent" class="black--text"
                   @click="submit"
            >
                {{$t('save_changes')}}
            </v-btn>
            <v-btn depressed
                   v-if="school"
                   x-large
                   color="gray" class="black--text"
                   :to="{name: 'courses.index', params: {school_slug: school.slug}}"
            >
                {{$t('cancel_changes')}}
            </v-btn>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapMutations} from 'vuex';
    import {STATUS_COURSE_SELECT, ACCESS_TYPE_COURSE_SELECT} from './course.constants'
    import PaymentMethod from '@/components/Course/parts/PaymentMethod'
    import FaqFormItem from '@/components/Course/parts/FaqFormItem'
    import TeacherFormItem from '@/components/Course/parts/TeacherFormItem'

    export default {
        components: {
            TeacherFormItem,
            FaqFormItem,
            PaymentMethod,
        },
        data() {
            return {
                item               : {
                    title           : '',
                    url_code        : '',
                    link_google_form: '',
                    description     : '',
                    status          : 0,
                    type            : 1,
                    price           : 0,
                    seo_title       : '',
                    seo_description : '',
                    is_active       : false,
                    is_finished     : false,
                    is_need_register: false,
                    is_paddle       : false,
                    faqs            : [],
                    teachers        : [],
                    payment_methods : [],
                    image           : ''
                },
                image              : null,
                preview            : null,
                form_errors        : [],
                course_statuses    : STATUS_COURSE_SELECT,
                course_access_types: ACCESS_TYPE_COURSE_SELECT,
                loading            : false
            }
        },
        mounted() {
            this.load().then(() => {
                this.setBreadcrumbs([
                    {
                        text: this.$t('admin_menu_title'),
                        to  : {name: 'admin.schools.index'}
                    },
                    {
                        text: this.$t('school.courses_n_posts'),
                        to  : {name: 'admin.courses.index', params: {school_slug: this.$route.params.school_slug}}
                    },
                    {
                        text: !!this.item.id
                            ? (this.$t('edit') + ' ' + this.item.title)
                            : (this.$t('create') + ' ' + this.$t('course.course'))
                    },
                ]);
            });
        },
        computed  : {
            ...mapGetters(['school'])
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),

            async load() {
                if (!!this.$route.params.id && this.$route.params.id !== 'add') {
                    this.loading = true;
                    await axios.get('/schools/' + this.$route.params.school_slug + '/courses/' + this.$route.params.id).then(response => {
                        this.item = response.data.data;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },

            submit() {
                this.loading = true;

                const url = (this.item.id)
                    ? '/schools/' + this.$route.params.school_slug + '/courses/' + this.$route.params.id
                    : '/schools/' + this.$route.params.school_slug + '/courses'

                axios.post(url, this.prepareForm()).then(response => {
                    this.form_errors = [];

                    this.showMessage(this.$t('successfully_saved'));

                    if (!this.item.id) {
                        this.item = response.data.data;
                        this.$router.push({
                            name  : 'courses.edit',
                            params: {
                                school_slug: this.$route.params.school_slug,
                                id         : response.data.data.id
                            }
                        }).then(() => {
                            this.setBreadcrumbs([
                                {text: (this.$t('edit') + ' ' + this.item.title)},
                            ]);
                        });
                    }
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            addPaymentMethod() {
                let method = {
                    type_payment : 0,
                    product_id   : 0,
                    price        : 0,
                    currency     : '',
                    product_title: ''
                };
                this.item.payment_methods.push(method);
            },
            prepareForm() {
                let formData = new FormData();

                if (this.item.id) formData.append('_method', 'PUT');

                for (let field in this.item) {
                    let value = this.item[field];

                    if (typeof value === 'object' || ['image'].includes(field)) continue;

                    if (typeof value === 'boolean') {
                        value = value ? 1 : 0;
                    }

                    formData.append(field, value);
                }

                if (!!this.image) {
                    formData.append('image', this.image);
                }

                for (let k in this.item.payment_methods) {
                    formData.append('payment_methods[' + k + '][type_payment]', this.item.payment_methods[k].type_payment);
                    formData.append('payment_methods[' + k + '][product_id]', this.item.payment_methods[k].product_id);
                    formData.append('payment_methods[' + k + '][price]', this.item.payment_methods[k].price);
                    formData.append('payment_methods[' + k + '][currency]', this.item.payment_methods[k].currency);
                    formData.append('payment_methods[' + k + '][product_title]', this.item.payment_methods[k].product_title);
                    formData.append('payment_methods[' + k + '][method]', 'paddle');
                    formData.append('payment_methods[' + k + '][interval]', '1');
                    if (!!this.item.payment_methods[k].interval_type) {
                        formData.append('payment_methods[' + k + '][interval_type]', this.item.payment_methods[k].interval_type);
                    }
                }

                return formData;
            },
            deleteImage() {
                this.item.is_delete_image = 1;
                this.$refs.image.value = null;
                this.item.image = '';
            },
            onImageChange(e) {
                this.item.is_delete_image = 0;
                let file = e.target.files[0];
                this.item.image = URL.createObjectURL(file);
                this.image = file;
            },
            deletePaymentMethod(index) {
                this.item.payment_methods.splice(index, 1);
            }
        },
    }
</script>
