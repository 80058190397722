import i18n from '@/plugins/i18n'

export const CONTENT_TYPE_PAID = 'paid';
export const CONTENT_TYPE_FREE = 'free';

export const STATUS_COURSE_HIDDEN = 0;
export const STATUS_COURSE_OPEN = 1;
export const STATUS_COURSE_SOON = 2;
export const STATUS_COURSE_CLOSED = 3;

export const ACCESS_TYPE_COURSE_TOKENIZED = 1;
export const ACCESS_TYPE_COURSE_OPENED = 0;

export const PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE = 0;
export const PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO = 1;
export const PAYMENT_TYPE_COURSE_ALL = 2;

export const CONTENT_TYPES_SELECT = [
    {
        text : i18n.t('course.payment.paid'),
        value: CONTENT_TYPE_PAID
    },
    {
        text : i18n.t('course.payment.free'),
        value: CONTENT_TYPE_FREE
    }
];

export const PAYMENT_TYPE_SUBSCRIPTION = 'subscription';
export const PAYMENT_TYPE_FIXED_PRICE = 'fixed_price';

export const PAYMENT_TYPES_SELECT = [
    {
        text : i18n.t('course.payment.subscription'),
        value: PAYMENT_TYPE_SUBSCRIPTION
    },
    {
        text : i18n.t('course.payment.fixed_price'),
        value: PAYMENT_TYPE_FIXED_PRICE
    }
]

export const PAYMENT_METHOD_PADDLE = 'paddle';
export const PAYMENT_METHOD_TINKOFF = 'tinkoff';

export const PAYMENT_METHODS_SELECT = [
    {
        text : 'Paddle',
        value: PAYMENT_METHOD_PADDLE
    },
    // {
    //     text  : 'Tinkoff',
    //     value : PAYMENT_METHOD_TINKOFF
    // }
]

export const STATUS_COURSE_SELECT = [
    {
        text : i18n.t('course.type.open'),
        value: STATUS_COURSE_OPEN
    },
    {
        text : i18n.t('course.type.hidden'),
        value: STATUS_COURSE_HIDDEN
    },
    {
        text : i18n.t('course.type.soon'),
        value: STATUS_COURSE_SOON
    },
    {
        text : i18n.t('course.type.closed'),
        value: STATUS_COURSE_CLOSED
    },
];

export const ACCESS_TYPE_COURSE_SELECT = [
    {
        text : i18n.t('course.access_type.tokenized'),
        value: ACCESS_TYPE_COURSE_TOKENIZED
    },
    {
        text : i18n.t('course.access_type.opened'),
        value: ACCESS_TYPE_COURSE_OPENED
    },
];

export const PAYMENT_TYPE_COURSE_SELECT = [
    {
        text : i18n.t('course.payment_type.subscription_simple'),
        value: PAYMENT_TYPE_COURSE_SUBSCRIPTION_SIMPLE
    },
    {
        text : i18n.t('course.payment_type.subscription_pro'),
        value: PAYMENT_TYPE_COURSE_SUBSCRIPTION_PRO
    },
    {
        text : i18n.t('course.payment_type.all'),
        value: PAYMENT_TYPE_COURSE_ALL
    },
];
