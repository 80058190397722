import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state    : {
        showSnack          : false,
        snackMessage       : "",
        snackColor         : "",
        breadcrumbsData    : [],
        forgotPasswordEmail: '',
        forgotPasswordToken: '',
        school             : null,
        confirmationEmail  : '',

        showLoginPopup            : false,
        showRegistrationPopup     : false,
        showSocialLoginPopup      : false,
        showForgotEnterPopup      : false,
        showForgotSendPopup       : false,
        showForgotSentPopup       : false,
        showFillInformationPopup  : false,
        showEmailConfirmationPopup: false,
        countUnreadNotifications  : 0
    },
    getters  : {
        school      : state => state.school,
        showSnack   : state => state.showSnack,
        snackMessage: state => state.snackMessage,
        snackColor  : state => state.snackColor,
        breadcrumbs : state => {
            if (!_.isArray(state.breadcrumbsData)) return [];

            let brcrs = [...state.breadcrumbsData];

            if (state.school) {
                brcrs.unshift({
                    text: state.school.name,
                    to  : (brcrs.length > 0) ? {name: 'courses.index', params: {school_slug: state.school.slug}} : null
                });
            }

            return brcrs.map(b => {
                return {
                    ...b,
                    exact   : true,
                    disabled: false
                }
            })
        },
    },
    mutations: {
        showSnackMessage(state, payload) {
            state.showSnack = true;
            state.snackMessage = payload.message;
            state.snackColor = payload.color;
        },
        hideSnackMessage(state) {
            state.showSnack = false;
        },
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbsData = breadcrumbs;
        },
        setSchool(state, school) {
            state.school = school;
        },
        SHOW_FORGOT_SEND(state, payload) {
            state.showForgotSendPopup = payload.dialog;
            state.forgotPasswordEmail = payload.email;
        },
        SHOW_FORGOT_SENT(state, payload) {
            state.showForgotSentPopup = payload.dialog;
            state.forgotPasswordEmail = payload.email;
        },
        SHOW_FORGOT_ENTER(state, payload) {
            state.showForgotEnterPopup = payload.dialog;
            state.forgotPasswordEmail = payload.email;
            state.forgotPasswordToken = payload.token;
        },
        SHOW_LOGIN(state, val) {
            state.showLoginPopup = val;
        },
        SHOW_SOCIAL_LOGIN(state, val) {
            state.showSocialLoginPopup = val;
        },
        SHOW_REGISTRATION(state, val) {
            state.showRegistrationPopup = val;
        },
        SHOW_FILL_INFORMATION(state, val) {
            state.showFillInformationPopup = val;
        },
        showEmailConfirmation(state, val) {
            state.showEmailConfirmationPopup = val.dialog;
            state.confirmationEmail = val.email;
        },
        setCountUnreadNotifications(state, val) {
            state.countUnreadNotifications = val;
        },
    },
    actions  : {
        loadSchool({commit}, school_slug) {
            return axios.get('/schools/' + school_slug).then(response => {
                commit('setSchool', response.data.data);
            })
        },
        oauth2Default({commit}, payload) {
            let form = {
                body  : {},
                code  : false,
                method: 'GET',
                params: {
                    scope: 'email',
                    state: {
                        school  : payload.school,
                    },
                }
            };
            form.params.scope = (payload.type === 'google') ? 'profile email' : 'email';
            form.code = !!payload.code;

            if (!!payload.code && !!payload.state) {
                let school_slug = !!payload.state.school ? payload.state.school : null;
                form.url = 'auth/' + payload.type + '/callback?code=' + payload.code+'&school='+school_slug;
                form.state = payload.state;
                form.body.code = payload.code;
            }
            return Vue.auth.oauth2(payload.type, form);
        },
        fetchCountUnreadNotifications({commit}, school_slug) {
            if (this._vm.$auth.check()) {
                return axios.get('schools/'+school_slug+'/notifications/count-unread').then(response => {
                    commit('setCountUnreadNotifications', response.data.data.count);
                }).finally(() => {
                });
            }
        }
    }
});

export default store;
