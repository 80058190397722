var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"360"},model:{value:(_vm.$store.state.showForgotEnterPopup),callback:function ($$v) {_vm.$set(_vm.$store.state, "showForgotEnterPopup", $$v)},expression:"$store.state.showForgotEnterPopup"}},[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":"","color":"transparent","to":{name: 'courses.index', params: {school_slug: this.$route.params.school_slug}}},on:{"click":function($event){return _vm.SHOW_FORGOT_ENTER({
                            email : '',
                            token : '',
                            dialog: false
                        })}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close\n        ")])],1),_vm._v(" "),(!_vm.sent)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('Preloader',{attrs:{"loading":_vm.loading}})],1),_vm._v(" "),_c('v-card-title',{staticClass:"text-h2 pb-0",staticStyle:{"word-break":"normal"},domProps:{"innerHTML":_vm._s((_vm.$route.name === 'invite') ? _vm.$t('auth.invite.title') : _vm.$t('auth.reset_password.new_password_title'))}}),_vm._v(" "),_c('v-card-text',{staticClass:"pt-5"},[_c('v-text-field',{attrs:{"outlined":"","rules":[function () { return !!_vm.form.password || _vm.$t('form.rules.required'); }],"error-messages":_vm.form_errors.password,"type":_vm.show_pwd ? 'text' : 'password',"append-icon":_vm.show_pwd ? 'mdi-eye' : 'mdi-eye-off',"label":(_vm.$route.name === 'invite') ? _vm.$t('auth.password_label') : _vm.$t('auth.reset_password.new_password_label'),"required":""},on:{"click:append":function($event){_vm.show_pwd = !_vm.show_pwd}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_vm._v(" "),_c('v-btn',{staticClass:"white--text mt-2",attrs:{"block":"","color":"black","depressed":"","x-large":""},on:{"click":_vm.submit}},[_vm._v("\n                "+_vm._s(_vm.$t('save'))+"\n            ")])],1)],2):_c('v-card',[_c('v-card-title',{staticClass:"text-h2",staticStyle:{"word-break":"normal"}},[_vm._v("\n            "+_vm._s(_vm.$t('auth.reset_password.title_success'))+"\n        ")]),_vm._v(" "),_c('v-card-text',{staticClass:"pt-5"},[_c('v-btn',{staticClass:"white--text justify-space-between",attrs:{"block":"","color":"black","depressed":"","x-large":"","to":{name: 'login'}},on:{"click":function($event){return _vm.SHOW_FORGOT_ENTER({
                            email : '',
                            token : '',
                            dialog: false
                        })}}},[_vm._v("\n                "+_vm._s(_vm.$t('auth.login.submit_button'))+"\n                "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("\n                    mdi-arrow-right\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }