<template>
    <div class="d-inline-flex">
        <v-menu
            right
            open-on-hover
            bottom
            offset-y
            content-class="shadowless"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="default-font-size btn-gray-hover" :class="(item.is_liked) ? 'btn-gray-hover-active' : ''"
                       small outlined @click="toggleLike()"
                       @mouseover="loadLikes"
                       v-bind="attrs"
                       v-on="on">
                    <span class="mr-1">{{item.count_likes}}</span>
                    <img class="img-active" src="/img/heart.svg"/>
                    <img class="img-hover" src="/img/heart-white.svg"/>
                    <img class="img-default" src="/img/heart-icon-default.svg"/>
                </v-btn>
            </template>
            <v-list outlined style="min-width: 300px" v-show="users.length > 0">
                <v-list-item v-for="user in slicedUsers" :key="user.id" style="min-height: 24px;" class="my-2">
                    <v-card class="shadowless pa-0"
                            :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: user.id}}">
                        <div class="d-flex flex-row">
                            <v-avatar size="24" rounded color="white" class="mr-2"
                                      :class="(!!user.avatar) ? '' : 'outlined'">
                                <img v-if="!!user.avatar"
                                     :src="user.avatar"
                                     alt=""
                                >
                                <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black"
                                          stroke-opacity="0.4"
                                          stroke-width="1.3"/>
                                </svg>
                            </v-avatar>
                            <div>{{user.name}}</div>
                        </div>
                    </v-card>
                </v-list-item>
                <v-list-item v-show="users.length > 5" style="min-height: 24px;" class="my-2">
                    <v-card class="shadowless pa-0" @click="dialog=true">
                        <div class="d-flex flex-row">
                            <v-avatar size="24" rounded color="gray" class="mr-2 pa-1 bg-gray-custom">
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.6706 7.50006L8.0074 13.9331L1.34419 7.50006C0.904689 7.08316 0.558501 6.58206 0.327425 6.02833C0.0963497 5.47459 -0.0146075 4.88022 0.00154042 4.28263C0.0176884 3.68504 0.160592 3.09718 0.421252 2.55608C0.681912 2.01497 1.05468 1.53233 1.51609 1.13856C1.97749 0.744794 2.51754 0.448417 3.10222 0.268099C3.6869 0.0877805 4.30355 0.0274264 4.91333 0.0908364C5.52312 0.154246 6.11283 0.340047 6.64533 0.636538C7.17783 0.93303 7.64159 1.33379 8.0074 1.81358C8.3748 1.33727 8.83909 0.940014 9.37123 0.64667C9.90337 0.353325 10.4919 0.170208 11.1 0.10878C11.708 0.0473513 12.3226 0.108934 12.9051 0.289673C13.4876 0.470412 14.0256 0.766417 14.4854 1.15916C14.9452 1.55191 15.3169 2.03294 15.5772 2.57215C15.8375 3.11136 15.9808 3.69714 15.9982 4.29283C16.0156 4.88852 15.9067 5.48129 15.6782 6.03406C15.4498 6.58682 15.1068 7.08767 14.6706 7.50526"
                                        fill="black"/>
                                </svg>
                            </v-avatar>
                            <div>{{$t('more_likes')}}</div>
                        </div>
                    </v-card>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="dialog" max-width="360px">
            <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="dialog = false"
            >
                <v-icon
                    class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card>
                <v-card-title class="pb-6">{{$t('likes')}}&nbsp;<span class="grey--text">[{{users.length}}]</span>
                </v-card-title>
                <v-card-text class="pa-10 pt-0">
                    <div class="mt-n3">
                        <v-card class="shadowless mt-3" v-for="user in users" :key="user.id"
                                :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: user.id}}">
                            <div class="d-flex flex-row align-center">
                                <v-avatar size="24" rounded color="white" class="mr-2"
                                          :class="(!!user.avatar) ? '' : 'outlined'">
                                    <img v-if="!!user.avatar"
                                         :src="user.avatar"
                                         alt=""
                                    >
                                    <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black"
                                              stroke-opacity="0.4"
                                              stroke-width="1.3"/>
                                    </svg>
                                </v-avatar>
                                <div>{{user.name}}</div>
                            </div>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        name    : 'LikeBtn',
        props   : {
            item: {
                type    : Object,
                required: true,
            },
        },
        data() {
            return {
                users      : [],
                loadedUsers: false,
                dialog     : false
            }
        },
        computed: {
            slicedUsers() {
                return this.users.slice(0, 5);
            }
        },
        methods : {
            toggleLike() {
                axios.post('/records/' + this.item.id + '/toggle-like').then(response => {
                    this.item.count_likes = response.data.data.count_likes;
                    this.item.is_liked = response.data.data.is_liked;
                    this.loadedUsers = false;
                    if (!!this.item.is_liked) {
                        this.showMessage(this.$t('like_set'), 'accent');
                    }
                });
            },
            loadLikes() {
                if (!this.loadedUsers) {
                    this.loadedUsers = true;
                    axios.get('/records/' + this.item.id + '/users-likes').then(response => {
                        if (!!response.data.data) {
                            this.users = response.data.data;
                        } else {
                            this.users = [];
                        }
                    });
                }
            }
        }
    }
</script>
