<template>
    <v-dialog
        v-model="$store.state.showForgotSentPopup"
        persistent
        max-width="360"
    >
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            :to="{name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}}"
            @click="SHOW_FORGOT_SENT(false)"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2 pb-0" v-html="$t('auth.forgot_password.notification_title')"></v-card-title>
            <v-card-text class="pt-0">
                <div class="mb-6 mt-1" v-html="$t('auth.forgot_password.notification_subtitle')"></div>
                <v-btn block depressed color="black" class="white--text" x-large @click="goLogin">
                    {{ $t('auth.login.submit_button') }}
                </v-btn>
                <div class="mt-6">
                    <a @click="goLogin">
                        <img src="/img/comment-arrow.svg"/>
                        {{ $t('auth.reset_password.title_resend_email') }}
                    </a>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapMutations} from 'vuex'
    export default {
        name    : 'ForgotSentBlock',
        data()
        {
            return {
                email   : '',
                loading : false
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (this.$route.name === 'forgotsent_by_school' && e.key === 'Escape') {
                    this.SHOW_FORGOT_SENT(false);
                    this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}}).then((resp) => {
                    }).catch(() => {});
                }
            });
        },
        methods : {
            ...mapMutations(['SHOW_FORGOT_SENT']),
            submit()
            {
                this.loading = true;
                axios.post('/schools/'+this.$route.params.school_slug+'/reset-password/create', {email : this.email}).finally(() => {
                    this.loading = false;
                });
            },
            goLogin() {
                this.SHOW_FORGOT_SENT({dialog: false});
                if (!!this.$route.params.school_slug) {
                    this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}});
                } else {
                    this.$router.push({name: 'login'});
                }
            },
        }
    }
</script>
