<template>
    <v-card outlined class="mb-4">
        <v-card-text class="pa-md-5 pa-3">
            <div class="d-flex flex-column flex-md-row justify-start justify-md-space-between align-md-center">
                <div>
                    <div class="d-flex flex-row align-center">
                        <div v-if="!!item.image" class="mr-3 d-flex align-center">
                            <img style="max-height: 48px;" class="rounded"
                                 :src="item.image"
                            >
                        </div>
                        <div>
                            {{item.title}}
                        </div>
                    </div>
                </div>
                <div class="mt-4 mt-md-0">
                    <div class="d-flex flex-row justify-md-end">
                        <v-btn v-if="hasSubscribeAccess"
                               color="gray"
                               :href="item.link_google_form"
                               depressed
                        >
                            {{ $t('subscribe') }}
                        </v-btn>
                        <v-btn v-else-if="hasOpenAccess"
                               color="gray" class="black--text"
                               :to="{name: 'courses.show', params : {school_slug: item.school.slug, id: item.id}}"
                               depressed
                        >
                            {{ $t('open_course') }}
                        </v-btn>
                        <v-btn
                            v-else-if="hasBuyAccess"
                            :to="{name: 'payment.index', params: {school_slug: item.school.slug, course_id: item.id}}"
                            class="white--text"
                            color="primary"
                            depressed
                        >
                            {{ $t('buy') }}
                        </v-btn>
                        <v-btn
                            v-else-if="hasBecomeStudentAccess"
                            @click="becomeStudentFree(item)"
                            class="white--text"
                            color="primary"
                            depressed
                        >
                            {{ $t('become_student_free') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import {STATUS_COURSE_OPEN, STATUS_COURSE_SOON} from "@/components/Course/course.constants";

    export default {
        name : 'UserCourse',
        props: {
            item: {
                type    : Object,
                required: true,
            },
        },
        computed  : {
            isOwner() {
                return this.hasCourseAdminAccess(this.item.school.slug, this.item.id)
            },
            hasDeleteAAccess() {
                return this.hasSchoolAdminAccess(this.item.school.slug) && this.item.can_delete;
            },
            hasOpenAccess() {
                return this.hasStudentAccess(this.item.school.slug, this.item.id);
            },
            hasSubscribeAccess() {
                return this.item.status === STATUS_COURSE_SOON && !this.isOwner;
            },
            hasBecomeStudentAccess() {
                return this.item.status === STATUS_COURSE_OPEN && this.item.payment_methods.length === 0 && !this.hasOpenAccess;
            },
            hasBuyAccess() {
                return this.item.status === STATUS_COURSE_OPEN && this.item.payment_methods.length > 0 && !this.hasOpenAccess;
            }
        },
        methods   : {
            becomeStudentFree(course) {
                if(this.$auth.check()) {
                    axios.get('courses/' + course.id + '/become-student').then(() => {
                        this.$auth.fetch();
                        this.$router.push({
                            name  : 'courses.show',
                            params: {school_slug: this.item.school.slug, id: this.item.id}
                        });
                    })
                } else {
                    this.$router.push({name: 'login'});
                }
            }
        }
    }
</script>
