<template>
    <v-dialog
            v-model="$store.state.showRegistrationPopup"
            persistent
            max-width="360"
    >
        <v-btn
                icon
                absolute
                top
                right
                color="transparent"
                @click="SHOW_REGISTRATION(false)"
                :to="{name: 'courses.index'}"
        >
            <v-icon
                    class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2 pb-0">
                {{ $t('auth.register.title') }}
            </v-card-title>
            <v-card-text class="pt-5">
                <v-text-field outlined
                              v-model="form.email"
                              :rules="[() => !!form.email || $t('form.rules.required')]"
                              :error-messages="form_errors.email"
                              :type="'email'"
                              :label="$t('auth.email_label')"
                              required
                ></v-text-field>
                <v-text-field outlined
                              v-model="form.password"
                              :rules="[() => !!form.password || $t('form.rules.required')]"
                              :error-messages="form_errors.password"
                              :type="show_pwd ? 'text' : 'password'"
                              :label="$t('auth.password_label')"
                              required
                >
                    <template slot="append">
                        <v-btn @click="show_pwd = !show_pwd" small fab rounded depressed class="button-slot-append">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                        fill="black"/>
                                <path
                                        d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                                        stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                            </svg>
                        </v-btn>
                    </template>
                </v-text-field>
                <v-btn depressed block color="primary" class="shadowless mt-2" x-large @click="submit">
                    {{ $t('auth.register.submit_button') }}
                </v-btn>
                <div class="position-relative my-4 py-3">
                    <v-divider></v-divider>
                    <div class="position-absolute top-0 d-inline-block px-1"
                         style="background-color: #ffffff; color: rgba(0,0,0,0.4); left: 50%; margin-left: -46px;">
                        {{$t('auth.login.or_through')}}
                    </div>
                </div>
                <v-row>
                    <v-col cols="6">
                        <v-btn block outlined x-large style="background-color: #4A67AD"
                               @click="oauth2Default({type: 'facebook', school: $route.params.school_slug})">
                            <img src="/img/fb-icon.png"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block outlined x-large
                               @click="oauth2Default({type: 'google', school: $route.params.school_slug})">
                            <img src="/img/google-icon.svg"/>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mt-6">
                    <a @click="goLogin">
                        <img src="/img/comment-arrow.svg"/>
                        {{ $t('auth.login.login_button') }}
                    </a>,
                    {{ $t('auth.login.if_isset_account_text') }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex'

    export default {
        name: 'RegistrationBlock',
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                },
                form_errors: [],
                loading: false,
                show_pwd: false
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    if (this.$route.name === 'registration' || this.$route.name === 'registration_by_school') {
                        this.SHOW_REGISTRATION(false);
                        this.$router.push({
                            name: 'courses.index',
                            params: {school_slug: this.$route.params.school_slug}
                        }).then((resp) => {
                        }).catch(() => {
                        });
                    }
                }
            });
        },
        methods: {
            ...mapMutations(['SHOW_FILL_INFORMATION', 'SHOW_REGISTRATION', 'SHOW_LOGIN', 'showEmailConfirmation']),
            ...mapActions(['oauth2Default']),
            submit() {
                this.form.name = this.form.email;
                this.form.password_confirmation = this.form.password;
                this.loading = true;
                this.$auth.register({
                    data: this.form,
                    redirect: '/' + this.$route.params.school_slug + '/courses',
                    url: !!this.$route.params.school_slug
                        ? 'schools/' + this.$route.params.school_slug + '/register'
                        : 'register',
                    staySignedIn: true,
                    autoLogin: false,
                    fetchUser: false
                }).then(response => {
                    this.form_errors = [];
                    this.SHOW_REGISTRATION(false);
                    this.showEmailConfirmation({dialog: true, email: this.form.email});
                }).catch(error => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            goLogin() {
                this.SHOW_REGISTRATION(false);
                if (!!this.$route.params.school_slug) {
                    this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}});
                } else {
                    this.$router.push({name: 'login'});
                }
            },
        },
    }
</script>
