import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import en from 'vuetify/lib/locale/en'
import ru from 'vuetify/lib/locale/ru'

Vue.use(Vuetify)

export const vuetify = new Vuetify({
    lang: {
        locales: { ru, en },
        current: 'ru',
    },
    theme : {
        themes : {
            light : {
                primary   : '#000000',
                secondary : '#FFFFFF',
                accent    : '#BDFF00',
                error     : '#D32F2F',
                gray      : "#E5E5E5",
                anchor    : '#696969',
            },
        },
    },
})

export const changeVuetifyLocale = function (locale) {
    vuetify.preset.lang.current = locale
}
