<template>
    <v-snackbar
        v-model="showAlert"
        :color="snackColor"
        :timeout="3500"
        width="100%"
        fixed
        :class="(snackColor === 'accent') ? 'black--text-i' : ''"
        :content-class="(snackColor === 'accent') ? 'black--text-i' : ''"
    >
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="showAlert = false"
                :class="(snackColor === 'accent') ? 'black--text-i' : ''"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

    export default {
        name : 'Snackbar',
        computed: {
            ...mapGetters(['showSnack', 'snackMessage', 'snackColor']),
            showAlert: {
                get() {
                    return this.showSnack
                },
                set() {
                    this.hideSnackMessage()
                }
            }
        },
        methods: {
            ...mapMutations(['hideSnackMessage'])
        }
    };
</script>

<style lang="scss">
    .v-snack {
        padding-bottom: 0 !important;
        .v-snack__wrapper {
            max-width: 100%;
        }
    }
</style>
