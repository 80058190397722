<template>
    <v-row>
        <v-col cols="4" class="text-truncate post-title">
            <v-avatar
                class="profile float-left m-1"
                color="gray"
                size="30"
                tile
            >
                <v-img :src="item.image"></v-img>
            </v-avatar>
            <div class="post-name">
                <div>{{ item.title }}</div>
                <div class="text--disabled">{{ item.created_at | date }}</div>
                <div class="post-type">({{ $t((entity === 'lectures') ? 'lecture.lecture' : 'post.post') }})</div>
            </div>
        </v-col>
        <v-col cols="4">
            <PostStatusLabel :status="item.status" is-admin/>
        </v-col>
        <v-col cols="4">
            <div class="post-action-buttons">
                <v-btn
                    small
                    outlined
                    fab
                    @click.native.stop=""
                    :to="{name: entity + '.show', params: {school_slug, id}}"
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                            fill="black"/>
                        <path
                            d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                            stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                    </svg>
                </v-btn>
                <v-btn small
                       rounded
                       fab
                       outlined
                       @click.native.stop=""
                       :to="{name: entity + '.edit', params: {school_slug, id}}">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                            stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                    </svg>
                </v-btn>
                <v-btn v-if="item.can_delete"
                       small
                       outlined
                       fab
                       @click="$emit('delete', course, item, entity)">
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114"
                            stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </v-btn>
                <v-btn
                    small
                    outlined
                    fab
                    @click.native.stop="handleCopy(item)">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                    small
                    outlined
                    fab
                    @click.native.stop="">
                    <v-icon>mdi-drag</v-icon>
                </v-btn>
                <v-btn
                    small
                    outlined
                    fab
                    @click.native.stop="">
                    <v-icon>mdi-telegram</v-icon>
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import PostStatusLabel from '@/components/Infopost/PostStatusLabel'

    export default {
        name      : 'AdminPostItem',
        props     : {
            course: {
                type    : Object,
                required: true
            },
            item  : {
                type    : Object,
                required: true
            },
            entity: {
                type   : String,
                default: 'lectures'
            }
        },
        components: {PostStatusLabel},
        data() {
            return {
                school_slug: this.$route.params.school_slug,
                id         : this.item.id,
            }
        },
        methods   : {
            handleCopy(item) {
                const entity_route = this.entity === 'infoposts' ? 'posts' : 'lectures';
                axios.post(entity_route + '/' + item.id + '/copy').then((response) => {
                    this.$emit('copiedPost', entity_route, response.data.data)
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .post-action-buttons {
        float: right;
    }

    .post-title {
        position: relative;

        .post-name {
            line-height: 15px;
            float: left;
            margin-left: 7px;

            .post-type {
                position: absolute;
                right: 0;
                top: 13px;
                background: white;
                color: gray;
                display: block;
            }
        }
    }
</style>
