<template>
    <v-card outlined class="mb-5">
        <v-card-text class="pa-3 pa-md-5">
            <v-row>
                <v-col cols="12" md="3">
                    <div class="d-flex flex-row">
                        <div>
                            <v-avatar size="48" rounded color="white" class="mr-3"
                                      :class="(!!item.recordable.image) ? '' : 'outlined'">
                                <img v-if="!!item.recordable.image"
                                     :src="item.recordable.image"
                                     alt=""
                                >
                                <v-icon color="primary" v-else>
                                    mdi-image-outline
                                </v-icon>
                            </v-avatar>
                        </div>
                        <div>
                            <div class="black--text">
                                <div>{{item.recordable.title}}</div>
                            </div>
                            <div class="grey--text">
                                {{dateFormattedComment(item.created_at)}}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="3" class="pt-0 pt-md-3">
                    <div class="d-flex flex-row align-center">
                        <div>
                            <v-avatar size="48" rounded color="white" class="mr-3"
                                      :class="(!!item.user.avatar) ? '' : 'outlined'">
                                <img v-if="!!item.user.avatar"
                                     :src="item.user.avatar"
                                     alt=""
                                >
                                <v-icon color="primary" v-else>
                                    mdi-image-outline
                                </v-icon>
                            </v-avatar>
                        </div>
                        <div>
                            <div class="black--text">
                                <div>{{item.user.name}} {{item.user.last_name}}</div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="3" class="d-flex align-center pt-0 pt-md-3">
                    <div style="height: 48px;"></div>
                    <v-card :to="linkTo" class="shadowless pa-0 black--text" v-html="content"></v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import {TYPE_LECTURE_RECORDS} from "@/components/Lecture/lecture.constants";

    export default {
        name    : 'SearchCommentItem',
        props   : {
            item: {
                type    : Object,
                required: true
            }
        },
        data() {
            return {}
        },
        computed: {
            linkTo() {
                let entity = (this.item.recordable_type === "App\\Models\\Post") ? 'infoposts' : 'lectures'

                return (!!this.item.recordable.type && this.item.recordable.type === TYPE_LECTURE_RECORDS) ? {
                    name    : 'records.show',
                    params  : {
                        lecture_id : this.item.recordable.id,
                        user_id    : this.item.user.id,
                        id         : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }, query: {course_id: this.item.recordable.course_id}
                } : {
                    name    : entity + '.show',
                    params  : {
                        id         : this.item.recordable.id,
                        comment_id : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }, query: {course_id: this.item.recordable.course_id}
                };
            },
            content() {
                if (this.item.content.length <= 100) {
                    return this.item.content;
                }
                return this.item.content.substr(0, 100 - 1) + '&hellip;'
            }
        },
    };
</script>
