import Vue from 'vue'
import {mapMutations} from 'vuex';
import {
    ROLE_ADMIN_PLATFORM,
    ROLE_ADMIN_SCHOOL,
    ROLE_ADMIN_COURSE,
    ROLE_STUDENT,
    ROLE_STUDENT_BASE,
    ROLE_STUDENT_PRO,
    DATETIME_DB_FORMAT,
    DATE_FORMAT,
    DATETIMEFULL_FORMAT,
} from '@/base.constants';

Vue.mixin({
    data() {
        return {}
    },
    filters: {
        date: function (datetime) {
            return dayjs(datetime).isValid() ? dayjs(datetime, DATETIME_DB_FORMAT).format(DATE_FORMAT) : ''
        },
        dateTime: function (datetime) {
            return dayjs(datetime).isValid() ? dayjs(datetime, DATETIME_DB_FORMAT).format(DATETIMEFULL_FORMAT) : ''
        },
    },
    methods: {
        ...mapMutations(['showSnackMessage', 'setCommentsUpdated']),
        showMessage(message, type) {
            this.showSnackMessage({
                message: message,
                color  : type,
            });
        },
        confirmDialog(title, message, options) {
            return this.$root.$confirm(title, message, options)
        },

        /*
         * Permissions
         */
        isAdminPlatform() {
            return this.$auth.check(ROLE_ADMIN_PLATFORM)
        },
        onlySchoolAdmin(school_slug) {
            return this.$auth.check({[ROLE_ADMIN_SCHOOL]: school_slug})
        },
        hasSchoolAdminAccess(school_slug) {
            return this.$auth.check(ROLE_ADMIN_PLATFORM)
                || this.$auth.check({[ROLE_ADMIN_SCHOOL]: school_slug})
        },

        onlyCourseAdmin(course_id) {
            return this.$auth.check({[ROLE_ADMIN_COURSE]: course_id})
        },
        hasCourseAdminAccess(school_slug, course_id) {
            return this.$auth.check(ROLE_ADMIN_PLATFORM)
                || this.$auth.check({[ROLE_ADMIN_SCHOOL]: school_slug})
                || this.$auth.check({[ROLE_ADMIN_COURSE]: course_id})
        },

        onlyStudent(course_id) {
            return this.$auth.check({[ROLE_STUDENT]: course_id})
                || this.$auth.check({[ROLE_STUDENT_BASE]: course_id})
                || this.$auth.check({[ROLE_STUDENT_PRO]: course_id})
        },

        proStudent(course_id) {
            return this.$auth.check({[ROLE_STUDENT_PRO]: course_id})
        },
        hasStudentAccess(school_slug, course_id) {
            return this.$auth.check(ROLE_ADMIN_PLATFORM)
                || this.$auth.check({[ROLE_ADMIN_SCHOOL]: school_slug})
                || this.$auth.check({[ROLE_ADMIN_COURSE]: course_id})
                || this.$auth.check({[ROLE_STUDENT]: course_id})
                || this.$auth.check({[ROLE_STUDENT_BASE]: course_id})
                || this.$auth.check({[ROLE_STUDENT_PRO]: course_id})
        },

        getAdminOneSchool() {
            let user_roles = this.$auth.user().roles;
            if (!!user_roles[ROLE_ADMIN_SCHOOL] && user_roles[ROLE_ADMIN_SCHOOL].length === 1) {
                return user_roles[ROLE_ADMIN_SCHOOL][0];
            }

            return false;
        },
        getOnlyCurator() {
            let user_roles = this.$auth.user().roles;
            if (!!user_roles[ROLE_ADMIN_COURSE] && user_roles[ROLE_ADMIN_COURSE].length > 0) {
                return user_roles[ROLE_ADMIN_COURSE][0];
            }

            return false;
        },
        dateFormattedComment(input) {
            return (!!input) ? dayjs(input).format('DD.MM в HH:mm') : '';
        },
        dateFormattedDate(input) {
            return (!!input) ? dayjs(input).format('DD.MM') : '';
        }
    }
});
