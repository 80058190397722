import ProfileIndex from "@/components/Profile/ProfileIndex";
import ProfileEdit from "@/components/Profile/ProfileEdit";
import ProfileSettings from "@/components/Profile/ProfileSettings";
import Page from '@/layout/Page';

export default [
    {
        path     : 'profile',
        component: Page,

        children: [
            {
                path     : '',
                component: ProfileIndex,
                name     : 'profile.index',
            },
            {
                path     : 'edit',
                component: ProfileEdit,
                name     : 'profile.edit',
            },
            {
                path     : 'settings',
                component: ProfileSettings,
                name     : 'profile.settings',
            },
            {
                path     : ':id',
                component: ProfileIndex,
                name     : 'profile.public',
            }
        ]
    }
]
