<template>
    <v-row justify="center">
        <v-col
            cols="12"
            sm="10"
            md="8"
            lg="6"
            class="text-center mt-md-16"
        >
            <div class="text-h3">404 Page not found</div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name : 'NotFound'
    }
</script>
