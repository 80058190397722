import LectureEdit from '@/components/Lecture/LectureEdit'
import LectureShow from '@/components/Lecture/LectureShow'
import Page from '@/layout/Page'

export default [
    {
        path: 'lectures',
        component: Page,
        children: [
            {
                path: ':id/edit',
                component: LectureEdit,
                name: 'lectures.edit',
            },
            {
                path: 'add',
                component: LectureEdit,
                name: 'lectures.add',
            },
            {
                path: ':id/:comment_id?',
                component: LectureShow,
                name: 'lectures.show',
            },
        ]
    }
]
