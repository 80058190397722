<template>
    <div>
        <v-overlay :class="heightClass" ref="overlay" content=""
                   :absolute="true"
                   :value="loading"
                   :opacity="0.1"
        >
            <v-progress-circular
                :size="50"
                color="accent"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    export default {
        name    : 'Preloader',
        props   : {
            loading: {
                type    : Boolean,
                required: true
            }
        },
        data() {
            return {
                heightClass: ''
            };
        },
        mounted() {
            this.heightClass = this.$refs.overlay.$vnode.elm.offsetHeight > 300 ? 'loading-overlay' : ''
        }
    };
</script>
