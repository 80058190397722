import i18n from "@/plugins/i18n";

export const STATUS_LECTURE_HIDDEN = 0;
export const STATUS_LECTURE_OPEN = 1;

export const STATUS_LECTURE_SELECT = [
    {
        text : i18n.t('lecture.hidden_status'),
        value: STATUS_LECTURE_HIDDEN
    },
    {
        text : i18n.t('lecture.opened_status'),
        value: STATUS_LECTURE_OPEN
    }
];

export const TYPE_LECTURE_COMMENTS = 0;
export const TYPE_LECTURE_RECORDS = 1;

export const TYPE_LECTURE_SELECT = [
    {
        text : i18n.t('lecture.comments_type'),
        value: TYPE_LECTURE_COMMENTS
    },
    {
        text : i18n.t('lecture.records_type'),
        value: TYPE_LECTURE_RECORDS
    }
];
