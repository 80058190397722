import Vue from 'vue';
import router from '@/plugins/router';

// Vue Resource
// import VueResource from 'vue-resource';

// Vue.use(VueResource);
// Vue.http.options.root = process.env.VUE_APP_API_URL;

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.baseURL = '/api';
axios.interceptors.response.use(null, error => {
    let path = '/error';
    if ((typeof error === 'object' && error !== null) && error.response.status === 404)
    {
        path = '/404';
        router.push(path);
    }
    return Promise.reject(error);
});
Vue.use(VueAxios, axios);

export default {
    root : '/api'
};
