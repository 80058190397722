import AdminSchoolIndex from '@/components/Admin/School/AdminSchoolIndex';
import AdminSchoolEdit from "@/components/Admin/School/AdminSchoolEdit";
import AdminSchoolShow from "@/components/Admin/School/AdminSchoolShow";
import SchoolLayout from '@/layout/SchoolLayout'
import {AuthResolver} from '@/plugins/AuthResolver'

export default [
    {
        path: 'schools',
        component: SchoolLayout,
        children: [
            {
                path: '',
                component: AdminSchoolIndex,
                name: 'admin.schools.index',
                beforeEnter: AuthResolver.adminRedirect
            },
            {
                path: 'add',
                component: AdminSchoolEdit,
                name: 'admin.schools.add',
            },
            {
                path: ':school_slug/edit',
                component: AdminSchoolEdit,
                name: 'admin.schools.edit',
            },
            {
                path: ':school_slug',
                component: AdminSchoolShow,
                name: 'admin.schools.show',
                beforeEnter: AuthResolver.adminRedirect
            },
        ]
    }
]
