<template>
    <v-col cols="12" class="pt-0 pt-md-3 pb-5 pb-md-3">
        <v-card
            :to="{name: 'profile.public',params: {school_slug: $route.params.school_slug, id: item.id}}"
            outlined>
            <v-card-text class="d-flex flex-row align-center pa-3 pa-md-5">
                <div>
                    <v-avatar size="48" rounded color="white" class="mr-3" :class="(!item.avatar) ? 'outlined' : ''">
                        <img v-if="!!item.avatar"
                             :src="item.avatar"
                             alt=""
                        >
                        <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black" stroke-opacity="0.4"
                                  stroke-width="1.3"/>
                        </svg>
                    </v-avatar>
                </div>
                <div class="black--text">
                    {{item.name}} {{item.last_name}}
                </div>
            </v-card-text>
        </v-card>
    </v-col>
</template>
<script>
    export default {
        name : 'SearchUserItem',
        props: {
            item: {
                type    : Object,
                required: true
            }
        },
        data() {
            return {}
        }
    };
</script>
