<template>
    <div>
        <v-row class="text-h2">
            <v-col cols="12" md="6">{{$t('record.work_records')}}</v-col>
            <v-col cols="12" md="6" class="font-sz-16">
                <a @click.prevent="$emit('change-sort', true)" :class="(!!sortByStar) ? 'black--text' : ''" href="#">{{$t('record.sort_best')}}</a>
                <span>/</span>
                <a @click.prevent="$emit('change-sort', false)" :class="(!sortByStar) ? 'black--text' : ''" href="#">{{$t('record.sort_new')}}</a>
            </v-col>
        </v-row>
        <masonry
            :gutter="40"
            :cols="{ default: 4, 1000: 3, 700: 2, 500: 1 }"
        >
            <div class="record-card-block">
                <v-card class="bg-gray-custom shadowless records-add-block"
                        :to="{name: 'records.show', params: { school_slug: $route.params.school_slug, lecture_id: lecture.id, user_id: $auth.user().id}}">
                    <v-responsive :aspect-ratio="1">
                        <v-card-text class="d-flex align-start flex-column fill-height">
                            <v-btn color="accent" depressed
                                   :to="{name: 'records.show', params: { school_slug: $route.params.school_slug, lecture_id: lecture.id, user_id: $auth.user().id, id: 'new' }}"
                                   class="shadowless black--text mb-auto" rounded fab>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="black" stroke-width="1.3"/>
                                </svg>
                            </v-btn>
                            <div class="text-h2 "
                                 v-html="(items.length === 0) ? $t('record.add_new_br') : $t('record.open_br')"></div>
                        </v-card-text>
                    </v-responsive>
                </v-card>
            </div>
            <RecordItem v-for="item in items" :item="item" :lecture="lecture" :key="item.id"/>
        </masonry>
    </div>
</template>

<script>

    import RecordItem from "@/layout/Comments/RecordItem";

    export default {
        name      : 'RecordsBlock',
        components: {
            RecordItem
        },
        props     : {
            lecture   : {
                type    : Object,
                required: true,
            },
            course_id : {
                type    : Number,
                required: true,
            },
            sortByStar: {
                type    : Boolean,
                required: true,
            },
            items     : {
                type   : Array,
                default: () => [],
            },
        },
        data() {
            return {}
        },
    }
</script>
