import RecordIndex from '@/components/Record/RecordIndex'
import RecordShow from '@/components/Record/RecordShow'
import Page from '@/layout/Page'

export default [
    {
        path: 'lectures/:lecture_id/records/',
        component: Page,
        children: [
            {
                path: '',
                component: RecordIndex,
                name: 'records.index',
            },
            {
                path: ':user_id/:id?',
                component: RecordShow,
                name: 'records.show',
            }
        ]
    }
]
