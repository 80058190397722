<template>
    <v-row justify="center">
        <v-col cols="12" class="pt-spacer">
            &nbsp;
        </v-col>
    </v-row>
</template>
<script>
    import {mapMutations} from 'vuex'

    export default {
        data() {
            return {}
        },
        watch  : {
            '$route.name': {
                handler  : function (search) {
                    switch (this.$route.name) {
                        case 'login':
                            this.SHOW_LOGIN(true);
                            break;
                        case 'login_by_school':
                            axios.get('schools/'+this.$route.params.school_slug).then(resp => {
                                this.SHOW_LOGIN(true);
                            }).catch((error) => {
                                if (error.response.status === 404) {
                                    this.$router.push({name: 'not_found'});
                                }
                            }).finally(() => {});
                            break;
                        case 'login_social':
                            this.SHOW_SOCIAL_LOGIN(true);
                            break;
                        case 'registration':
                            this.SHOW_REGISTRATION(true);
                            break;
                        case 'registration_by_school':
                            axios.get('schools/'+this.$route.params.school_slug).then(resp => {
                                this.SHOW_REGISTRATION(true);
                            }).catch((error) => {
                                if (error.response.status === 404) {
                                    this.$router.push({name: 'not_found'});
                                }
                            }).finally(() => {});
                            break;
                        case 'reset':
                            this.SHOW_FORGOT_ENTER({
                                email : this.$route.query.email,
                                token : this.$route.params.token,
                                dialog: true
                            });
                            break;
                        case 'reset_by_school':
                            this.SHOW_FORGOT_ENTER({
                                email : this.$route.query.email,
                                token : this.$route.params.token,
                                dialog: true
                            });
                            break;
                        case 'invite':
                            this.SHOW_FORGOT_ENTER({
                                email : this.$route.query.email,
                                token : this.$route.params.token,
                                dialog: true
                            });
                            break;
                        case 'forgot':
                            this.SHOW_FORGOT_SEND({email: '', dialog: true});
                            break;
                        case 'forgot_by_school':
                            this.SHOW_FORGOT_SEND({email: '', dialog: true});
                            break;
                        case 'forgotsent':
                            this.SHOW_FORGOT_SENT({email: this.$store.state.forgotPasswordEmail, dialog: true});
                            break;
                        case 'forgotsent_by_school':
                            this.SHOW_FORGOT_SENT({email: this.$store.state.forgotPasswordEmail, dialog: true});
                            break;
                        case 'confirmationsent':
                        case 'confirmationenter':
                            this.showEmailConfirmation({dialog: true, email: ''});
                            break;
                        default:
                            break;
                    }
                },
                deep     : true,
                immediate: true
            }
        },
        mounted() {
            this.setBreadcrumbs([]);
        },
        methods: {
            ...mapMutations([
                'SHOW_LOGIN',
                'SHOW_SOCIAL_LOGIN',
                'SHOW_REGISTRATION',
                'SHOW_FORGOT_ENTER',
                'SHOW_FORGOT_SEND',
                'SHOW_FORGOT_SENT',
                'setBreadcrumbs',
                'showEmailConfirmation'
            ]),
        }
    }
</script>
