<template>
    <div class="mb-16" :data-index="index">
        <v-card color="grey lighten-3 shadowless" class="mb-6" :id="'user-record-'+item.id" :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-text class="pa-3 pa-md-5" v-observe-visibility="setRead">
                <v-row>
                    <v-col cols="12" md="6">
                        <div class="d-flex flex-row">
                            <v-btn color="black"
                                   rounded
                                   class="shadowless white--text" fab>
                                {{index+1}}
                            </v-btn>
                            <router-link class="ml-3"
                                         :to="{name: 'profile.public', params: {school_slug: $route.params.school_slug, id: item.user.id}}">
                                <v-avatar size="48" color="accent" rounded
                                          :class="(!!item.user.avatar) ? '' : 'outlined'">
                                    <img v-if="item.user.avatar"
                                         :src="item.user.avatar"
                                    >
                                    <v-icon color="black" v-else>
                                        mdi-account-circle
                                    </v-icon>
                                </v-avatar>
                            </router-link>
                            <div class="ml-3 d-flex flex-column">
                                <div class="black--text">{{item.user.name}} {{item.user.last_name}}</div>
                                <div class="mt-auto grey--text" v-if="!edit">
                                    <span style="white-space: nowrap" v-if="item.updated_at !== item.created_at">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                                stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                                        </svg>
                                    </span>
                                    {{dateFormattedComment(item.created_at)}}
                                </div>
                                <div class="mt-auto grey--text" v-else>{{$t('editing')}}</div>
                            </div>
                            <div class="ml-3">
                                <v-btn v-if="hasCourseAdminAccess($route.params.school_slug, item.course.id)"
                                       @click="toggleStar"
                                       :color="(!!item.is_star ? 'accent' : 'gray darken-3')"
                                       :outlined="(!item.is_star)"
                                       depressed
                                       rounded
                                       class="default-border-radius" x-small fab>
                                    <svg width="16" height="15" viewBox="0 0 17 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path :fill-opacity="(!Number(item.is_star)) ? 0.4 : 1"
                                              d="M8.9392 12.4372L3.99468 15L4.9392 9.57195L0.933594 5.72816L6.46133 4.9384L8.93359 0L11.4059 4.9384L16.9336 5.72816L12.928 9.57195L13.8725 15L8.9392 12.4372Z"
                                              fill="black"/>
                                    </svg>
                                </v-btn>
                                <v-chip v-else
                                        :color="(!!item.is_star ? 'accent' : 'gray darken-3')"
                                        :outlined="(!item.is_star)"
                                        label small
                                        class="chip-square"
                                        v-show="(!!item.is_star)">
                                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.9392 12.4372L3.99468 15L4.9392 9.57195L0.933594 5.72816L6.46133 4.9384L8.93359 0L11.4059 4.9384L16.9336 5.72816L12.928 9.57195L13.8725 15L8.9392 12.4372Z"
                                            fill="black"/>
                                    </svg>
                                </v-chip>
                            </div>
                            <div class="ml-3">
                                <v-btn @click="toggleBlocked"
                                       v-if="hasSchoolAdminAccess(this.$route.params.school_slug)" color="gray darken-3"
                                       :class="(item.is_blocked) ? 'btn-gray-hover-active' : ''"
                                       class="default-border-radius btn-gray-hover" x-small fab outlined>
                                    <img class="img-active" src="/img/cancel-icon.svg"/>
                                    <img class="img-hover" src="/img/cancel-icon-white.svg"/>
                                    <img class="img-default" src="/img/cancel-icon-grey.svg"/>
                                </v-btn>
                            </div>
                            <v-chip v-if="isNew" label class="black--text ml-auto" color="accent">
                                {{$t('notifications.is_new')}}
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col v-show="edit" cols="6">
                        <v-btn small fab outlined class="rounded" @click="cancel(item)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-if="!!item.is_blocked" class="d-flex flex-row align-center grey--text nowrap-text mt-3">
                    <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    <div>{{$t('record.blocked')}}</div>
                </div>
                <div v-else>
                    <div v-if="!edit">
                        <v-row :class="(!!files.length || !!videos.length) ? 'mb-2' : 'mb-0'">
                            <v-col class="" md="6" sm="12">
                                <EmbedContent :content="content"/>
                            </v-col>
                        </v-row>
                        <AttachedFile v-for="(file, index) in videos" :file="file" :key="index"/>
                        <masonry class="attached-masonry"
                                 :gutter="40"
                                 :cols="{ default: 2, 1000: 2, 700: 2, 500: 1 }"
                        >
                            <AttachedFile v-for="(file, index) in files" :file="file" :key="index"/>
                        </masonry>
                        <div :class="(!!files.length || !!videos.length) ? 'mtop' : ''" class="d-flex flex-row align-center">
                            <LikeBtn :item="item"/>
                            <FavoriteBtn :item="item"/>
                            <v-btn class="default-font-size ml-2 btn-gray-hover" x-small fab outlined @click="edit=true"
                                   v-if="canEdit">
                                <img class="img-hover" src="/img/pencil-icon-white.svg"/>
                                <img class="img-default" src="/img/pencil-icon-grey.svg"/>
                            </v-btn>
                            <!--v-btn small outlined v-if="isOwner">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114" stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
                            </v-btn-->
                        </div>
                    </div>
                    <div v-else>
                        <RecordEditForm @edited="edited" :item="item" :index="index" :lecture="lecture"/>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <CommentsBlock :type="'record'" :items="item.children" :course_id="item.course.id"
                       :id="Number($route.params.lecture_id)"
                       :users="users"
                       :parent_id="item.id" :block_id="'record'+item.id"/>
    </div>
</template>
<script>
    import CommentsBlock from "@/layout/Comments/CommentsBlock";
    import RecordEditForm from "@/components/Record/parts/RecordEditForm";
    import LikeBtn from "@/layout/Comments/parts/LikeBtn";
    import FavoriteBtn from "@/layout/Comments/parts/FavoriteBtn";
    import EmbedContent from "@/layout/EmbedContent";
    import AttachedFile from "@/layout/Comments/parts/AttachedFile";

    export default {
        name      : 'RecordBlockComments',
        components: {
            CommentsBlock,
            RecordEditForm,
            LikeBtn,
            FavoriteBtn,
            EmbedContent,
            AttachedFile
        },
        data() {
            return {
                loading: false,
                edit   : false,
                files  : [],
                videos : [],
                old_content: '',
            }
        },
        props     : {
            item   : {
                type    : Object,
                required: true
            },
            lecture: {
                type    : Object,
                required: true
            },
            users  : {
                type    : Array,
                required: true
            },
            index  : {
                type    : Number,
                required: true
            }
        },
        computed  : {
            canEdit() {
                return Number(this.item.user.id) === Number(this.$auth.user().id) && this.item.can_update === true;
            },
            isNew() {
                for (let k in this.item.notifiable) {
                    if (Number(this.item.notifiable[k].is_read) === 0) {
                        return true;
                    }
                }
                return false
            },
            notificationId() {
                for (let k in this.item.notifiable) {
                    if (Number(this.item.notifiable[k].is_read) === 0) {
                        return this.item.notifiable[k].id;
                    }
                }
                return false
            },
            content() {
                return this.item.content;
            },
        },
        mounted() {
            this.oldContent();
            this.refreshFiles();
        },
        methods   : {
            toggleBlocked() {
                this.loading = true;
                axios.get('/records/' + this.item.id + '/toggle-blocked').then(response => {
                    this.item.is_blocked = response.data.data.is_blocked;
                }).finally(() => {
                    this.loading = false;
                });
            },
            toggleStar() {
                this.loading = true;
                axios.get('/records/' + this.item.id + '/toggle-star').then(response => {
                    this.item.is_star = response.data.data.is_star;
                }).finally(() => {
                    this.loading = false;
                });
            },
            oldContent() {
                this.old_content = this.item.content;
            },
            cancel(item) {
                this.edit = false;
                this.item.content = !!this.old_content ? this.old_content : item.content;
            },
            edited(item) {
                this.edit = false;
                this.$emit('edited', item);
                this.refreshFiles();
            },
            setRead(isVisible) {
                if (isVisible && this.isNew) {
                    let app = this;
                    setTimeout(function () {
                        let url = 'notifications/' + app.notificationId + '/read';
                        axios.get(url).then(response => {
                            app.item.notifiable = [];
                        }).finally(() => {
                        });
                    }, 2000)
                }
            },
            refreshFiles() {
                this.files = [];
                this.videos = [];
                for (let k in this.item.files) {
                    if (!!this.item.files[k].videoId) {
                        this.videos.push(this.item.files[k]);
                    } else {
                        this.files.push(this.item.files[k]);
                    }
                }
            }
        }
    }
</script>
