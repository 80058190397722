<template>
    <div class="pb-8 pt-1 pl-xs-1 pr-xs-1 mob-preview">
        <v-card :outlined="preview.type == 'text'" :to="linkTo" class="mob-preview-width shadowless">
            <v-responsive class="mob-preview" :aspect-ratio="1" v-if="preview.type === 'text'">
                <v-card-text class="mob-p-12 d-flex justify-center">
                    <div class="comment-preview-text black--text-i" v-if="preview.type === 'text'">{{content}}</div>
                </v-card-text>
            </v-responsive>
            <img v-else-if="preview.type === 'video'" class="mob-preview img-mw align-center fill-height" :src="preview.path" />
            <img v-else :src="preview.path" class="img-mw mob-preview"/>
        </v-card>
        <v-card-title class="pb-1 pl-0 pr-0 pt-2 pt-md-5 sm-default-font-size">
            <div class="d-flex align-center w-100">
                <div class="mr-auto">
                    <v-avatar size="30" class="mr-2 rounded" :class="(!!item.user.avatar) ? '' : 'outlined'">
                        <img v-if="!!item.user.avatar"
                             :src="item.user.avatar"
                             alt="John"
                        >
                        <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.00012L29 29.0001M29 1.00012L1 29.0001" stroke="black" stroke-opacity="0.4"
                                  stroke-width="1.3"/>
                        </svg>
                    </v-avatar>
                    {{item.user.name}} {{item.user.last_name}}
                </div>
                <div class="grey--text">
                    {{dateFormattedDate(item.updated_at)}}
                </div>
                <v-chip v-if="item.type === TYPE_LECTURE_COMMENTS" small label class="ml-2 chip-square">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4273_23107)">
                            <path d="M1 14L2.01033 11.0788C1.13713 9.8342 0.821245 8.36024 1.12141 6.93104C1.42157 5.50184 2.31736 4.21464 3.64221 3.30879C4.96706 2.40293 6.63082 1.94006 8.32415 2.00623C10.0175 2.0724 11.6251 2.66311 12.8482 3.66853C14.0713 4.67395 14.8266 6.02566 14.9736 7.47234C15.1207 8.91901 14.6495 10.3622 13.6476 11.5336C12.6458 12.705 11.1815 13.5248 9.52698 13.8407C7.87249 14.1566 6.14038 13.947 4.65271 13.251L1 14Z" fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4273_23107">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </v-chip>
            </div>
        </v-card-title>
    </div>
</template>
<script>
    import {TYPE_LECTURE_COMMENTS, TYPE_LECTURE_RECORDS} from "@/components/Lecture/lecture.constants";
    import {CONTENT_LENGTH} from '@/base.constants';
    export default {
        name    : 'SavedRecord',
        props   : {
            item: {
                type    : Object,
                required: true,
            },
        },
        data() {
            return {
                TYPE_LECTURE_COMMENTS: TYPE_LECTURE_COMMENTS,
                TYPE_LECTURE_RECORDS : TYPE_LECTURE_RECORDS
            }
        },
        computed: {
            preview() {
                let pr = {type: 'text'};
                let file = this.item.files.find(o => !!o.videoId);

                if (!!file) {
                    pr = {type: 'video', path: 'https://vumbnail.com/'+file.videoId+'.jpg'};
                } else {
                    for (let k in this.item.files) {
                        if (this.item.files[k].mime_type === 'image/jpeg' || this.item.files[k].mime_type === 'image/png' || this.item.files[k].mime_type === 'image/gif') {
                            pr = {type: 'image', path: this.item.files[k].path};
                            break;
                        }
                    }
                }
                return pr;
            },
            content() {
                let content = this.item.content;
                if (content.length > CONTENT_LENGTH) {
                    content = content.substr(1, CONTENT_LENGTH)+'...';
                }
                return content;
            },
            linkTo() {
                return ((!!this.toItem.type) && this.toItem.type === TYPE_LECTURE_RECORDS) ? {
                    name  : 'records.show',
                    params: {
                        lecture_id : this.toItem.id,
                        user_id    : this.item.user.id,
                        id         : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }
                } : {
                    name  : this.toItemType + '.show',
                    params: {
                        id         : this.toItem.id,
                        comment_id : this.item.id,
                        school_slug: this.$route.params.school_slug
                    }
                };
            },
            toItem() {
                if (!!this.item.lecture) {
                    return this.item.lecture;
                }
                if (!!this.item.post) {
                    return this.item.post;
                }
                return false;
            },
            toItemType() {
                if (!!this.item.post) {
                    return 'infoposts';
                }
                return 'lectures';
            },
        }
    }
</script>
