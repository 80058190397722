import Vue from 'vue';
import axios from 'axios';
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import driverOAuth2Google from '@websanova/vue-auth/dist/drivers/oauth2/google.esm.js';
import driverOAuth2Facebook from '@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js';

driverOAuth2Google.params.client_id = process.env.MIX_GOOGLE_ID;
driverOAuth2Google.params.redirect_uri = process.env.MIX_GOOGLE_URL;
driverOAuth2Facebook.params.client_id = process.env.MIX_FACEBOOK_ID;
driverOAuth2Facebook.params.redirect_uri = process.env.MIX_FACEBOOK_URL;

const auth = new VueAuth(Vue, {
    plugins : {
        http   : axios,
        router : Vue.router
    },
    drivers : {
        http   : driverHttpAxios,
        auth   : driverAuthBearer,
        router : driverRouterVueRouter,
        oauth2 : {
            google   : driverOAuth2Google,
            facebook : driverOAuth2Facebook,
        }
    },
    options : {
        rolesKey         : 'roles',
        notFoundRedirect : {name : 'not_found'},
        registerData     : {
            url      : 'register',
            method   : 'POST',
            redirect : '/login'
        },
        loginData        : {
            url       : 'login',
            method    : 'POST',
            redirect  : '/',
            fetchUser : true
        },
        logoutData       : {
            url         : 'logout',
            method      : 'GET',
            makeRequest : true
        },
        fetchData        : {
            url     : 'user',
            method  : 'GET',
            enabled : true
        },
        refreshData      : {
            url      : 'refresh-token',
            method   : 'GET',
            enabled  : false,
            interval : 30
        }
    }
});

export default auth;
