<template>
    <div @click.self="$emit('hideFeed')" class="notifications-column d-md-block d-flex justify-end" :class="(feedShow) ? 'on-display' : ''">
        <div class="mobile-inner px-md-10 px-c-10" :class="(notifications.length < 1) ? 'notification-block-width' : ''">
            <div class="d-flex flex-row mb-4 justify-space-between">
                <div class="d-none d-md-block mb-3">{{ $t('course.news_feed') }}</div>
                <a class="d-block d-md-none" style="white-space: nowrap" href="#" @click="$emit('hideFeed')">
                    {{ $t('course.news_feed') }}
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 8H14M14 8L6.54277 1M14 8L6.54277 15" stroke="black"
                              stroke-width="1.3"/>
                    </svg>
                </a>
                <router-link style="white-space: nowrap" :to="{name: 'notifications.index'}">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{ $t('all') }}
                </router-link>
            </div>
            <div class="notifications-column-inner">
                <div class="mb-3 mb-md-5" v-for="item in notifications" :key="item.id">
                    <NotificationCard :color="'white'" :to="{name: 'notifications.index', params: {id: item.id}}"
                                      :item="item"/>
                </div>
                <a v-if="!loadedNotifications" class="d-block" style="white-space: nowrap" href="#"
                   @click.prevent="loadNotifications">
                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                            stroke="black" stroke-width="1.3"/>
                    </svg>
                    {{ $t('notifications.more_news') }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    import NotificationCard from "@/components/Notifications/parts/NotificationCard";

    export default {
        name      : 'NotificationsBlock',
        components: {
            NotificationCard
        },
        props     : {
            feedShow: {
                type   : Boolean,
                default: () => false
            }
        },
        data() {
            return {
                notifications       : [],
                loadingNotifications: false,
                loadedNotifications : false,
                pageNotifications   : 1,
                height              : 0
            }
        },
        mounted() {
            this.loadNotifications();
            this.onResize();
        },
        methods   : {
            loadNotifications() {
                if (!this.loadedNotifications && !this.loadingNotifications) {
                    this.loadingNotifications = true;
                    let url = '/schools/'+this.$route.params.school_slug+'/notifications?page=' + this.pageNotifications;

                    axios.get(url).then(response => {
                        if (response.data.data) {
                            if (Number(response.data.meta.last_page) > this.pageNotifications) {
                                this.pageNotifications++;
                            } else {
                                this.loadedNotifications = true;
                            }
                        }
                        this.notifications.push(...response.data.data);
                    }).finally(() => {
                        this.loadingNotifications = false;
                    });
                }
            },
            onResize() {
                this.height = window.innerHeight
            }
        }
    }
</script>
