<template>
    <v-dialog
        v-model="$store.state.showLoginPopup"
        persistent
        max-width="360"
    >
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            @click="SHOW_LOGIN(false)"
            :to="{name: 'courses.index'}"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2 pb-0">
                {{ $t('auth.login.title') }}
            </v-card-title>
            <v-card-text class="pt-5">
                <v-form @submit.prevent="submit">
                    <v-text-field outlined
                                  v-model="form.email"
                                  :rules="[() => !!form.email || $t('form.rules.required')]"
                                  :error-messages="form_errors.email"
                                  :type="'email'"
                                  :label="$t('auth.email_label')"
                                  required
                    ></v-text-field>
                    <div class="position-relative">
                        <a class="position-absolute right-0 top-0" @click="goForgot">{{
                            $t('auth.login.forgot_password_link') }}</a>
                        <v-text-field outlined
                                      v-model="form.password"
                                      :rules="[() => !!form.password || $t('form.rules.required')]"
                                      :error-messages="form_errors.password"
                                      :type="show_pwd ? 'text' : 'password'"
                                      :label="$t('auth.password_label')"
                                      required
                        >
                            <template slot="append">
                                <v-btn @click="show_pwd = !show_pwd" small fab rounded depressed
                                       class="button-slot-append">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                            fill="black"/>
                                        <path
                                            d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z"
                                            stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                                    </svg>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </div>
                    <v-btn depressed
                           block
                           color="black"
                           class="white--text"
                           x-large
                           type="submit"
                    >{{ $t('auth.login.submit_button') }}
                    </v-btn>
                    <div class="position-relative my-4 pt-7 pb-6">
                        <v-divider></v-divider>
                        <div class="position-absolute d-inline-block px-1"
                             style="background-color: #ffffff; color: rgba(0,0,0,0.4); left: 50%; margin-left: -46px; top: 14px">
                            {{$t('auth.login.or_through')}}
                        </div>
                    </div>
                    <v-row>
                        <v-col cols="6">
                            <v-btn block outlined x-large style="background-color: #4A67AD"
                                   @click="oauth2Default({type: 'facebook', school: $route.params.school_slug})">
                                <img src="/img/fb-icon.png"/>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block outlined x-large
                                   @click="oauth2Default({type: 'google', school: $route.params.school_slug})">
                                <img src="/img/google-icon.svg"/>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>

                <div class="mt-5">
                    <a @click="goRegister">
                        <img src="/img/comment-arrow.svg"/>
                        {{ $t('auth.login.register_link') }}
                    </a>
                    ,
                    {{ $t('auth.login.if_register_text') }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex'

    export default {
        name   : 'LoginBlock',
        data() {
            return {
                form       : {
                    email   : '',
                    password: '',
                    remember: true,
                },
                form_errors: [],
                loading    : false,
                show_pwd   : false,
                school_slug   : this.$route.params.school_slug
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    if(this.$route.name === 'login' || this.$route.name === 'login_by_school') {
                        this.SHOW_LOGIN(false);
                        this.$router.push({name: 'courses.index', params: {school_slug: this.school_slug}}).then((resp) => {
                        }).catch(() => {});
                    }
                }
            });
        },
        methods: {
            ...mapMutations(['SHOW_FILL_INFORMATION', 'SHOW_LOGIN', 'SHOW_REGISTRATION', 'SHOW_FORGOT_SEND', 'showEmailConfirmation']),
            ...mapActions(['oauth2Default', 'fetchCountUnreadNotifications']),
            submit() {
                this.loading = true;
                let app = this;
                this.$auth.login({
                    data      : {
                        email   : app.form.email,
                        password: app.form.password
                    },
                    url: !!this.$route.params.school_slug
                        ? 'schools/'+this.$route.params.school_slug+'/login'
                        : 'login',
                    rememberMe: app.form.remember,
                }).then(response => {
                    let school_slug = !!this.school_slug ? this.school_slug : this.$auth.user().schools[0].slug;
                    this.form_errors = [];
                    this.SHOW_LOGIN(false);
                    this.fetchCountUnreadNotifications(school_slug);
                    if (!this.$auth.user().fill_complete) {
                        this.SHOW_FILL_INFORMATION(true);
                    }
                    this.$router.push({name: 'courses.index', params: {school_slug: school_slug}}).then((resp) => {
                    }).catch(() => {});
                }).catch(error => {
                    if (error.response.data.data === 'verification.not_verified') {
                        this.SHOW_LOGIN(false);
                        this.showEmailConfirmation({dialog: true, email: this.form.email});
                    } else {
                        this.form_errors = error.response.data.data;
                        this.showMessage(error.response.data.message, 'error');
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            goRegister() {
                this.SHOW_LOGIN(false);
                if (!!this.$route.params.school_slug) {
                    this.$router.push({name: 'registration_by_school', params: {school_slug: this.$route.params.school_slug}});
                } else {
                    this.$router.push({name: 'registration'});
                }
            },
            goForgot() {
                this.SHOW_LOGIN(false);
                if (!!this.$route.params.school_slug) {
                    this.$router.push({name: 'forgot_by_school', params: {school_slug: this.$route.params.school_slug}});
                } else {
                    this.$router.push({name: 'forgot'});
                }
            }
        },
    }
</script>
