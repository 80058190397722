<template>
    <div>
        <div class="text-h2 mb-20" v-if="type !== 'record'">{{(type === 'lecture') ?
            $t('comments.lecture_comments_title') :
            $t('comments.post_comments_title')}}
        </div>
        <div class="comment-block-items">
            <svg class="icon-comment-responded" width="15" height="10"
                 viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.917358 1C0.917358 1.79565 0.917358 5 0.917358 5C0.917358 5 3.03067 5 3.79346 5H13.3805M13.3805 5L9.54568 1M13.3805 5L9.54568 9"
                    stroke="black" stroke-width="1.3"/>
            </svg>
            <CommentEdit @saved="saved" :users="users" :item="item" :type="type" :id="id" :edit="true"
                         :block_id="block_id"
                         :respond_id="0"/>
            <CommentItem @saved="saved" :users="users" v-for="(item, index) in items" :item="item" :parent_item="item"
                         :block_id="block_id"
                         :type="type" :id="id"
                         :key="item.id"
                         :level="1"
                         :index="index"
            />
        </div>
        <!--div class="text-center my-4" v-observe-visibility="load" v-if="!loaded">
            {{$t('record.loading')}}
        </div-->
    </div>
</template>

<script>
    import CommentItem from "@/layout/Comments/CommentItem";
    import CommentEdit from "@/layout/Comments/CommentEdit";

    export default {
        name      : 'CommentsBlock',
        components: {
            CommentItem,
            CommentEdit
        },
        props     : {
            type     : {
                type    : String,
                required: true,
            },
            id       : {
                type    : Number,
                required: true,
            },
            course_id: {
                type    : Number,
                required: true,
            },
            parent_id: {
                type   : Number,
                default: 0,
            },
            users    : {
                type   : Array,
                default: () => [],
            },
            items    : {
                type   : Array,
                default: () => [],
            },
            block_id : {
                type   : String,
                default: 'default',
            }
        },
        data() {
            return {
                //items  : [],
                item   : {
                    parent_id  : this.parent_id,
                    content    : '',
                    is_active  : false,
                    preview_url: '',
                    files      : []
                },
                page   : 1,
                loading: false,
                loaded : false
            }
        },
        mounted() {

        },
        watch     : {
            id(value) {
                if (!!value) {
                    this.loadUsers();
                }
            },
        },
        methods   : {
            /*load(isVisible, entry) {
                if (!!this.id && isVisible && !this.loaded) {
                    this.loading = true;

                    let url = (this.type === 'lecture' || this.type === 'record') ? '/lectures/' + this.id + '/records' : '/posts/' + this.id + '/records';
                    if (!!this.parent_id) {
                        url += '/' + this.parent_id
                    }

                    url += '?page=' + this.page;

                    axios.get(url).then(response => {
                        this.items.push(...response.data.data);
                        if (Number(response.data.meta.last_page) > this.page) {
                            this.page++;
                        } else {
                            this.loaded = true;
                        }

                        if (!!this.$route.params.comment_id && this.isIdPresent(this.$route.params.comment_id)) {
                            let app = this;
                            setTimeout(function () {
                                app.$vuetify.goTo('#user-record-' + app.$route.params.comment_id, {offset: 70});
                            }, 500)
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },*/
            loadUsers() {
                axios.get('courses/' + this.course_id + '/users').then(response => {
                    for (let k in response.data.data) {
                        this.users.push(
                            {
                                id    : response.data.data[k].id,
                                value : response.data.data[k].slug,
                                label : response.data.data[k].name,
                                avatar: response.data.data[k].avatar
                            }
                        );
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            saved(item, param) {
                this.item = {
                    parent_id  : this.parent_id,
                    content    : '',
                    is_active  : false,
                    preview_url: '',
                    files      : []
                };
                if (!item.parent_id) {
                    item.parent_id = 0;
                }

                if (param === 'created') {
                    if (this.parent_id === item.parent_id) {
                        this.items.push(item);
                    } else {
                        for (let k in this.items) {
                            if (this.items[k].id === item.parent_id) {
                                this.items[k].children.push(item);
                                this.items[k].can_delete = 0;
                            }
                        }
                    }
                } else if (param === 'updated') {
                    if (this.parent_id === item.parent_id) {
                        for (let k in this.items) {
                            if (this.items[k].id === item.id) {
                                this.items.splice(k, 1, item);
                            }
                        }
                    } else {
                        for (let k_parent in this.items) {
                            for (let k_children in this.items[k_parent].children) {
                                if (this.items[k_parent].children[k_children].id === item.id) {
                                    this.items[k_parent].children.splice(k_children, 1, item);
                                }
                            }
                        }
                    }
                } else if (param === 'deleted') {
                    if (this.parent_id === item.parent_id) {
                        for (let k in this.items) {
                            if (this.items[k].id === item.id) {
                                this.items[k].deleted_at = 1;
                            }
                        }
                    } else {
                        for (let k_parent in this.items) {
                            for (let k_children in this.items[k_parent].children) {
                                if (this.items[k_parent].children[k_children].id === item.id) {
                                    this.items[k_parent].children[k_children].deleted_at = 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>
