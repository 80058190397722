<template>
    <div
        class="pt-spacer">
    </div>
</template>
<script>
    import {mapMutations} from 'vuex';

    export default {
        components : {
        },
        data()
        {
            return {
                items       : [
                    {
                        id    : 1,
                        title : 'test 1',
                        img   : ''
                    },
                    {
                        id    : 2,
                        title : 'test 2',
                        img   : ''
                    },
                    {
                        id    : 3,
                        title : 'test 3',
                        img   : ''
                    },
                    {
                        id    : 4,
                        title : 'test 4',
                        img   : ''
                    },
                    {
                        id    : 5,
                        title : 'test 5',
                        img   : ''
                    },
                ],
                loading     : false
            }
        },
        mounted() {
            this.setBreadcrumbs([
                {
                    text : this.$t('record.records')
                },
            ]);
        },
        methods    : {
            ...mapMutations(['setBreadcrumbs']),
        }
    }
</script>
