<template>
    <VueSlickCarousel :class="'gallery-type-'+gallery.type"
                      v-bind="(gallery.type === 'video') ? settingsVideo : settingsImage" :key="gallery.id">
        <div v-for="file in gallery.files">
            <div class="slide-content">
                <div>
                    <div class="video-container" v-if="gallery.type === 'video'">
                        <div v-if="$auth.check({[ROLE_STUDENT_SUSPENDED]: course_id})"
                             class="video-unavailable d-flex flex-row align-center justify-center fill-height ">
                            <div>{{$t('lecture.video_after_buy')}}</div>
                        </div>
                        <iframe v-else-if="file.provider === 'youtube'" class="video-iframe"
                                :src="'https://www.youtube.com/embed/' + file.videoId" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        <iframe v-else :src="'https://player.vimeo.com/video/'+file.videoId" class="video-iframe"
                                frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                    <img v-else :src="file.path"/>
                </div>
            </div>
        </div>
        <template #prevArrow="arrowOption">
            <v-btn color="accent"
                   class="shadowless black--text" fab
                   rounded>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </template>
        <template #nextArrow="arrowOption">
            <v-btn color="accent"
                   class="shadowless black--text" fab
                   rounded>
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
        </template>
    </VueSlickCarousel>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import {ROLE_STUDENT_SUSPENDED} from "@/base.constants";

    export default {
        name      : 'ImageGallery',
        components: {
            VueSlickCarousel
        },
        data() {
            return {
                settingsVideo         : {
                    dots         : false,
                    centerPadding: "60px",
                    focusOnSelect: true,
                    infinite     : false,
                    slidesToShow : 1,
                    speed        : 500
                },
                settingsImage         : {
                    dots          : false,
                    focusOnSelect : true,
                    infinite      : false,
                    slidesToShow  : (this.gallery.files.length > 1) ? 1 : 1,
                    speed         : 500,
                    centerMode    : false,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    variableWidth : true,
                    responsive    : [
                        {
                            breakpoint: 600,
                            settings  : {
                                variableWidth: false
                            }
                        },
                    ]
                },
                ROLE_STUDENT_SUSPENDED: ROLE_STUDENT_SUSPENDED,
            }
        },
        props     : {
            gallery  : {
                type    : Object,
                required: true
            },
            course_id: {
                type    : Number,
                required: true
            },
        }
    }
</script>
