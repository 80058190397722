<script>
    import ShortcodeParser from 'meta-shortcodes'
    import ImageGallery from "@/layout/Shortcode/Gallery";
    import Vue from 'vue'

    export default {
        components: {
            ShortcodeParser
        },
        name      : 'ShortcodeContent',
        props     : {
            item: {
                type    : Object,
                required: true
            },
        },
        data() {
            return {
                parser: ShortcodeParser()
            }
        },
        mounted() {
            const app = this;
            this.parser.add("gallery", function (opts, content) {
                let index = app.item.gallery.findIndex(x => Number(x.uhash) === Number(opts.id));
                return (index !== false && index >= 0) ? '<ImageGallery :course_id="item.course_id" :gallery="item.gallery[' + index + ']" />' : '';
            });
        },
        render(createElement) {
            let template = (!!this.item.description) ? '<div class="item-description">' + this.parser.parse(this.item.description) + '</div>' : '<div>' + this.item.description + '</div>'
            //template = template.replaceAll('<p><Image', "<Image").replaceAll(']" /></p>', ']" />');
            const item = this.item;
            let component = Vue.component('ShortcodeContent', {
                template  : template,
                components: {
                    'ImageGallery': ImageGallery
                },
                data() {
                    return {item: item}
                }
            });

            return createElement(
                'div',
                {
                    class: {}
                },
                [
                    createElement(component)
                ]
            )
        }
    }
</script>
