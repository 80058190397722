<template>
    <div class="editor">
        <div
            v-if="editor"
            class="menubar">

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('bold') }"
                @click="editor.chain().focus().toggleBold().run()"
            >
                <v-icon>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('italic') }"
                @click="editor.chain().focus().toggleItalic().run()"
            >
                <v-icon>mdi-format-italic</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >
                <v-icon>mdi-format-header-1</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
                <v-icon>mdi-format-header-2</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
                <v-icon>mdi-format-header-3</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('link') }"
                @click="setLink"
            >
                <v-icon>mdi-link-variant</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('link') }"
                @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')"
            >
                <v-icon>mdi-link-variant-off</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('bulletList') }"
                @click="editor.chain().focus().toggleBulletList().run()"
            >
                <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                :class="{ 'is-active': editor.isActive('orderedList') }"
                @click="editor.chain().focus().toggleOrderedList().run()"
            >
                <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>

            <v-btn
                small
                outlined
                @click="addImage">
                <v-icon>mdi-file-image-outline</v-icon>
            </v-btn>

            <v-menu offset-y v-if="gallery.length > 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        outlined
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>mdi-image-plus</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in gallery"
                        :key="index"
                    >
                        <v-list-item-title>
                            <v-btn depressed @click="addGallery(item)">
                                {{(item.type === 'video') ? $t('video_gallery.label') : $t('image_gallery.label')}} {{
                                item.uhash }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </div>

        <editor-content class="editor__content" :editor="editor"/>
    </div>
</template>

<script>
    import {Editor, EditorContent} from '@tiptap/vue-2'
    import StarterKit from '@tiptap/starter-kit'
    import Image from '@tiptap/extension-image'
    import Link from '@tiptap/extension-link'

    // https://github.com/ueberdosis/tiptap/issues/1283

    export default {
        components: {EditorContent},
        props     : {
            value  : {
                required: true
            },
            gallery: {
                default: []
            },
        },
        data() {
            return {
                editor: null,
            }
        },
        mounted() {
            this.init()
        },
        watch     : {
            value(val) {
                // HTML
                const isSame = this.editor.getHTML() === val

                if (isSame) {
                    return
                }

                this.editor.commands.setContent(val, false)
            }
        },
        beforeDestroy() {
            this.editor.destroy()
        },
        methods   : {
            init() {
                this.$nextTick(() => {
                    this.editor = new Editor({
                        extensions  : [
                            StarterKit,
                            Image,
                            Link,
                        ],
                        content     : this.value,
                        onUpdate    : () => {
                            this.$emit('input', this.editor.getHTML())
                        },
                        parseOptions: {
                            preserveWhitespace: true
                        }
                    })
                })
            },
            setLink() {
                const previousUrl = this.editor.getAttributes('link').href
                const url = window.prompt('URL', previousUrl)

                // cancelled
                if (url === null) {
                    return
                }

                // empty
                if (url === '') {
                    this.editor
                        .chain()
                        .focus()
                        .extendMarkRange('link')
                        .unsetLink()
                        .run()

                    return
                }

                // update link
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: url })
                    .run()
            },
            addImage() {
                const url = window.prompt('URL')

                if (url) {
                    this.editor.chain().focus().setImage({src: url}).run()
                }
            },
            addGallery(item) {
                let content = '[gallery id="' + item.uhash + '"/]';
                this.editor.chain().focus().insertContent(content).run()
            },
        }
    }
</script>
