var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-spacer",attrs:{"cols":"12"}},[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":_vm.$t('loading')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('school.schools')))]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer')],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'admin.schools.show', params: { school_slug: item.slug }}}},[_vm._v("\n                        "+_vm._s(item.name)+"\n                    ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":'green',"dark":""}},[_vm._v("\n                        "+_vm._s(_vm.$t('school.enabled'))+"\n                    ")]):_c('v-chip',{attrs:{"color":'red',"dark":""}},[_vm._v("\n                        "+_vm._s(_vm.$t('school.disabled'))+"\n                    ")])]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('img',{directives:[{name:"show",rawName:"v-show",value:(!!item.logo),expression:"!!item.logo"}],staticStyle:{"max-width":"100px"},attrs:{"src":item.logo}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasSchoolAdminAccess(item.slug))?_c('v-btn',{attrs:{"depressed":"","rounded":"","fab":"","outlined":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z","stroke":"black","stroke-width":"1.3","stroke-linecap":"square"}})])]):_vm._e()]}}])}),_vm._v(" "),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }