<template>
    <v-footer
        :fixed="false"
        :absolute="true"
        :padless="true"
        app
        id="footer"
    >
        <v-container fluid class="pa-md-15 px-c-10">
            <v-row>
                <v-col cols="12" md="6" class="footer-col">
                    <div class="text-h2">{{$t('footer_question')}} <a target="_blank" :href="support_link">{{$t('footer_question_here')}}</a></div>
                </v-col>
                <v-col cols="12" md="6" class="footer-col">
                    <div class="text-h2">
                        <a target="_blank" :href="fb_link">{{$t('fb_link')}}, </a>
                        <a target="_blank" :href="instagram_link">{{$t('instagram_link')}}, </a>
                        <a target="_blank" :href="vimeo_link">{{$t('vimeo_link')}}</a>
                    </div>
                </v-col>
            </v-row>
            <div class="d-flex flex-md-row flex-column justify-space-between mt-6 a-text footer-bottom">
                <div class="mb-3 mb-md-0" v-html="$t('copyright')"></div>
                <div class="ml-md-n11 footer-terms order-first order-md-0 mb-3 mb-md-0">
                    <router-link :to="{ name: 'terms', params: {school_slug: !!$route.params.school_slug ? $route.params.school_slug : DEFAULT_SCHOOL_SLUG}}" class="mr-md-10">
                        {{ $t('terms.title') }}
                    </router-link>
                    <router-link :to="{ name: 'privacy', params: {school_slug: !!$route.params.school_slug ? $route.params.school_slug : DEFAULT_SCHOOL_SLUG}}">
                        {{ $t('privacy.title') }}
                    </router-link>
                </div>
                <div>{{$t('design_text')}}</div>
            </div>
        </v-container>
    </v-footer>
</template>

<script>
    import {DEFAULT_SCHOOL_NAME, DEFAULT_SCHOOL_SLUG} from '@/base.constants'

    export default {
        name      : 'Footer',
        components: {},
        data() {
            return {
                DEFAULT_SCHOOL_SLUG     : DEFAULT_SCHOOL_SLUG,
                fb_link: '',
                instagram_link: '',
                vimeo_link: '',
                support_link: '',
            }
        },
        mounted() {
            this.load();
        },
        methods   : {
            async load() {
                let school_slug = !!this.$route.params.school_slug ? this.$route.params.school_slug : DEFAULT_SCHOOL_SLUG;

                await axios.get('/schools/' + school_slug).then(response => {
                    let data = response.data.data;
                    this.fb_link = data.fb_link;
                    this.instagram_link = data.instagram_link;
                    this.vimeo_link = data.vimeo_link;
                    this.support_link = data.support_link;
                }).finally(() => {
                });
            },
        }
    }
</script>
