<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" :style="{ zIndex: options.zIndex }">
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            @click="dialog = false"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card>
            <v-card-text class="pa-10">
                <div class="text-h2 mb-2">{{ title }}</div>
                <div>{{ message }}</div>
                <div class="mt-6">
                    <v-btn depressed block color="error" class="mb-3" @click.native="agree">
                        {{ $t('yes') }}
                    </v-btn>
                    <v-btn depressed block @click.native="cancel">{{ $t('cancel') }}</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            color: 'primary',
            width: 360,
            zIndex: 200
        }
    }),
    methods: {
        open(title, message, options) {
            this.dialog = true
            this.title = title
            this.message = message
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.reject(true)
            this.dialog = false
        }
    }
}
</script>
