<template>
    <v-card :loading="loading" class="shadowless">
        <template slot="progress">
            <Preloader :loading="loading"/>
        </template>
        <div>
            <v-card :id="(!this.item.id) ? 'user-record-new' : ''" class="shadowless pa-20" color="grey lighten-3">
                <div class="text-h2" v-if="!this.item.id">{{$t('record.add_new')}}</div>
                <v-textarea outlined class="borderless pa-0 record-textarea" v-model="item.content"
                            :placeholder="$t('record.description_placeholder')"
                ></v-textarea>
                <input v-show="false" @change="onFileChange" type="file" multiple
                       ref="file"/>
                <draggable group="uploads"
                           class="list-group d-flex justify-start pa-4"
                           ghost-class="ghost"
                           v-model="item.files"
                           handle=".handle">
                    <v-hover v-slot="{ hover }" class="comments-upload-item mr-2" v-for="(file, index) in item.files"
                             :key="index">
                        <div>
                            <div v-show="hover" class="position-absolute pa-1">
                                <v-btn @click="deleteFile(index)" class="shadowless black--text" x-small fab rounded>
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114"
                                                stroke="black" stroke-width="1.3" stroke-linecap="square"
                                                stroke-linejoin="round"/>
                                    </svg>
                                </v-btn>
                                <v-btn class="shadowless black--text handle cursor-move" x-small fab rounded>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M5.49966 3.71417C5.97302 3.71417 6.35675 3.33044 6.35675 2.85709C6.35675 2.38373 5.97302 2 5.49966 2C5.02631 2 4.64258 2.38373 4.64258 2.85709C4.64258 3.33044 5.02631 3.71417 5.49966 3.71417Z"
                                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        <path
                                                d="M5.49966 8.857C5.97302 8.857 6.35675 8.47326 6.35675 7.99991C6.35675 7.52655 5.97302 7.14282 5.49966 7.14282C5.02631 7.14282 4.64258 7.52655 4.64258 7.99991C4.64258 8.47326 5.02631 8.857 5.49966 8.857Z"
                                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        <path
                                                d="M5.49966 14.0001C5.97302 14.0001 6.35675 13.6163 6.35675 13.143C6.35675 12.6696 5.97302 12.2859 5.49966 12.2859C5.02631 12.2859 4.64258 12.6696 4.64258 13.143C4.64258 13.6163 5.02631 14.0001 5.49966 14.0001Z"
                                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        <path
                                                d="M10.4997 3.71417C10.973 3.71417 11.3568 3.33044 11.3568 2.85709C11.3568 2.38373 10.973 2 10.4997 2C10.0263 2 9.64258 2.38373 9.64258 2.85709C9.64258 3.33044 10.0263 3.71417 10.4997 3.71417Z"
                                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        <path
                                                d="M10.4997 8.857C10.973 8.857 11.3568 8.47326 11.3568 7.99991C11.3568 7.52655 10.973 7.14282 10.4997 7.14282C10.0263 7.14282 9.64258 7.52655 9.64258 7.99991C9.64258 8.47326 10.0263 8.857 10.4997 8.857Z"
                                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                        <path
                                                d="M10.4997 14.0001C10.973 14.0001 11.3568 13.6163 11.3568 13.143C11.3568 12.6696 10.973 12.2859 10.4997 12.2859C10.0263 12.2859 9.64258 12.6696 9.64258 13.143C9.64258 13.6163 10.0263 14.0001 10.4997 14.0001Z"
                                                fill="black" stroke="black" stroke-width="1.3" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                    </svg>
                                </v-btn>
                            </div>
                            <img
                                    v-if="file.id === 0 && (file.file.type === 'image/jpeg' || file.file.type === 'image/png' || file.file.type === 'image/gif')"
                                    :src="getImagePreview(file.file)"/>
                            <img
                                    v-else-if="file.id > 0 && !!file.mime_type && (file.mime_type === 'image/jpeg' || file.mime_type === 'image/png' || file.mime_type === 'image/gif')"
                                    :src="file.path"/>
                            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4000_45749)">
                                    <path
                                            d="M14 8.70678L9.1336 13.5975C8.24007 14.4955 7.02819 15 5.76455 15C4.50091 15 3.28903 14.4955 2.3955 13.5975C1.50198 12.6995 1 11.4816 1 10.2116C1 8.94166 1.50198 7.72372 2.3955 6.82573L7.26191 1.93499C7.85759 1.33633 8.66551 1 9.50794 1C10.3504 1 11.1583 1.33633 11.754 1.93499C12.3497 2.53365 12.6843 3.34561 12.6843 4.19225C12.6843 5.03889 12.3497 5.85085 11.754 6.44952L6.88757 11.3403C6.5822 11.6471 6.17338 11.8006 5.76455 11.8006C5.35573 11.8006 4.9469 11.6471 4.64154 11.3403C4.34369 11.0409 4.17637 10.6349 4.17637 10.2116C4.17637 9.7883 4.34369 9.38232 4.64154 9.08299L7.07474 6.63762"
                                            stroke="black" stroke-width="1.3" stroke-linecap="square"
                                            stroke-linejoin="bevel"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_4000_45749">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </v-hover>
                </draggable>
                <div class="w-100 d-flex justify-space-between">
                    <div class="d-flex justify-start">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="white" class="mr-4 shadowless bg-gray-custom sq-btn-d-mobile btn-upload-files"
                                       @click="$refs.file.click()"
                                       v-bind="attrs"
                                       v-on="on">
                                    <svg class="mr-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_4000_45749)">
                                            <path
                                                    d="M14 8.70678L9.1336 13.5975C8.24007 14.4955 7.02819 15 5.76455 15C4.50091 15 3.28903 14.4955 2.3955 13.5975C1.50198 12.6995 1 11.4816 1 10.2116C1 8.94166 1.50198 7.72372 2.3955 6.82573L7.26191 1.93499C7.85759 1.33633 8.66551 1 9.50794 1C10.3504 1 11.1583 1.33633 11.754 1.93499C12.3497 2.53365 12.6843 3.34561 12.6843 4.19225C12.6843 5.03889 12.3497 5.85085 11.754 6.44952L6.88757 11.3403C6.5822 11.6471 6.17338 11.8006 5.76455 11.8006C5.35573 11.8006 4.9469 11.6471 4.64154 11.3403C4.34369 11.0409 4.17637 10.6349 4.17637 10.2116C4.17637 9.7883 4.34369 9.38232 4.64154 9.08299L7.07474 6.63762"
                                                    stroke="black" stroke-width="1.3" stroke-linecap="square"
                                                    stroke-linejoin="bevel"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4000_45749">
                                                <rect width="16" height="16" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span class="d-none d-md-inline-flex">{{$t('comments.upload_label')}}</span>
                                </v-btn>
                            </template>
                            <v-card d-flex flex-wrap class="shadowless bg-gray tooltip-block">
                                <div class="">{{$t('comments.upload_rules_label')}}</div>
                                <div>{{this.config.files_validation.format}}</div>
                            </v-card>
                        </v-tooltip>
                    </div>
                    <v-btn v-show="!!this.item.id || this.item.content.length > 0" color="accent"
                           class="black--text shadowless"
                           @click="submit">
                        {{$t('publish')}}
                    </v-btn>
                </div>
            </v-card>
            <v-switch @change="changeSwitch"
                      v-if="(item.id === null || Number(item.id) === 0) && (!!lecture.course && !hasCourseAdminAccess($route.params.school_slug, lecture.course.id)) && !lecture.course.is_free"
                      v-model="item.is_comment_request"
                      inset
                      :label="$t('record.notify')"
            ></v-switch>
        </div>
        <v-dialog
                v-model="dialogErrorFile"
                persistent
                max-width="360"
        >
            <v-card>
                <v-card-text class="pa-8">
                    <div class="mb-4 text-h2 black--text">
                        {{$t('record.curator_notify')}} <span class="grey--text">{{$t('record.photo_or_video')}}</span>
                    </div>
                    <v-btn @click="dialogErrorFile = false"
                           block depressed
                           color="accent"
                           class="black--text"
                           x-large
                           type="submit"
                    >{{ $t('understand') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="360"
        >
            <v-card>
                <v-card-text class="pa-8">
                    <div class="mb-4 black--text">
                        <div class="text-h2 mb-4">{{$t('record.add_success')}}</div>
                        <div>{{$t('record.add_success_text')}}</div>
                    </div>
                    <v-btn @click="dialogSuccess = false"
                           block depressed
                           color="accent"
                           class="black--text"
                           x-large
                           type="submit"
                    >{{ $t('understand') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogSwitch"
                persistent
                max-width="360"
        >
            <v-btn
                    icon
                    absolute
                    top
                    right
                    color="transparent"
                    @click="closeSwitch"
            >
                <v-icon
                        class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card>
                <v-card-text class="pa-8">
                    <div class="mb-4 black--text">
                        <div class="text-h2 mb-4">{{$t('record.record_switch_text')}}</div>
                    </div>
                    <!--v-btn @click="closeSwitch"
                           block
                           color="primary"
                           class="black--text mb-4"
                           x-large
                    >{{ $t('record.increase_text') }} $30
                    </v-btn-->
                    <!--<v-btn v-for="(button, index) in buttons" :key="index" :href="button.link"-->
                    <v-btn @click="swapToPro"
                           block depressed
                           color="accent"
                           class="mb-6 black--text"
                    >
                        <span v-if="!!this.payment_pro">{{ $t('course.upgrade_to_pro')+this.payment_pro.price }}</span>
                    </v-btn>
                    <v-btn @click="closeSwitch"
                           block
                           depressed
                           class="mb-6 black--text"
                    >{{ $t('cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogValidation"
                persistent
                max-width="360"
        >
            <v-btn
                    icon
                    absolute
                    top
                    right
                    color="transparent"
                    @click="closeDialogValidation"
            >
                <v-icon
                        class="white--text">mdi-close
                </v-icon>
            </v-btn>
            <v-card class="border-radius-12">
                <v-card-text class="pa-dialog">
                    <div class="mb-4 black--text">
                        <div v-show="validationFormat">
                            <div class="text-h2 mb-4">{{$t('file.validation.format.title')}}</div>
                            <div class="mb-4">{{$t('file.validation.format.description') +' '+this.config.files_validation.format+'.' }}</div>
                        </div>
                        <div v-show="validationMaxSize">
                            <div class="text-h2 mb-4">{{$t('file.validation.max.title')}}</div>
                            <div class="mb-4">{{$t('file.validation.max.description')}}</div>
                        </div>
                    </div>
                    <v-btn @click="closeDialogValidation"
                           block
                           depressed
                           color="accent"
                           class="black--text"
                    >{{ $t('understand') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
    import draggable from "vuedraggable";

    export default {
        name: 'RecordEditForm',
        components: {
            draggable,
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            lecture: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                buttons: [],
                dialogErrorFile: false,
                dialogSuccess: false,
                dialogSwitch: false,
                loading: false,
                payment_pro: [],
                swapPro: false,
                dialogValidation: false,
                validationFormat: false,
                validationMaxSize: false,
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (e.key === 'Escape') {
                    this.dialogValidation = false;
                    this.dialogErrorFile = false;
                    this.dialogSuccess = false;
                    this.dialogSwitch = false;
                }
            });
        },
        watch: {
            lecture(val) {
                if (!!val.course.id && this.buttons.length === 0) {
                    this.loadButtons();
                }
            }
        },
        methods: {
            changeSwitch(val) {
                if (!this.lecture.can_comment_request && val) {
                    if (!this.swapPro) {
                        this.dialogSwitch = true;
                    }
                }
            },
            closeDialogValidation() {
                this.dialogValidation = false;
                this.validationFormat = false;
                this.validationMaxSize = false;
            },
            closeSwitch() {
                this.dialogSwitch = false;
                this.item.is_comment_request = false;
            },
            loadButtons() {
                this.loading = true;
                axios.get('/courses/' + this.lecture.course.id + '/payments/links').then(response => {
                    this.buttons = (!!response.data.data) ? response.data.data : [];
                    this.payment_pro = this.buttons.find(o => o.type_payment === 0) ?? null;
                }).finally(() => {
                    this.loading = false;
                });
            },
            swapToPro() {
                this.loading = true;
                axios.get('/courses/' + this.lecture.course.id + '/payments/paddle/subscription/swap-to-pro').then(response => {
                    this.dialogSwitch = false;
                    this.swapPro = true;
                }).finally(() => {
                    this.loading = false;
                });
            },
            submit() {
                if (!this.id && this.item.files.length === 0 && !!this.item.is_comment_request) {
                    this.dialogErrorFile = true;
                    return;
                }

                this.loading = true;

                let formData = new FormData();
                if (!!this.item.id) formData.append('_method', 'PUT');

                formData.append('content', this.item.content);
                formData.append('preview_url', this.item.preview_url);
                formData.append('is_active', (!!this.item.is_active) ? 1 : 0);
                if (!!this.item.is_comment_request) {
                    formData.append('is_comment_request', 1);
                }
                for (const i of Object.keys(this.item.files)) {
                    let maxSizeKb = this.config.files_validation.size*1024;
                    if(this.item.files[i].file.size > maxSizeKb) {
                        this.validationMaxSize = true;
                        this.dialogValidation = true;
                        this.loading = false;
                        return;
                    }

                    formData.append('files[' + i + '][id]', this.item.files[i].id)
                    if (this.item.files[i].id === 0) {
                        formData.append('files[' + i + '][file]', this.item.files[i].file)
                    }
                }

                let url = (!!this.item.id) ? 'records/' + this.item.id : 'lectures/' + this.$route.params.lecture_id + '/records';
                axios.post(url, formData).then(response => {
                    this.form_errors = [];
                    this.dialogSuccess = true;
                    this.$emit('edited', response.data.data);
                }).catch((error) => {
                    let error_message = Object.values(error.response.data.data);
                    if (!!error_message[0][0] && error_message[0][0].includes('files')) {
                        let error_msg = error_message[0][0].split(' ').join('');
                        let error_format = error_msg.includes(this.config.files_validation.format.split(' ').join(''));

                        if (error_format) {
                            this.validationFormat = true;
                        } else {
                            this.validationMaxSize = true;
                        }
                        this.dialogValidation = true;
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            onFileChange(e) {
                Array
                    .from(Array(e.target.files.length).keys())
                    .map(x => {
                        this.item.files.push({id: 0, file: e.target.files[x]});
                    });
            },
            deleteFile(index) {
                this.item.files.splice(index, 1);
            },
            getImagePreview(file) {
                return URL.createObjectURL(file);
            },
        }
    }
</script>
