import UserIndex from '@/components/User/UserIndex'
import UserEdit from '@/components/User/UserEdit'
import Page from '@/layout/Page'

export default [
    {
        path: 'users',
        component: Page,
        children: [
            {
                path: '',
                component: UserIndex,
                name: 'users.index',
            },
            {
                path: 'add',
                component: UserEdit,
                name: 'users.add',
            },
            {
                path: ':id',
                component: UserEdit,
                name: 'users.edit',
            },
        ]
    }
]
