import CourseIndex from '@/components/Course/CourseIndex'
import CourseShow from '@/components/Course/CourseShow'
import CourseEdit from '@/components/Course/CourseEdit'
import Page from '@/layout/Page'

export default [
    {
        path        : 'courses',
        component   : Page,
        children    : [
            {
                path      : '',
                component : CourseIndex,
                name      : 'courses.index',
                meta      : { auth : undefined },
            },
            {
                path      : 'add',
                component : CourseEdit,
                name      : 'courses.add',
            },
            {
                path      : ':id',
                component : CourseShow,
                name      : 'courses.show',
            },
            {
                path      : ':id/edit',
                component : CourseEdit,
                name      : 'courses.edit',
            },
        ]
    },
]
