<template>
    <router-view></router-view>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

export default {
    name: 'SchoolLayout',
    created() {
        if (this.$route.params.school_slug) {
            this.loadSchool(this.$route.params.school_slug);
        } else {
            this.setSchool(null)
        }
    },
    beforeDestroy() {
        this.setSchool(null)
    },
    methods: {
        ...mapMutations(['setSchool']),
        ...mapActions(['loadSchool'])
    }
}
</script>
