<template>
    <div class="pt-spacer">
        <v-card class="shadowless" :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        outlined
                        :to="(!!$route.params.id)
                        ? {name: 'lectures.show', params: $route.params }
                        : {name: 'courses.index', params: {school_slug: school.slug}}"
                    >
                        <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="black"/>
                            <path d="M15 8C13.1331 11.3336 10.8 13 8 13C5.2 13 2.8669 11.3336 1 8C2.8669 4.66643 5.2 3 8 3C10.8 3 13.1331 4.66643 15 8Z" stroke="black" stroke-width="1.3" stroke-linecap="round"/>
                        </svg>
                        {{ $t('lecture.open_from_edit') }}
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <VideoGallery @delete="deleteGallery(index)" v-for="(gallery, index) in item.gallery"
                                  v-if="gallery.type === 'video'"
                                  :gallery="gallery" :index="index" :key="'gallery'+index"/>
                    <v-btn depressed dark @click="addVideoGallery">
                        {{ $t('video_gallery.add') }}
                    </v-btn>
                    <v-spacer class="mb-5"></v-spacer>
                    <ImageGallery @delete="deleteGallery(index)" v-for="(gallery, index) in item.gallery"
                                  v-if="gallery.type === 'image'"
                                  :gallery="gallery" :index="index" :key="'gallery'+index"/>
                    <v-btn depressed dark @click="addImageGallery">
                        {{ $t('image_gallery.add') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <editor v-model="item.description" :gallery="item.gallery"/>
                    <v-textarea class="mt-4"
                                outlined
                                counter
                                :label="$t('lecture.task_label')"
                                v-model="item.task"
                                :error-messages="form_errors.task"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-card class="mb-4" outlined>
                        <v-card-title>{{$t('lecture.image_title')}}</v-card-title>
                        <v-card-text>
                            <div>
                                <div class="mb-4">
                                    <v-responsive :aspect-ratio="22/13" class="grey lighten-2 rounded-lg">
                                        <img v-if="!!item.image" style="max-width: 100%;" :src="item.image"/>
                                    </v-responsive>
                                </div>
                                <div class="d-flex justify-left align-center">
                                    <v-btn class="mr-4"
                                           rounded
                                           fab
                                           outlined
                                           @click="$refs.image.click()">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 13H4.13144L12.3515 4.77998C12.7667 4.36472 13 3.80152 13 3.21426C13 2.627 12.7667 2.0638 12.3515 1.64854C11.9362 1.23329 11.373 1 10.7857 1C10.1985 1 9.63528 1.23329 9.22002 1.64854L1 9.86856V13Z"
                                                stroke="black" stroke-width="1.3" stroke-linecap="square"/>
                                        </svg>
                                    </v-btn>
                                    <v-btn class="mr-4"
                                           rounded
                                           fab
                                           :color="(!!item.is_blocked) ? 'error' : 'primary'"
                                           outlined
                                           @click="deleteImage">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.05025 12.9497L12.9497 3.05025M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="black" stroke-width="1.3"/>
                                        </svg>
                                    </v-btn>
                                    <div>
                                        {{$t('course.image_size_title')}}<br/>
                                        {{$t('course.image_wh_title')}}
                                    </div>
                                </div>
                                <input v-show="false" @change="onImageChange" type="file"
                                       accept="image/png, image/jpeg" ref="image"/>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-4" outlined>
                        <v-card-title>{{$t('lecture.settings_lecture_label')}}</v-card-title>
                        <v-card-text>
                            <v-select :items="courses"
                                      :label="$t('course.course')"
                                      v-model="item.course_id"
                                      required
                                      outlined
                            ></v-select>
                            <v-text-field
                                v-model="item.title"
                                :rules="[() => !!item.title || $t('form.rules.required')]"
                                :error-messages="form_errors.title"
                                :label="$t('lecture.name_label')"
                                required
                                outlined
                            ></v-text-field>
                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        :items="statuses"
                                        :label="$t('lecture.status_label')"
                                        v-model="item.status"
                                        :error-messages="form_errors.status"
                                        outlined
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :items="types"
                                        :label="$t('lecture.discuss_type_label')"
                                        v-model="item.type"
                                        :error-messages="form_errors.type"
                                        outlined
                                        :disabled="!!item.id"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-4" outlined>
                        <v-card-text>
                            <v-switch
                                v-model="item.show_author"
                                inset
                                :label="$t('lecture.show_author_label')"
                                :error-messages="form_errors.show_author"
                                color="black"
                            ></v-switch>
                            <v-row v-show="!!item.show_author">
                                <v-col cols="6">
                                    <v-autocomplete
                                        :items="users"
                                        v-model="item.author"
                                        :label="$t('lecture.author_label')"
                                        :error-messages="form_errors.author"
                                        item-text="name"
                                        item-value="id"
                                        outlined>
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                            >
                                                <v-avatar left>
                                                    <v-img v-if="!!data.item.avatar" :src="data.item.avatar"></v-img>
                                                    <v-icon color="black" v-else>
                                                        mdi-account-circle
                                                    </v-icon>
                                                </v-avatar>
                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-avatar>
                                                    <img v-if="!!data.item.avatar" :src="data.item.avatar">
                                                    <v-icon color="black" v-else>
                                                        mdi-account-circle
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete
                                        :items="users"
                                        v-model="item.co_author"
                                        :label="$t('lecture.coauthor_label')"
                                        :error-messages="form_errors.co_author"
                                        item-text="name"
                                        item-value="id"
                                        outlined>
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                            >
                                                <v-avatar left>
                                                    <v-img v-if="!!data.item.avatar" :src="data.item.avatar"></v-img>
                                                    <v-icon color="black" v-else>
                                                        mdi-account-circle
                                                    </v-icon>
                                                </v-avatar>
                                                {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-avatar>
                                                    <img v-if="!!data.item.avatar" :src="data.item.avatar">
                                                    <v-icon color="black" v-else>
                                                        mdi-account-circle
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-4" outlined>
                        <v-card-text>
                            <v-switch
                                v-model="item.show_publish_at"
                                inset
                                :label="$t('lecture.show_publish_date_label')"
                                :error-messages="form_errors.show_publish_at"
                                color="black"
                            ></v-switch>
                            <div v-show="!!item.show_publish_at">
                                <v-menu
                                    ref="menu1"
                                    v-model="date_menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    outlined
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="item.publish_at"
                                            :label="$t('lecture.publish_at_label')"
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="item.publish_at"
                                        no-title
                                        @input="date_menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <div class="d-flex justify-space-between mt-12 mb-6">
                <v-btn depressed
                    x-large
                    color="accent" class="black--text"
                    @click="submit"
                >
                    <span v-if="!!item.id">
                    {{$t('save')}}
                    <span class="d-none d-md-inline">{{$t('changes')}}</span>
                    </span>
                    <span v-else>{{$t('publish')}}</span>
                </v-btn>
                <v-btn depressed
                    v-if="school"
                    x-large
                    color="grey lighten-2" class="black--text"
                    :to="{name: 'courses.index', params: {school_slug: school.slug}}"
                >
                    {{$t('cancel_changes')}}
                </v-btn>
            </div>
        </v-card>
    </div>
</template>
<script>
    import {mapGetters, mapMutations} from 'vuex';
    import {STATUS_LECTURE_SELECT, TYPE_LECTURE_SELECT} from "@/components/Lecture/lecture.constants";
    import Editor from '@/layout/Editor'
    import ImageGallery from '@/layout/Gallery/ImageGallery'
    import VideoGallery from '@/layout/Gallery/VideoGallery'

    export default {
        components: {
            Editor,
            ImageGallery,
            VideoGallery,
        },
        data() {
            return {
                item       : {
                    status         : 0,
                    course_id      : 0,
                    type           : 0,
                    title          : '',
                    description    : '',
                    task           : '',
                    publish_at     : '',
                    show_author    : 0,
                    show_publish_at: 0,
                    user_id        : '',
                    author         : '',
                    co_author      : '',
                    gallery        : []
                },
                date_menu  : false,
                image      : null,
                form_errors: {},
                statuses   : STATUS_LECTURE_SELECT,
                types      : TYPE_LECTURE_SELECT,
                loading    : false,
                courses    : [],
                users      : []
            }
        },
        computed  : {
            ...mapGetters(['school'])
        },
        mounted() {
            this.loadUsers();
            this.loadCourses();
            this.load().then(() => {
                this.applyBreadcrumbs();
            });
        },
        methods   : {
            ...mapMutations(['setBreadcrumbs']),
            async load() {
                if (!!this.$route.params.id && this.$route.params.id !== 'add') {
                    this.loading = true;
                    await axios.get('/lectures/' + this.$route.params.id).then(response => {
                        this.item = response.data.data;
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    this.item.course_id = Number(this.$route.query.course_id)
                }
            },
            submit() {
                let url = (this.item.id) ? '/lectures/' + this.$route.params.id : '/lectures';

                this.loading = true;
                axios.post(url, this.prepareForm()).then(response => {
                    this.form_errors = [];
                    this.showMessage(this.$t('successfully_saved'));

                    if (!this.item.id) {
                        this.$router.push({
                            name  : 'lectures.edit',
                            params: {
                                school_slug: this.$route.params.school_slug,
                                id         : response.data.data.id
                            }
                        });
                        this.item = response.data.data;
                        this.applyBreadcrumbs();
                    } else {
                        this.item = response.data.data;
                    }
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            prepareForm() {
                let formData = new FormData();

                if (this.item.id) formData.append('_method', 'PUT');

                for (let field in this.item) {
                    let value = this.item[field];

                    if (typeof value === 'object' || ['image', 'gallery'].includes(field)) continue;

                    if (typeof value === 'boolean') {
                        value = value ? 1 : 0;
                    }
                    if (typeof value === 'string') {
                        value = (!!value) ? value : '';
                    }

                    formData.append(field, value);
                }

                if (!!this.image) {
                    formData.append('image', this.image);
                }
                for (let gallery_k in this.item.gallery) {
                    formData.append('gallery[' + gallery_k + '][id]', this.item.gallery[gallery_k].id);
                    formData.append('gallery[' + gallery_k + '][type]', this.item.gallery[gallery_k].type);
                    formData.append('gallery[' + gallery_k + '][uhash]', this.item.gallery[gallery_k].uhash);
                    for (let file_k in this.item.gallery[gallery_k].files) {
                        formData.append('gallery[' + gallery_k + '][files][' + file_k + '][id]', (!!this.item.gallery[gallery_k].files[file_k].id) ? this.item.gallery[gallery_k].files[file_k].id : 0);
                        formData.append('gallery[' + gallery_k + '][files][' + file_k + '][type]', (!!this.item.gallery[gallery_k].files[file_k].type) ? this.item.gallery[gallery_k].files[file_k].type : 'upload');
                        if (!!this.item.gallery[gallery_k].files[file_k].upload) {
                            formData.append('gallery[' + gallery_k + '][files][' + file_k + '][upload]', this.item.gallery[gallery_k].files[file_k].upload);
                        }
                        if (!!this.item.gallery[gallery_k].files[file_k].link) {
                            formData.append('gallery[' + gallery_k + '][files][' + file_k + '][link]', this.item.gallery[gallery_k].files[file_k].link);
                        }
                    }
                }

                return formData;
            },
            deleteImage() {
                this.item.is_delete_image = 1;
                this.$refs.image.value = null;
                this.item.image = '';
            },
            onImageChange(e) {
                this.item.is_delete_image = 0;
                let file = e.target.files[0];
                this.item.image = URL.createObjectURL(file);
                this.image = file;
            },
            loadUsers() {
                this.loading = true;
                let app = this;
                return axios.get('schools/' + this.$route.params.school_slug + '/users/list').then(response => {
                    app.users = response.data.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadCourses() {
                this.loading = true;
                return axios.get('schools/' + this.$route.params.school_slug + '/courses/list').then(response => {
                    for (let k in response.data.data) {
                        this.courses.push({
                            value: k * 1,
                            text : response.data.data[k]
                        })
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            addImageGallery() {
                this.item.gallery.push(
                    {id: 0, uhash: new Date().getTime() + this.item.gallery.length, type: 'image', files: []}
                );
                console.log(this.item.gallery)
            },
            addVideoGallery() {
                this.item.gallery.push(
                    {id: 0, uhash: new Date().getTime() + this.item.gallery.length, type: 'video', files: []}
                );
            },
            deleteGallery(index) {
                this.item.gallery.splice(index, 1);
            },
            applyBreadcrumbs() {
                let arr = [
                    {
                        text: this.$t('admin_menu_title'),
                        to  : {name: 'admin.schools.index'}
                    },
                    {
                        text: this.$t('school.courses_n_posts'),
                        to  : {
                            name  : 'admin.courses.index',
                            params: {school_slug: this.$route.params.school_slug}
                        }
                    }
                ];
                if (!!this.item.course) {
                    arr.push({
                        text: this.item.course.title,
                        to  : {
                            name  : 'courses.show',
                            params: {school_slug: this.$route.params.school_slug, id: this.item.course.id}
                        }
                    });
                }
                arr.push({
                    text: !!this.item.id ? this.item.title : this.$t('lecture.add')
                });
                this.setBreadcrumbs(arr);
            }
        },
    }
</script>
