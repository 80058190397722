<template>
    <div id="plus-button">
        <v-btn class="mr-2 mr-md-3"
               mr-2 mr-md-3
               v-if="button.length === 1"
               fab
               outlined
               rounded
               :to="button[0].to"
        >
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="black" stroke-width="1.3"/>
            </svg>
        </v-btn>
        <v-menu v-else-if="button.length > 1"
                left
                bottom
                offset-y
                content-class="shadowless mt-1"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="mr-2 mr-md-3"
                    fab
                    outlined
                    rounded
                    v-bind="attrs"
                    v-on="on"
                >
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.32813 0V16M0.328125 8L16.3281 8" stroke="black" stroke-width="1.3"/>
                    </svg>
                </v-btn>
            </template>

            <v-list outlined>
                <v-list-item v-for="(item, index) in button" :to="item.to" :key="index">
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'PlusButton',
    data() {
        return {}
    },
    computed: {
        button() {
            const school_slug = this.$route.params.school_slug;
            const course_id = this.$route.params.id;

            switch (this.$route.name) {
                case 'courses.index':
                    return this.hasSchoolAdminAccess(school_slug) ? [{to: {name: 'courses.add', params: {school_slug}}}] : []
                case 'courses.show':
                    return (this.hasSchoolAdminAccess(school_slug) || this.hasCourseAdminAccess(school_slug, course_id)) ? [{to: {name: 'lectures.add', params: {school_slug}, query: {course_id: course_id}}, title: this.$t('lecture.lecture')}, {to: {name: 'infoposts.add', params: {school_slug}, query: {course_id: course_id}}, title: this.$t('post.post')}] : []
                case 'admin.schools.index':
                    return this.isAdminPlatform() ? [{to: {name: 'admin.schools.add'}}] : []
                case 'users.index':
                    return this.hasSchoolAdminAccess(school_slug) ? [{to: {name: 'users.add', params: {school_slug}}}] : []
                case 'records.index':
                    return this.hasCourseAdminAccess(school_slug, course_id) ? [{to: {name: 'records.add', params: {school_slug}}}] : []
                default:
                    return []
            }
        }
    },
}
</script>
