<template>
    <v-card class="mb-4" outlined>
        <v-card-title>{{$t('course.payment.type_label')}} {{index+1}}</v-card-title>
        <v-btn
            v-if="!item.id"
            icon
            absolute
            top
            right
            @click="$emit('delete')"
        >
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.777344 4.11038H13.2219M12.408 4.50003L11.6627 13.4438C11.6627 13.8563 11.4988 14.252 11.2071 14.5437C10.9154 14.8355 10.5197 14.9993 10.1071 14.9993H3.88487C3.47231 14.9993 3.07664 14.8355 2.78492 14.5437C2.49319 14.252 2.3293 13.8563 2.3293 13.4438L1.58399 4.50003M4.66474 4.11114V1.77778C4.66474 1.5715 4.74669 1.37367 4.89255 1.22781C5.03841 1.08194 5.23625 1 5.44253 1H8.55366C8.75994 1 8.95778 1.08194 9.10364 1.22781C9.2495 1.37367 9.33145 1.5715 9.33145 1.77778V4.11114" stroke="black" stroke-width="1.3" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
        </v-btn>
        <v-card-text>
            <div>
                <v-row>
                    <v-col cols="6">
                        <v-select @change="loadPaddle"
                                  :items="course_payment_types"
                                  :label="$t('course.payment.type_label')"
                                  v-model="item.type_payment"
                                  outlined
                                  :disabled="!!item.id"
                        ></v-select>
                        <v-text-field
                            :label="$t('course.payment.price_label')"
                            v-model="item.price"
                            suffix="usd"
                            required
                            outlined
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select @change="setData"
                                  :items="paddle_product_select"
                                  :label="$t('course.payment.paddle_product_id_label')"
                                  v-model="item.product_id"
                                  outlined
                                  :disabled="!!item.id"
                        ></v-select>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import {PAYMENT_TYPE_COURSE_SELECT, PAYMENT_TYPE_COURSE_ALL} from "@/components/Course/course.constants";

    export default {
        name      : 'PaymentMethod',
        components: {},
        props     : {
            item : {
                type    : Object,
                required: true
            },
            index: {
                type    : Number,
                required: true
            }
        },
        data() {
            return {
                course_payment_types: PAYMENT_TYPE_COURSE_SELECT,
                paddle_data         : [],
                payment_types       : ['base', 'pro', 'products'],
            }
        },
        computed  : {
            paddle_product_select() {
                let data = [];
                for (var k in this.paddle_data) {
                    data.push({text: this.paddle_data[k].product_title, value: this.paddle_data[k].product_id});
                }
                return data;
            }
        },
        mounted() {
            this.loadPaddle();
        },
        methods   : {
            loadPaddle() {
                let url = '';
                if (this.item.type_payment === PAYMENT_TYPE_COURSE_ALL) {
                    url = 'schools/' + this.$route.params.school_slug + '/payments/paddle/products';

                } else {
                    url = 'schools/' + this.$route.params.school_slug + '/payments/paddle/subscriptions/' + this.payment_types[this.item.type_payment];
                }
                if (!!this.item.id) {
                    url += '/' + this.item.product_id;
                }

                this.loading = true;
                return axios.get(url).then(response => {
                    this.item.price = 0;
                    this.paddle_data = response.data.data;
                    this.setData();
                }).finally(() => {
                    this.loading = false;
                });
            },
            setData() {
                for (var k in this.paddle_data) {
                    if (this.paddle_data[k].product_id == this.item.product_id) {
                        this.item.price = this.paddle_data[k].price;
                        this.item.currency = this.paddle_data[k].currency;
                        this.item.product_title = this.paddle_data[k].product_title;
                        if(!!this.paddle_data[k].interval_type) {
                            this.item.interval_type = this.paddle_data[k].interval_type;
                        } else {
                            if(!!this.item.interval_type) {
                                delete this.item.interval_type;
                            }
                        }
                    }
                }
            }
        }
    }
</script>
