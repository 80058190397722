import NotFound from '@/components/NotFound.vue'
import ProfileIndex from '@/components/Profile/ProfileIndex'
import Home from '@/layout/Home'
import privacy from "@/router/privacy";
import terms from "@/router/terms";
import course from '@/router/course'
import lecture from '@/router/lecture'
import infopost from '@/router/infopost'
import user from '@/router/user'
import record from '@/router/record'
import auth from '@/router/auth'
import payment from '@/router/payment'
import profile from '@/router/profile'
import admin from '@/router/admin/admin'
import notifications from '@/router/notifications'
import redirectToDefaultSchool from '@/router/redirect-to-default-school'
import SchoolLayout from '@/layout/SchoolLayout'
import search from "@/router/search";

export const routes = [
    {
        path     : '/',
        component: Home,
        children : [
            redirectToDefaultSchool('index'),
            ...admin,
            ...auth,
            {
                path     : '/:school_slug',
                component: SchoolLayout,
                meta     : {auth: true},
                children : [
                    redirectToDefaultSchool('index.school'),
                    ...course,
                    ...payment,
                    ...lecture,
                    ...infopost,
                    ...user,
                    ...record,
                    ...profile,
                    ...notifications,
                    ...search,
                    ...privacy,
                    ...terms,
                ]
            },
            {
                path     : '*',
                component: NotFound,
                name     : 'not_found',
                meta     : {auth: undefined}
            },
        ]
    },
];
