<template>
    <div id="header" class="d-flex align-start py-4 px-md-15 px-c-10">
            <Breadcrumbs/>
    </div>
</template>

<script>
    import UserBlock from "./UserBlock.vue";
    import Breadcrumbs from "./Breadcrumbs.vue";

    export default {
        name       : 'Header',
        components : {
            UserBlock,
            Breadcrumbs,
        },
        data() {
            return {}
        },
    }
</script>
