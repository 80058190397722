import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=32bd0940&"
import script from "./Privacy.vue?vue&type=script&lang=js&"
export * from "./Privacy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
