import Page from "@/layout/Page";
import SearchIndex from "@/components/Search/SearchIndex";

export default [
    {
        path     : 'search',
        component: Page,
        children : [
            {
                path     : '',
                component: SearchIndex,
                name     : 'search.index',
            }
        ]
    }
]
