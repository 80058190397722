<template>
    <v-dialog
        v-model="$store.state.showForgotSendPopup"
        persistent
        max-width="360"
    >
        <v-btn
            icon
            absolute
            top
            right
            color="transparent"
            @click="SHOW_FORGOT_SEND({dialog: false})"
            :to="{name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}}"
        >
            <v-icon
                class="white--text">mdi-close
            </v-icon>
        </v-btn>
        <v-card :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2 pb-2" style="word-break: normal">
                {{ $t('auth.reset_password.title') }}
            </v-card-title>
            <v-card-text class="pt-5">
                <v-text-field outlined
                              v-model="form.email"
                              :rules="[() => !!form.email || $t('form.rules.email')]"
                              :error-messages="form_errors.email"
                              :type="'email'"
                              label="Почта"
                              required
                ></v-text-field>
                <v-btn block depressed color="black" class="white--text mt-2" x-large @click="submit">
                    {{ $t('restore') }}
                </v-btn>
                <div class="mt-6">
                    <a @click="goLogin">
                        <img src="/img/comment-arrow.svg"/>
                        {{ $t('auth.login.login_button') }}
                    </a>,
                    {{ $t('auth.login.if_password_remembered_text') }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        name   : 'ForgotSendBlock',
        data() {
            return {
                form       : {
                    email: ''
                },
                form_errors: [],
                loading    : false
            }
        },
        mounted() {
            window.document.addEventListener("keydown", (e) => {
                if (this.$route.name === 'forgot_by_school' && e.key === 'Escape') {
                    this.SHOW_FORGOT_SEND(false);
                    this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}}).then((resp) => {
                    }).catch(() => {});
                }
            });
        },
        methods: {
            ...mapMutations(['SHOW_FORGOT_SEND']),
            submit() {
                this.loading = true;
                axios.post('/schools/'+this.$route.params.school_slug+'/reset-password/create', this.form).then(response => {
                    this.form_errors = [];
                    this.SHOW_FORGOT_SEND({email: '', dialog: false});
                    if (!!this.$route.params.school_slug) {
                        this.$router.push({name: 'forgotsent_by_school', params: {school_slug: this.$route.params.school_slug}});
                    } else {
                        this.$router.push({name: 'forgotsent'});
                    }
                }).catch((error) => {
                    this.form_errors = error.response.data.data;
                    this.showMessage(error.response.data.message, 'error');
                }).finally(() => {
                    this.loading = false;
                });
            },
            goLogin() {
                this.SHOW_FORGOT_SEND({dialog: false});
                if (!!this.$route.params.school_slug) {
                    this.$router.push({name: 'login_by_school', params: {school_slug: this.$route.params.school_slug}});
                } else {
                    this.$router.push({name: 'login'});
                }
            },
        }
    }
</script>
