import {CONTENT_TYPE_FREE, CONTENT_TYPE_PAID} from "./components/Course/course.constants";

export const ROLE_ADMIN_PLATFORM = 'admin-platform';
export const ROLE_ADMIN_SCHOOL = 'admin-school';
export const ROLE_ADMIN_COURSE = 'admin-course';
export const ROLE_STUDENT = 'student-all';
export const ROLE_STUDENT_BASE = 'student-base';
export const ROLE_STUDENT_PRO = 'student-pro';
export const ROLE_STUDENT_SUSPENDED = 'student-suspended';

export const DEFAULT_SCHOOL_NAME = 'Method';
export const DEFAULT_SCHOOL_SLUG = 'method-education';

export const DATETIME_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';

export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';

export const DATETIMEFULL_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const CONTENT_LENGTH = 100;
