<template>
    <v-dialog
        v-model="$store.state.showSocialLoginPopup"
        persistent
        max-width="520"
    >
        <v-card :loading="loading">
            <template slot="progress">
                <Preloader :loading="loading"/>
            </template>
            <v-card-title class="text-h2">
                {{ $t('auth.login_social.title') }}
            </v-card-title>
            <v-btn
                icon
                absolute
                top
                right
                @click="SHOW_SOCIAL_LOGIN(false)"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-text class="text-h2 text-center pt-5">{{ $t('auth.login_social.wait_text') }}</v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex'

    export default {
        name   : 'SocialLoginBlock',
        data() {
            return {
                loading: false
            }
        },
        watch  : {
            '$store.state.showSocialLoginPopup': {
                handler: function (search) {
                    if (this.$store.state.showSocialLoginPopup == true) {
                        let state = JSON.parse(this.$route.query.state);
                        this.oauth2Default({
                            type : this.$route.params.type,
                            code : this.$route.query.code,
                            state: state
                        }).then(() => {
                            this.SHOW_SOCIAL_LOGIN(false);
                            if(!this.$auth.user().fill_complete) {
                                this.SHOW_FILL_INFORMATION(true);
                            }
                            if (!!state.school) {
                                this.fetchCountUnreadNotifications(state.school);
                                this.$router.push({name: 'courses.index', params: {school_slug: state.school}}).then((resp) => {
                                }).catch(() => {});
                            }
                        }).catch(error => {
                            this.SHOW_SOCIAL_LOGIN(false);
                            this.showMessage(error.response.data.message, 'error');
                        });
                    }
                }
            }
        },
        methods: {
            ...mapMutations(['SHOW_FILL_INFORMATION', 'SHOW_SOCIAL_LOGIN']),
            ...mapActions(['oauth2Default', 'fetchCountUnreadNotifications']),
        }
    }
</script>
